import Repository from "./Repository"

export function getQuickLinks() {
  return new Promise((resolve, reject) => {
    Repository.get("/home/quick-link")
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
