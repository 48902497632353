import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { setBreadcrumbItems } from "store/actions"
import { connect } from "react-redux"
import {
  Card,
  CardTitle,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import AllCategories from "./AllCategories"
import HomePageCategories from "./HomepageCategories"

function Categories({ setBreadcrumbItems }) {
  const breadcrumbItems = [
    {
      item: "Categories",
      path: "/all-categories",
    },
  ]
  const [activeTab, setActiveTab] = useState(1)
  useEffect(() => {
    setBreadcrumbItems("Categories", breadcrumbItems)
  }, [])
  return (
    <Card>
      <CardBody>
        <CardTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            Categories
            <Link to={"/add-category"}>
              <a className="btn btn-primary">Add Category</a>
            </Link>
          </div>
        </CardTitle>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab == 1 ? "active" : ""}
              onClick={() => {
                setActiveTab(1)
              }}
            >
              All Categories
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={activeTab == 2 ? "active" : ""}
              onClick={() => {
                setActiveTab(2)
              }}
            >
              Homepage Categories
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1}>
            <AllCategories />
          </TabPane>
          {/* <TabPane tabId={2}>
            <HomePageCategories />
          </TabPane> */}
        </TabContent>
      </CardBody>
    </Card>
  )
}

export default connect(null, { setBreadcrumbItems })(Categories)
