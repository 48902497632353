import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  const user = JSON.parse(localStorage.getItem("authUser"))
  const permissions = JSON.parse(user.permissions)
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      {user.role == "super" ? (
        <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Main")} </li>
              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i className="mdi mdi-view-dashboard"></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>

              <li>
                <Link to="/all-categories" className="waves-effect">
                  <i className="mdi mdi-checkbox-multiple-blank"></i>
                  <span>Category</span>
                </Link>
              </li>

              <li>
                <Link to="/all-products" className="waves-effect">
                  <i className="mdi mdi-apps"></i>
                  <span>Products</span>
                </Link>
              </li>

              <li>
                <Link to="/glossary" className="waves-effect">
                  <i className="mdi mdi-alphabetical-variant"></i>
                  <span>Glossary</span>
                </Link>
              </li>

              <li>
                <Link to="/subadmins" className="waves-effect">
                  <i className="mdi mdi-account"></i>
                  <span>Sub-Admins</span>
                </Link>
              </li>
              <li>
                <Link to="/clients" className="waves-effect">
                  <i className="mdi mdi-account"></i>
                  <span>Clients</span>
                </Link>
              </li>
            </ul>
          </div>
        </SimpleBar>
      ) : (
        <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Main")} </li>
              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i className="mdi mdi-view-dashboard"></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>

              {permissions.category.read ? (
                <li>
                  <Link to="/all-categories" className="waves-effect">
                    <i className="mdi mdi-checkbox-multiple-blank"></i>
                    <span>Category</span>
                  </Link>
                </li>
              ) : (
                ""
              )}

              {permissions.product.read ? (
                <li>
                  <Link to="/all-products" className="waves-effect">
                    <i className="mdi mdi-apps"></i>
                    <span>Products</span>
                  </Link>
                </li>
              ) : (
                ""
              )}

              {permissions.glossary.read ? (
                <li>
                  <Link to="/glossary" className="waves-effect">
                    <i className="mdi mdi-alphabetical-variant"></i>
                    <span>Glossary</span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              <li>
                <Link to="/clients" className="waves-effect">
                  <i className="mdi mdi-account"></i>
                  <span>Clients</span>
                </Link>
              </li>
            </ul>
          </div>
        </SimpleBar>
      )}
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
