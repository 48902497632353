import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { setBreadcrumbItems } from "store/actions"
import {
  Card,
  CardBody,
  CardTitle,
  ButtonGroup,
  Button,
  Row,
  Col,
  Spinner,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { getGlossary, deleteGlossary } from "repository/GlossaryRepository"
import toast, { Toaster } from "react-hot-toast"
import MaterialTable from "material-table"
import { ThemeProvider, createTheme } from "@mui/material"
import { useHistory } from "react-router-dom/cjs/react-router-dom"

function AllClients({ setBreadcrumbItems }) {
  const user = JSON.parse(localStorage.getItem("authUser"))
  const [clientData, setClientData] = useState([])
  const history = useHistory();
  const permissions = JSON.parse(user.permissions)
  const breadcrumbItems = [
    {
      item: "Clients",
      path: "/clients",
    },
  ]
  const columns = [
    // { title: "Letter", field: "letter" },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Slug",
      field: "productSlug",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
    },
  ]

  const handleDeleteClient = async (clientInfo) => {
    const answer = window.confirm(
      `Are you sure you want to add ${clientInfo.name}`
    )
    const id = toast.loading("Processing your Request...")
    if (answer){
      let url = `${process.env.REACT_APP_ADMIN_ROLE_BACKEND_URL}/client/${clientInfo.client_id}`
      const token = localStorage.getItem("token")
      const res = await fetch(url,
          {
              method : 'DELETE',
              headers: {
                  // apiKey: process.env.REACT_APP_API_TOKEN,
                  'x-access-token':token
  
        }},)
      if(res.ok){
        const resData = await res.json()
        toast.success(resData.message)
        window.location.reload();
      }
      else{
        toast.error('There was some problem deleting client')
      }
    } else {
      toast.error("Client Not Deleted")
    }
    toast.dismiss(id)
  }
  const defaultMaterialTheme = createTheme()
  const getActions = element => (
    <>
      {permissions.product.update ? (
        <Link
          to={{
            pathname: "/edit-client",
            state: { client: JSON.stringify(element) },
          }}
        >
          <a className="btn btn-primary" style={{ width: "100%" }}>
            Edit Client
          </a>
        </Link>
      ) : (
        ""
      )}
      {permissions.product.delete ? (
        <Button color="danger" style={{
          width : '100%',
          marginTop : '8px'
        }}
          onClick={() => handleDeleteClient(element)}
        >
          Delete
        </Button>
            
      ) : (
        ""
      )}
    </>
  )

  useEffect(() => {
    setBreadcrumbItems("Client", breadcrumbItems)
    const getClientData = async () => {
        let url = `${process.env.REACT_APP_ADMIN_ROLE_BACKEND_URL}/client`
        const token = localStorage.getItem("token")
        const res = await fetch(url,
            {
                method : 'GET',
                headers: {
                    // apiKey: process.env.REACT_APP_API_TOKEN,
                    'x-access-token':token

          }},)

        const resData = await res.json()
        setClientData(resData.data.map(item => {
            return {
                name : item.name,
                productSlug : item.productSlug,
                email : item.email,
                actions : getActions(item)
            }
        }))
        // setClientData(resData.data)
    }
    getClientData()
}, [])
  return (
    <Card>
      <CardBody>
        <CardTitle>
        <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            Clients
          {permissions.glossary.read ? (
            <Link to={"/add-client"}>
              <a className="btn btn-primary">Add Client</a>
            </Link>
          ) : (
            ""
          )}
          </div>
        </CardTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            title=""
            columns={columns}
            options={{
              debounceInterval: 700,
              padding: "dense",
            //   filtering: true,
            }}
            data={clientData}
            // data={query =>
            //   new Promise((resolve, reject) => {
            //     // prepare your data and then call resolve like this:
            //     let url = `${process.env.REACT_APP_ADMIN_ROLE_BACKEND_URL}/client`
            //     const token = localStorage.getItem("token")
            //     // //searching
            //     // if (query.search) {
            //     //   url += `_search=${query.search}`
            //     // }
            //     // if (query.filters.length) {
            //     //   const filter = query.filters.map(filter => {
            //     //     return `&${filter.column.field}=${filter.value}`
            //     //   })
            //     //   url += filter.join("")
            //     // }
            //     // //pagination
            //     // url += `&page=${query.page + 1}`
            //     // url += `&pageCount=${query.pageSize}`

            //     fetch(url, {
            //       method: "GET",
            //       headers: {
            //         // apiKey: process.env.REACT_APP_API_TOKEN,
            //         'x-access-token':token
	
            //       },
            //     })
            //       .then(resp => resp.json())
            //       .then(resp => {
            //         resp.data.forEach(element => {
            //           element["letter"] = element["name"]
            //             .toUpperCase()
            //             .charAt(0)
            //           element["actions"] = getActions(element)
            //         })
            //         console.log(resp);
            //         resolve({
            //           data: resp.data, // your data array
            //           page: 1, //query.page // current page number
            //           totalCount: 1, //resp.featureCount // total row number
            //         })
            //       })
            //   })
            // }
          />
        </ThemeProvider>
        <Toaster />
      </CardBody>
    </Card>
  )
}

export default connect(null, { setBreadcrumbItems })(AllClients)
