import { reject } from "lodash"
import Repository from "./Repository"

export function getAllProducts() {
  return new Promise((resolve, reject) => {
    Repository.get("/product")
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getRecentlyAddedProducts() {
  return new Promise((resolve, reject) => {
    Repository.get("/product/home-page/recently-added")
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getHomepageProducts() {
  return new Promise((resolve, reject) => {
    Repository.get("/product/home-page")
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getProducAlternatives(slug) {
  return new Promise((resolve, reject) => {
    Repository.get(`/product/${slug}/alternates`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getProductPricingInfo(slug) {
  return new Promise((resolve, reject) => {
    Repository.get(`/product/${slug}/pricingInfo`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getProductDetails(slug) {
  return new Promise((resolve, reject) => {
    Repository.get(`/product/${slug}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getProductOverview(slug) {
  return new Promise((resolve, reject) => {
    Repository.get(`/product/${slug}/overview`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getProductMediaInfo(slug) {
  return new Promise((resolve, reject) => {
    Repository.get(`/product/${slug}/media`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getProductReviews(slug) {
  return new Promise((resolve, reject) => {
    Repository.get(`/product-review/${slug}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function searchProduct(value) {
  return new Promise((resolve, reject) => {
    Repository.get(`/search?query=${value}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function addProduct(data) {
  return new Promise((resolve, reject) => {
    Repository.post(`/product`, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
export function addSponsor(data) {
  return new Promise((resolve, reject) => {
    Repository.post(`/sponsor`, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function updateProduct(data) {
  return new Promise((resolve, reject) => {
    Repository.put(`/product`, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function deleteProduct(slug) {
  return new Promise((resolve, reject) => {
    Repository.delete(`/product?slug=${slug}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function addSponsorProduct(data) {
  return new Promise((resolve, reject) => {
    Repository.post("/sponsor", data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function searchCustomer(query) {
  return new Promise((resolve, reject) => {
    Repository.get("/search/customers", { params: { query: query } })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function addNewCustomer(data) {
  return new Promise((resolve, reject) => {
    Repository.post("/customer", data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function searchAll(query) {
  return new Promise((resolve, reject) => {
    Repository.get("/search", { params: { query: query } })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function searchIntegration(query) {
  return new Promise((resolve, reject) => {
    Repository.get("/search/products-and-integrations", { params: { query: query } })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function addNewIntegration(data) {
  return new Promise((resolve, reject) => {
    Repository.post("/integration", data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}


export function searchProductProduct(query) {
  return new Promise((resolve, reject) => {
    Repository.get("/product", { params: { _search: query } })
    .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function addNewPricingInfo(slug, data) {
  return new Promise((resolve, reject) => {
    Repository.post(`/product/${slug}/pricingInfo`, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function updatePricingInfo(slug, data) {
  return new Promise((resolve, reject) => {
    Repository.put(`/product/${slug}/pricingInfo`, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}


export async function getProductId(slug) {
  try{
    const res = await fetch(`${process.env.REACT_APP_WEB_API}/map?resource=product&slug=${slug}`)
    if(!res.ok){
      throw 'Bad request in map api'
    }
    const resData = await res.json()
    return resData
  }
  catch(e){
    return e 
  }
}

export function deletePricingInfo(slug) {
  return new Promise((resolve, reject) => {
    Repository.delete(`/product/${slug}/pricingInfo`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
