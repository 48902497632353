import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { setBreadcrumbItems } from "store/actions"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import AllProducts from "./AllProducts"
import DeletedProducts from "./DeletedProducts"
import SponsoredProducts from "./SponsoredProducts"

function Products({ setBreadcrumbItems }) {
  const breadcrumbItems = [
    {
      item: "Products",
      path: "/all-products",
    },
  ]
  const [activeTab, setActiveTab] = useState(1)
  useEffect(() => {
    setBreadcrumbItems("Products", breadcrumbItems)
  }, [])
  return (
    <Card>
      <CardBody>
        <CardTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            Products
            <Link to={"/add-product"}>
              <a className="btn btn-primary">Add Product</a>
            </Link>
          </div>
        </CardTitle>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab == 1 ? "active" : ""}
              onClick={() => {
                setActiveTab(1)
              }}
            >
              Active Products
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={activeTab == 2 ? "active" : ""}
              onClick={() => {
                setActiveTab(2)
              }}
            >
              Homepage Products
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              className={activeTab == 2 ? "active" : ""}
              onClick={() => {
                setActiveTab(2)
              }}
            >
              Deleted Products
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab == 3 ? "active" : ""}
              onClick={() => {
                setActiveTab(3)
              }}
            >
              Sponsored Products
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1}>
            <AllProducts />
          </TabPane>
          <TabPane tabId={2}>
            <DeletedProducts />
          </TabPane>
          <TabPane tabId={3}>
            <SponsoredProducts />
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  )
}

export default connect(null, { setBreadcrumbItems })(Products)
