import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "store/actions"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { Row, Button } from "reactstrap"
import { addProduct } from "repository/ProductRepository"
import { getAllCategories } from "repository/CategoryRepository"
import toast, { Toaster } from "react-hot-toast"
import { upload } from "helpers/S3Functions"
import Box from "@mui/material/Box"

function AddProduct({ setBreadcrumbItems }) {
  const breadcrumbItems = [
    {
      item: "Add Product",
      path: "#",
    },
  ]
  const handleSubmit = e => {
    e.preventDefault()
    let product_copy = JSON.parse(JSON.stringify(productDetails))
    product_copy.primaryCategoryId = product_copy.primaryCategorySlug._id
    product_copy.primaryCategorySlug = product_copy.primaryCategorySlug.slug
    if (!product_copy.rating) {
      product_copy.rating = -1.0
    } else {
      product_copy.rating = parseFloat(product_copy.rating)
    }
    if (!product_copy.ratingCount) {
      product_copy.ratingCount = -1
    } else {
      product_copy.ratingCount = parseInt(product_copy.ratingCount)
    }
    if (!product_copy.score) {
      product_copy.score = -1.0
    } else {
      product_copy.score = parseFloat(product_copy.score)
    }

    console.log("Submitted Data:", product_copy)
    const answer = window.confirm(
      `Are you sure you want to add ${product_copy.name}`
    )
    const id = toast.loading("Processing your Request...")
    if (answer) {
      addProduct(product_copy)
        .then(response => {
          if (response.success) {
            toast.success(response.message)
            window.location.href = `${window.location.origin}/product-detail/${response.slug}`
          } else {
            toast.error(response.message)
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      toast.error("Product Not Added")
    }
    toast.dismiss(id)
  }
  useEffect(() => {
    setBreadcrumbItems("Add Product", breadcrumbItems)
    getAllCategories()
      .then(response => {
        setCategories(response)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  const [categories, setCategories] = useState([])
  const categoryAutoCompleteProps = {
    options: categories,
    getOptionLabel: option => option.slug || "",
  }
  const [productDetails, setProductDetails] = useState({
    name: "",
    logo: "",
    rating: 0,
    pricing: [],
    priceImage: [],
    priceImageAltText: [],
    subtitle: "",
    desc: "",
    url: "",
    api: "NA",
    customerTypes: [],
    deploymentTypes: [],
    features: [],
    ratingCount: -1,
    score: -1.0,
    rating: -1.0,
    support: [],
    logoAltText: "",
    location: "",
    contact: "",
    imageUrls: [],
    imageUrlsAltText: [],
    appUrls: {
      playstoreUrl: "",
      appStoreUrl: "",
    },
    primaryCategoryId: "",
    primaryCategorySlug: "",
    customers: [],
    integrations: [],
    socialMediaUrls: {
      twitterUrl: "",
      instagramUrl: "",
      facebookUrl: "",
      youtubeUrl: "",
      linkedinUrl: "",
      otherSocialUrls: "",
    },
  })

  return (
    <Box sx={{ width: "100%" }}>
      <Toaster />{" "}
      <form onSubmit={handleSubmit}>
        <Row className="mb-3" style={{ marginTop: "10px" }}>
          <label className="col-md-2 col-form-label required">
            Product Name
          </label>
          <div className="col-md-10">
            <input
              className="form-control"
              placeholder={productDetails.name}
              name="name"
              required
              value={productDetails.name}
              onChange={e => {
                setProductDetails({
                  ...productDetails,
                  name: e.target.value,
                })
              }}
            />
          </div>
        </Row>
        <Row className="mb-3" style={{ marginTop: "10px" }}>
          <label className="col-md-2 col-form-label">Subtitle</label>
          <div className="col-md-10">
            <input
              className="form-control"
              placeholder={productDetails.subtitle}
              name="name"
              value={productDetails.subtitle}
              onChange={e => {
                setProductDetails({
                  ...productDetails,
                  subtitle: e.target.value,
                })
              }}
            />
          </div>
        </Row>
        <Row className="mb-3" style={{ marginTop: "10px" }}>
          <label className="col-md-2 col-form-label">Description</label>
          <div className="col-md-10">
            <textarea
              className="form-control"
              placeholder={productDetails.desc}
              name="name"
              value={productDetails.desc}
              onChange={e => {
                setProductDetails({
                  ...productDetails,
                  desc: e.target.value,
                })
              }}
            />
          </div>
        </Row>
        <Row className="mb-3" style={{ marginTop: "10px" }}>
          <label className="col-md-2 col-form-label required">
            Primary Category Slug
          </label>
          <Autocomplete
            {...categoryAutoCompleteProps}
            id="controlled-demo"
            value={
              productDetails.primaryCategorySlug
                ? productDetails.primaryCategorySlug
                : ""
            }
            onChange={(event, newValue) => {
              setProductDetails({
                ...productDetails,
                primaryCategorySlug: newValue,
              })
            }}
            renderInput={params => (
              <TextField
                {...params}
                required
                error={!productDetails.primaryCategorySlug}
                helperText={
                  !productDetails.primaryCategorySlug
                    ? "Please select an option."
                    : ""
                }
                label=""
                variant="standard"
              />
            )}
          />
        </Row>

        <Row className="mb-3" style={{ marginTop: "10px" }}>
          <label className="col-md-2 col-form-label required">Logo</label>
          <div className="col-md-10">
            <input
              required
              className="form-control"
              placeholder="image"
              type="file"
              onChange={async e => {
                const id = toast.loading("Processing your Request...")
                try {
                  const reader = new FileReader()
                  reader.onload = e => {
                    const fileUrl = new File(
                      [e.target.result],
                      `image_urls_${Date.now()}`,
                      {
                        type: "image/jpg",
                      }
                    )
                    reader.readAsDataURL(file)
                  }
                  const logoURL = await upload({
                    dirname: "product*" + productDetails.name,
                    file: e.target.files[0],
                  })
                  setProductDetails({
                    ...productDetails,
                    logo: logoURL,
                  })
                } finally {
                  toast.dismiss(id)
                }
              }}
            />
            <p>Max image size: 1 MB</p>
          </div>
        </Row>
        <Row className="mb-3" style={{ marginTop: "10px" }}>
          <label className="col-md-2 col-form-label required">
            Logo Alt Text
          </label>
          <div className="col-md-10">
            <input
              className="form-control"
              required
              placeholder={productDetails.logoAltText}
              value={productDetails.logoAltText}
              onChange={e => {
                if (e.target.value) {
                  setProductDetails({
                    ...productDetails,
                    logoAltText: e.target.value,
                  })
                }
              }}
            />
          </div>
        </Row>
        <Row className="mb-3" style={{ marginTop: "10px" }}>
          <label className="col-md-2 col-form-label required">
            Product URL
          </label>
          <div className="col-md-10">
            <input
              required
              className="form-control"
              placeholder={productDetails.url}
              value={productDetails.url}
              onChange={e => {
                if (e.target.value) {
                  setProductDetails({
                    ...productDetails,
                    url: e.target.value,
                  })
                }
              }}
            />
          </div>
        </Row>
        <Row className="mb-3" style={{ marginTop: "10px" }}>
          <label className="col-md-2 col-form-label required">Spot Score</label>
          <div className="col-md-10">
            <input
              className="form-control"
              type="number"
              step="0.1"
              placeholder={productDetails.score}
              name="name"
              required
              value={productDetails.score}
              onChange={e => {
                console.log(e.target.value)
                if (
                  e.target.value != -1.0 &&
                  (e.target.value > 10 || e.target.value < 0)
                ) {
                  toast.error("Score should be either between 0 and 10 or -1")
                } else {
                  setProductDetails({
                    ...productDetails,
                    score: e.target.value,
                  })
                }
              }}
            />
            <p>Default Value : -1</p>
          </div>
        </Row>
        <Row className="mb-3" style={{ marginTop: "10px" }}>
          <label className="col-md-2 col-form-label required">Rating</label>
          <div className="col-md-10">
            <input
              className="form-control"
              type="number"
              step="0.1"
              placeholder={productDetails.rating}
              name="name"
              required
              value={productDetails.rating}
              onChange={e => {
                console.log(e.target.value)
                if (
                  e.target.value != -1.0 &&
                  (e.target.value > 5 || e.target.value < 0)
                ) {
                  toast.error("Rating should be either between 0 and 5 or -1")
                } else {
                  setProductDetails({
                    ...productDetails,
                    rating: e.target.value,
                  })
                }
              }}
            />
            <p>Default Value -1</p>
          </div>
        </Row>
        <Row className="mb-3" style={{ marginTop: "10px" }}>
          <label className="col-md-2 col-form-label">Rating Count</label>
          <div className="col-md-10">
            <input
              className="form-control"
              type="number"
              step="1"
              placeholder={productDetails.ratingCount}
              name="name"
              value={productDetails.ratingCount}
              onChange={e => {
                setProductDetails({
                  ...productDetails,
                  ratingCount: e.target.value,
                })
              }}
            />
            <p>Default Value -1</p>
          </div>
        </Row>
        <Button type="submit">Submit</Button>
      </form>
    </Box>
  )
}

export default connect(null, { setBreadcrumbItems })(AddProduct)
