import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import toast, { Toaster } from "react-hot-toast"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "store/actions"
import { getAllCategories } from "repository/CategoryRepository"
import { addSponsor, getProductDetails } from "repository/ProductRepository"
import { useHistory } from "react-router-dom"
import TextField from "@mui/material/TextField"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"

import Autocomplete from "@mui/material/Autocomplete"
import {
  countryCodeContinentCodeMap,
  countryNameCodeMap,
  continentNameCodeMap,
} from "common/data/geoData"
import SponsoredProducts from "../SponsoredProducts"

function AddSponsor({ setBreadcrumbItems }) {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(1)
  const [sponsorDocs, setSponsorDocs] = useState([])
  const [selectedProd, setSelectedProd] = useState(null)
  const [productUrl, setProductUrl] = useState('')
  const [prodCategories, setProdCategories] = useState([])
  const fetchSponsorDocData = async (categorySlug, countryCode) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/sponsor/doc?categorySlug=${categorySlug}&countryCode=${countryCode}`
      const response = await fetch(url, {
        method: "GET",
        headers: {
          apiKey: process.env.REACT_APP_API_TOKEN,
        },
      })
      const jsonData = await response.json()
      return jsonData
    } catch (error) {
      console.error("Error fetching data:", error)
      return {}
    }
  }

  const breadcrumbItems = [
    {
      item: "Sponsor",
      path: "/product",
    },
    {
      item: "Add",
      path: "/add-sponsor",
    },
  ]
  const [categories, setCategories] = useState([])
  const [products, setProducts] = useState([])
  const [globalStatus, setGlobalStatus] = useState(true)
  const categoryAutoCompleteProps = {
    options: prodCategories.length > 0 ? prodCategories : categories,
    getOptionLabel: option =>  option.slug || "",
  }
  const countryCodeAutoCompleteProps = {
    options: countryNameCodeMap,
    getOptionLabel: option => option.countryName || "",
  }
  const continentCodeAutoCompleteProps = {
    options: continentNameCodeMap,
    getOptionLabel: option => option.continentName || "",
  }
  const productAutoCompleteProps = {
    options: products,
    getOptionLabel: option => option.slug || "",
  }
  const [sponsorData, setSponsorData] = useState({
    slug: "",
    categorySlugs: [],
    countryCodes: [],
    continentCodes: [],
    productUrls: [],
    productRanks: [],
  })
  const getCountryCodeRows = categorySlug => {
    return (
      <Row className="mb-6">
        {sponsorData.countryCodes.map((code, index) => {
          let products = []
          const sponsorDoc = sponsorDocs.find(
            doc =>
              doc.categorySlug == categorySlug.slug &&
              doc.countryCode == code.countryCode
          )
          if (sponsorDoc && sponsorDoc.hasOwnProperty("products")) {
            products = sponsorDoc.products
          }
          // console.log(products)
          return (
            <Row className="mb-3">
              <div>
                {index + 1}. {code.countryName}
              </div>
              <div>
                {" "}
                <h6>Existing Products</h6>
                <ul>
                  {products.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
              <div>
                <label>Choose Product Rank </label>
                <input
                  type="number"
                  value={sponsorData.productRanks[index]}
                  // disabled="disabled"
                  onChange={e => {
                    setSponsorData({
                      ...sponsorData,
                      productRanks: [
                        ...sponsorData.productRanks.slice(0, index),
                        parseInt(e.target.value),
                        ...sponsorData.productRanks.slice(index + 1),
                      ],
                    })
                  }}
                />
              </div>
            </Row>
          )
        })}
        {/* {console.log(sponsorData)} */}
      </Row>
    )
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    const copy = JSON.parse(JSON.stringify(sponsorData))
    console.log('copy', copy)
    const dataToSend = {
      productSlug: copy.slug.slug,
      categorySlugs: copy.categorySlugs.map(a => a.slug),
      // countryCodes: copy.countryCodes.map(a => a.countryCode),
      countryCodes: countryNameCodeMap.map(a => a.countryCode),
      // continentCodes: copy.continentCodes.map(a => a.continentCode),
      continentCodes: [],
      // productRanks: copy.productRanks,
      productRanks: countryNameCodeMap.map(a => {return copy.productRanks[0]}),
      // productUrls: [],
      productUrls: countryNameCodeMap.map(a => {return copy.productUrls[0]}),
    }
    console.log('dataToSend', dataToSend)
    const answer = window.confirm(
      `Are you sure you want to sponsor ${dataToSend.productSlug}`
    )
    const id = toast.loading("Processing your Request...");
    if (answer) {
      addSponsor(dataToSend)
        .then(response => {
          if (response.success) {
            toast.success("Product Sponsored Successfully")
            history.goBack()
          } else {
            toast.error(response.message)
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      toast.error("Product Not Sponsored")
    }
    toast.dismiss(id);
  }
  useEffect(() => {
    setBreadcrumbItems("Add Sponsor", breadcrumbItems)
    getAllCategories()
      .then(response => {
        setCategories(response)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  useEffect(()=>{
    if(selectedProd){
      
      getProductDetails(selectedProd.slug)
      .then(response => {
        setProdCategories([
          {
            slug : response.categorySlug,
            name : response.categoryName,
            _id : response.categoryId
          }])
          console.log(sponsorData.categorySlugs);
          console.log(response.categorySlug);
        setSponsorData(prev => {return{
          ...prev,
          categorySlugs: prev.categorySlugs.filter(item => item.slug === response.categorySlug),
        }})
      })
      .catch(error => {
        console.log(error)
      })
    }
    else{
      setProdCategories(categories)
    }
  },[selectedProd])

  // console.log('activeTab:', activeTab);
  // console.log('sponsorDocs:', sponsorDocs);
  // console.log('selectedProd:', selectedProd);
  // console.log('productUrl:', productUrl);
  // console.log('prodCategories:', prodCategories);
  // console.log('categories:', categories);
  // console.log('products:', products);
  // console.log('globalStatus:', globalStatus);
  // console.log('sponsorData:', sponsorData);
  return (
    <Card>
      <CardBody>
        <Toaster />
        <CardTitle>Add Sponsor</CardTitle>
        <form onSubmit={handleOnSubmit}>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label required">Product</label>
            <Autocomplete
              {...productAutoCompleteProps}
              id="controlled-demo"
              noOptionsText={"Search Product"}
              onInputChange={(event, value, reason) => {
                if (value) {
                  // console.log(`${process.env.REACT_APP_API_BASE_URL}/product?_search=` +
                  //     value + (sponsorData?.categorySlugs?.length > 0 ? `&primaryCategorySlug=${sponsorData?.categorySlugs[0].slug}` : ''));
                  fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/product?_search=` +
                      value + (sponsorData?.categorySlugs?.length > 0 ? `&primaryCategorySlug=${sponsorData?.categorySlugs[0].slug}` : ''),
                    {
                      method: "GET",
                      headers: {
                        apiKey: process.env.REACT_APP_API_TOKEN,
                      },
                    }
                  )
                    .then(function (response) {
                      return response.json()
                    })
                    .then(function (myJson) {
                      // console.log(
                      //   "search term: " + value + ", results: ",
                      //   myJson.products?.filter(item=> sponsorData?.categorySlugs?.
                      //     filter(subitem=>subitem.slug === item?.primaryCategorySlug).length > 0
                      //     )
                      // )
                      // console.log('searching here', value, myJson.products, sponsorData?.categorySlugs);
                      // const updatedOptions = myJson.products.map(p => {
                      //   return { slug: p.slug }
                      // })
                      setProducts(myJson.products)
                    })
                } else {
                  setProducts([])
                }
              }}
              value={sponsorData.slug ? sponsorData.slug : ""}
              onChange={(event, newValue) => {
                // let newSlugs = objArray.map(a => a.slug)
                setSponsorData({
                  ...sponsorData,
                  slug: newValue,
                })
                setSelectedProd(newValue)
              }}
              renderInput={params => (
                <TextField {...params} label="" variant="standard" />
              )}
            />
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label required">Category</label>
            <Autocomplete
              {...categoryAutoCompleteProps}
              multiple
              id="controlled-demo"
              value={
                sponsorData.categorySlugs ? sponsorData.categorySlugs : ""
              }
              onChange={async (event, newValue) => {
                // let newSlugs = newValue.map(a => a.slug)
                console.log(newValue);
                
                let docs = []
                for(let i=0; i < newValue.length ; i++){
                  console.log(newValue[i]);
                  docs[i] = await fetchSponsorDocData(newValue[i].slug,'US')
                }
                setSponsorDocs(docs)
                setSponsorData({
                  ...sponsorData,
                  categorySlugs: newValue,
                })
                console.log('here are docs', docs);
              }}
              renderInput={params => (
                <TextField {...params} label="" variant="standard" />
              )}
            />
          </Row>
          <FormControlLabel
            control={
              <Checkbox
                checked={globalStatus}
                disabled
                onChange={e => {
                  setGlobalStatus(e.target.checked)
                }}
              />
            }
            label="Global"
          />
          {/* <Row className="mb-3">
            <label className="col-md-2 col-form-label">Continent Names</label>
            <Autocomplete
              {...continentCodeAutoCompleteProps}
              multiple
              disabled={globalStatus || sponsorData.countryCodes.length != 0}
              id="controlled-demo"
              value={
                sponsorData.continentCodes ? sponsorData.continentCodes : ""
              }
              onChange={(event, newValue) => {
                // let newSlugs = newValue.map(a => a.slug)
                setSponsorData({
                  ...sponsorData,
                  continentCodes: newValue,
                })
              }}
              renderInput={params => (
                <TextField {...params} label="" variant="standard" />
              )}
            />
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Country Names</label>
            <Autocomplete
              {...countryCodeAutoCompleteProps}
              multiple
              id="controlled-demo"
              disabled={globalStatus || sponsorData.continentCodes.length != 0}
              value={sponsorData.countryCodes ? sponsorData.countryCodes : ""}
              onChange={async (event, newValue) => {
                // let newSlugs = newValue.map(a => a.slug)
                let productRanks = Array(newValue.length).fill(0)
                setSponsorData({
                  ...sponsorData,
                  countryCodes: newValue,
                  productRanks,
                })
                let docs = []
                for (let j = 0; j < sponsorData.categorySlugs.length; j++) {
                  let c_slug = sponsorData.categorySlugs[j]
                  for (let i = 0; i < newValue.length; i++) {
                    let code = newValue[i]
                    console.log(c_slug, code)
                    docs[i] = await fetchSponsorDocData(c_slug, code)
                  }
                }
                console.log(docs)
                setSponsorDocs(docs)
              }}
              renderInput={params => (
                <TextField {...params} label="" variant="standard" />
              )}
            />
          </Row> */}
          {/* {sponsorData.countryCodes.length != 0 ? ( */}
          {/* {sponsorData.countryCodes.length != 0 ? ( */}
            <Row className="mb-3">
              <Nav tabs>
                {/* {console.log(sponsorData.categorySlugs)} */}
                {sponsorData.categorySlugs.map((item, index) => {
                  return (
                    <NavItem>
                      <NavLink
                        className={activeTab == index + 1 ? "active" : ""}
                        onClick={() => {
                          setActiveTab(index + 1)
                        }}
                      >
                        {item?.slug}
                      </NavLink>
                    </NavItem>
                  )
                })}
              </Nav>
              <TabContent activeTab={activeTab}>
                {sponsorData.categorySlugs.map((item, index) => {
                  return (
                    <TabPane tabId={index + 1}>
                      
                      <Row className="mt-2">
                        {
                          sponsorDocs?.find(sponDoc=>sponDoc.categorySlug === item.slug)?.products?.length > 0 ?
                          // console.log('this is here',sponsorDocs)
                          sponsorDocs?.find(sponDoc=>sponDoc.categorySlug === item.slug)?.products?.map((product, productIndex)=>{
                            return <p className="mb-0">{productIndex}. {product}</p>
                          })
                          :
                          <p>No sponsored product in this category</p>
                        }
                      </Row>
                      <div className="mt-4 d-flex align-items-center gx-2">
                        <label className="col-md-2 col-form-label required">Choose Product Rank (ranking starts from 0)</label>
                        <input
                          type="number"
                          value={sponsorData.productRanks[index]}
                          max={sponsorDocs?.find(sponDoc=>sponDoc.categorySlug === item.slug)?.products?.length || 0}
                          min={0}
                          // disabled="disabled"
                          onChange={e => {
                            setSponsorData({
                              ...sponsorData,
                              productRanks: [
                                ...sponsorData.productRanks.slice(0, index),
                                parseInt(e.target.value),
                                ...sponsorData.productRanks.slice(index + 1),
                              ],
                            })
                          }}
                          
                        />
                      </div>
                      <div className="mt-4 d-flex align-items-center gx-2">
                        <label className="col-md-2 col-form-label required">Product Url</label>
                        <input type="text" value={sponsorData.productUrls[index]} onChange={e => {
                            setSponsorData({
                              ...sponsorData,
                              productUrls: [
                                ...sponsorData.productUrls.slice(0, index),
                                e.target.value,
                                ...sponsorData.productUrls.slice(index + 1),
                              ],
                            })
                          }} required/>
                      </div>
                      {getCountryCodeRows(item)}
                    </TabPane>
                  )
                })}
              </TabContent>
            </Row>
          {/* ) : (
            ""
          )} */}
          {/* <Row className="mb-3">
            <br />
            Note - 1. Either of the continent code or country code can be
            selected at a time 2. O is the topmost rank product can have
            <br />
          </Row> */}
          <Button color="success" type="submit">
            Add
          </Button>
        </form>
      </CardBody>
    </Card>
  )
}

export default connect(null, { setBreadcrumbItems })(AddSponsor)
