import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Button, Row } from "reactstrap"
import toast, { Toaster } from "react-hot-toast"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "store/actions"
import { getAllCategories } from "repository/CategoryRepository"
// import { addClient, addGlossary } from "repository/GlossaryRepository"
import { useHistory } from "react-router-dom"
import TextField from "@mui/material/TextField"
import AutocompleteMui from "@mui/material/Autocomplete"

function AddClient({ setBreadcrumbItems }) {
  const history = useHistory()

  const breadcrumbItems = [
    {
      item: "Client",
      path: "/clients",
    },
    {
      item: "Add",
      path: "/add-client",
    },
  ]



  const [clientData, setClientData] = useState({
    "name": "",
    "email": "",
    "password": "",
    "productSlug": ""
  })
  const [productSearchResult, setProductSearchResult] = useState([])
    const productSearchAutoCompleteProps = {
        options: productSearchResult.map(item => item.slug),
        // getOptionLabel: option => option.slug || "",
    }


    
    async function addClient(data) {
        let url = `${process.env.REACT_APP_ADMIN_ROLE_BACKEND_URL}/client`
        const token = localStorage.getItem("token")
        const res = await fetch(url,
            {
                method : 'POST',
                body : JSON.stringify(data),
                headers: {
                    // apiKey: process.env.REACT_APP_API_TOKEN,
                    "User-Agent":"Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
                    'x-access-token':token,
                    "Content-Type": "application/json",
                }
            },
        )
        const resData = await res.json()
        console.log(resData);
        return resData
        
    }

  const handleOnSubmit = e => {
    e.preventDefault()

    const answer = window.confirm(
      `Are you sure you want to add ${clientData.name}`
    )
    const id = toast.loading("Processing your Request...");
    if (answer) {
        addClient(clientData)
        .then(response => {
          if (response.status) {
            toast.success(response.message)
            history.goBack()
          } else {
            toast.error(response.message)
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      toast.error("Client Not Added")
    }
    toast.dismiss(id);
  }
  useEffect(() => {
    setBreadcrumbItems("Add Client", breadcrumbItems)

  }, [])
  return (
    <Card>
      <CardBody>
        <Toaster />
        <CardTitle>Add Client</CardTitle>
        <form onSubmit={handleOnSubmit}>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label required">Name</label>
            <div className="col-md-10">
              <input
                className="form-control"
                placeholder="Name"
                value={clientData.name}
                onChange={e => {
                  setClientData({ ...clientData, name: String(e.target.value) })
                }}
                required
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Email</label>
            <div className="col-md-10">
              <input
                className="form-control"
                placeholder="Email"
                value={clientData.email}
                onChange={e => {
                  setClientData({ ...clientData, email: String(e.target.value) })
                }}
                required
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Password</label>
            <div className="col-md-10">
              <input
                className="form-control"
                placeholder="password"
                type="password"
                value={clientData.password}
                onChange={e => {
                  setClientData({ ...clientData, password: String(e.target.value) })
                }}
                required
              />
            </div>
          </Row>
          {/* <Row className="mb-3">
            <label className="col-md-2 col-form-label">Product slug</label>
            <div className="col-md-10">
              <input
                className="form-control"
                placeholder="product slug"
                value={clientData.productSlug}
                onChange={e => {
                  setClientData({ ...clientData, productSlug: e.target.value })
                }}
                required
              />
            </div>
          </Row> */}
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Product</label>
            <div className="col-md-10">
                <AutocompleteMui
                    {...productSearchAutoCompleteProps}
                    id="prod-controlled-demo"
                    noOptionsText={"Search Product"}
                    onInputChange={(event, value, reason) => {
                        if (value) {
                        fetch(
                            `${process.env.REACT_APP_API_BASE_URL}/product?_search=` +
                            value,
                            {
                            method: "GET",
                            headers: {
                                apiKey: process.env.REACT_APP_API_TOKEN,
                            },
                            }
                        )
                            .then(function (response) {
                            return response.json()
                            })
                            .then(function (myJson) {
                            setProductSearchResult(myJson.products)
                            })
                        } else {
                        setProductSearchResult([])
                        }
                    }}
                    // value={
                    //   productDetails.migratedToProductSlug
                    //     ? productDetails.migratedToProductSlug
                    //     : ""
                    // }
                    onChange={(event, newValue) => {
                        // let newSlugs = objArray.map(a => a.slug)
                        setClientData({
                        ...clientData,
                        // migratedToProductName: newValue.name,
                            productSlug: String(newValue),
                        })
                    }}
                    
                    // defaultValue={{ label: productDetails.migratedToProductName }}
                    renderInput={params => (
                        <TextField {...params} label="" variant="outlined" size="small" />
                    )}
                />
            </div>
          </Row>
          <Button color="success" type="submit">
            Add
          </Button>
        </form>
      </CardBody>
    </Card>
  )
}

export default connect(null, { setBreadcrumbItems })(AddClient)
