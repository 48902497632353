import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Button, Row } from "reactstrap"
import toast, { Toaster } from "react-hot-toast"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "store/actions"
import { getAllCategories } from "repository/CategoryRepository"
// import { editClient, addGlossary } from "repository/GlossaryRepository"
import { useHistory, useLocation } from "react-router-dom"
import TextField from "@mui/material/TextField"
import AutocompleteMui from "@mui/material/Autocomplete"

function EditClient({ setBreadcrumbItems }) {
  const history = useHistory()
  const {
    state: { client },
  } = useLocation()

  const breadcrumbItems = [
    {
      item: "Client",
      path: "/clients",
    },
    {
      item: "Edit",
      path: "/edit-client",
    },
  ]



  const [clientData, setClientData] = useState({
    "name": JSON.parse(client).name,
    "email": JSON.parse(client).email,
    // "password" : "",
    "productSlug": JSON.parse(client).productSlug,
    "client_id":JSON.parse(client).client_id
  })
  const [productSearchResult, setProductSearchResult] = useState([])
    const productSearchAutoCompleteProps = {
        options: productSearchResult.map(item => item.slug),
        // getOptionLabel: option => option.slug || "",
    }


    
    async function editClient(data) {
        delete data.productSlug
        let url = `${process.env.REACT_APP_ADMIN_ROLE_BACKEND_URL}/client/${clientData.client_id}`
        const token = localStorage.getItem("token")
        const res = await fetch(url,
            {
                method : 'PUT',
                body : JSON.stringify(data),
                headers: {
                    // apiKey: process.env.REACT_APP_API_TOKEN,
                    // "User-Agent":"Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
                    'x-access-token':token,
                    "Content-Type": "application/json",
                }
            },
        )
        const resData = await res.json()
        console.log(resData);
        return resData
        
    }

  const handleOnSubmit = e => {
    e.preventDefault()

    const answer = window.confirm(
      `Are you sure you want to add ${clientData.name}`
    )
    const id = toast.loading("Processing your Request...");
    if (answer) {
        editClient(clientData)
        .then(response => {
          if (response.status) {
            toast.success(response.message)
            history.goBack()
          } else {
            toast.error(response.message)
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      toast.error("Client Not Added")
    }
    toast.dismiss(id);
  }
  useEffect(() => {
    setBreadcrumbItems("Add Client", breadcrumbItems)

  }, [])
  return (
    <Card>
      <CardBody>
        <Toaster />
        <CardTitle>Edit Client</CardTitle>
        <form onSubmit={handleOnSubmit}>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label required">Name</label>
            <div className="col-md-10">
              <input
                className="form-control"
                placeholder="Name"
                value={clientData.name}
                onChange={e => {
                  setClientData({ ...clientData, name: String(e.target.value) })
                }}
                required
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Email</label>
            <div className="col-md-10">
              <input
                className="form-control"
                placeholder="Email"
                value={clientData.email}
                onChange={e => {
                  setClientData({ ...clientData, email: String(e.target.value) })
                }}
                required
              />
            </div>
          </Row>
          {/* <Row className="mb-3">
            <label className="col-md-2 col-form-label">Password</label>
            <div className="col-md-10">
              <input
                className="form-control"
                placeholder="password"
                type="password"
                value={clientData.password}
                onChange={e => {
                  setClientData({ ...clientData, password: String(e.target.value) })
                }}
                required
              />
            </div>
          </Row> */}
          {/* <Row className="mb-3">
            <label className="col-md-2 col-form-label">Product slug</label>
            <div className="col-md-10">
              <input
                className="form-control"
                placeholder="product slug"
                value={clientData.productSlug}
                onChange={e => {
                  setClientData({ ...clientData, productSlug: e.target.value })
                }}
                required
              />
            </div>
          </Row> */}
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Product</label>
            <div className="col-md-10">
              <input
                className="form-control"
                placeholder="Email"
                value={clientData.productSlug}
                // onChange={e => {
                //   setClientData({ ...clientData, email: String(e.target.value) })
                // }}
                disabled
              />
            </div>
          </Row>
          <Button color="success" type="submit">
            Save
          </Button>
        </form>
      </CardBody>
    </Card>
  )
}

export default connect(null, { setBreadcrumbItems })(EditClient)
