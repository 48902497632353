import S3FileUpload from "react-s3"
window.Buffer = window.Buffer || require("buffer").Buffer

export const upload = async ({ dirname, file }) => {
  try {
    // config = { ...config, dirName: dirname }
    let data = new FormData()
    const url = `${process.env.REACT_APP_API_BASE_URL}/image/${dirname}.jpg`
    console.log(url, dirname)
    data.append("image", file)
    const h = {} //headers
    h.Accept = "application/json" //if you expect JSON response
    h.apiKey = process.env.REACT_APP_API_TOKEN
    const res = await fetch(url, {
      method: "POST",
      headers: h,
      body: data,
    })
    const res_data = await res.json()
    console.log(res_data)
    // const res = await S3FileUpload.uploadFile(file, config)
    return res_data.url
  } catch (err) {
    console.log(err)
  }
}
