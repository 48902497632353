import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

import { loginAdmin } from "repository/AuthRepository"

function* loginUser({ payload }) {
  try {
    let response = yield call(loginAdmin, {
      username: payload.user.username,
      password: payload.user.password,
    })
    if (response.status == 1) {
      yield put(loginSuccess({ data: response.data, token: response.token }))
      localStorage.setItem("authUser", JSON.stringify(response.data))
      localStorage.setItem("token", response.token)
      payload.history.push("/dashboard")
    } else {
      yield put(apiError(response.message))
    }
  } catch (error) {
    console.log(error)
    yield put(apiError("Some error occoured!!"))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
