import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import CloseIcon from "@mui/icons-material/Close"

export default function VideoDnDList({ list, setList, droppableId }) {
  const handleDrop = droppedItem => {
    if (!droppedItem.destination) return
    var updatedList = [...list]
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
    setList(updatedList)
  }

  return (
    <DragDropContext onDragEnd={handleDrop}>
      <Droppable droppableId={droppableId}>
        {provided => (
          <div
            style={{ height: 240, overflowY: "scroll", overflowX: "hidden" }}
            className="list-container"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {list.map((item, index) => (
              <Draggable
                key={`media-${index}`}
                draggableId={`media-${index}`}
                index={index}
              >
                {provided => (
                  <div
                    className="d-flex mt-1 mb-1"
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                  >
                    <button
                      style={{
                        backgroundColor: "lightgray",
                        padding: "0",
                        float: "right",
                      }}
                      onClick={e => {
                        if (list) {
                          const listCopy = JSON.parse(JSON.stringify(list))
                          listCopy.splice(index, 1)
                          setList(listCopy)
                        }
                      }}
                    >
                      <CloseIcon />
                    </button>
                    <p align="center">
                      <iframe height={150} src={item.videoUrl} />{" "}
                    </p>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
