import Repository from "./Repository"

export function getAllCategories() {
  return new Promise((resolve, reject) => {
    Repository.get(`/category`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getHomePageCategories() {
  return new Promise((resolve, reject) => {
    Repository.get("/category/home-page")
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getEditorPickProducts(slug) {
  return new Promise((resolve, reject) => {
    Repository.get(`/category/${slug}/products/editor-picks`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getCategoryInfo(slug) {
  return new Promise((resolve, reject) => {
    Repository.get(`/category/${slug}/glance`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
export function getCategoryFeatures(slug) {
  return new Promise((resolve, reject) => {
    Repository.get(`/category/${slug}/glance`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getRelatedCategories(slug) {
  return new Promise((resolve, reject) => {
    Repository.get(`/category/${slug}/products/related-categories`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getParentCategories() {
  return new Promise((resolve, reject) => {
    Repository.get("/all-categories")
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getProductsByCategory(slug) {
  return new Promise((resolve, reject) => {
    Repository.get(`/category/${slug}/products?page=1`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function addCategory(data) {
  return new Promise((resolve, reject) => {
    Repository.post(`/category`, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        console.log(dataToBuffer)
        reject(error)
      })
  })
}

export function updateCategory(data) {
  return new Promise((resolve, reject) => {
    Repository.put(`/category`, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function deleteCategory(slug) {
  return new Promise((resolve, reject) => {
    Repository.delete(`/category?slug=${slug}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
