import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Input,
  Label,
  Button,
} from "reactstrap"
import toast, { Toaster } from "react-hot-toast"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "store/actions"
import { addSubadmin } from "repository/AuthRepository"

function AddSubadmin({ setBreadcrumbItems }) {
  const token = localStorage.getItem("token")
  const breadcrumbItems = [
    {
      item: "Subadmins",
      path: "#",
    },
    {
      item: "Add Subadmin",
      path: "/subadmins",
    },
  ]
  const [permissions, setPermissions] = useState({
    category: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    product: {
      create: false,
      read: false,
      update: false,
      delete: false,
      sponsor: false,
    },
    glossary: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
  })
  const [subadmin, setSubadmin] = useState({
    username: "",
    email: "",
    password: "",
    confirm_password: "",
    permissions: "",
  })
  const addUpdateData = () => {
    const id = toast.loading("Processing your Request...");
    if (subadmin.password != subadmin.confirm_password) {
      toast.error("Passwords don't match")
    } else {
      let data = JSON.parse(JSON.stringify(subadmin))
      data["permissions"] = JSON.stringify(permissions)
      addSubadmin(data, token).then(response => {
        if (response.status == 1) {
          toast.success("Subadmin added successfully!!")
        } else {
          toast.error(response.message)
        }
      })
    }
    toast.dismiss(id);
  }
  useEffect(() => {
    setBreadcrumbItems("Add Subadmin", breadcrumbItems)
  }, [])
  return (
    <Card>
      <CardBody>
        <CardTitle>Add Subadmin</CardTitle>
        <Toaster />
        <form
          onSubmit={e => {
            e.preventDefault()
            addUpdateData()
          }}
        >
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Username</label>
            <div className="col-md-10">
              <input
                className="form-control"
                type="text"
                placeholder="Username"
                value={subadmin.username}
                required
                onChange={e => {
                  setSubadmin({ ...subadmin, username: e.target.value })
                }}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Email</label>
            <div className="col-md-10">
              <input
                className="form-control"
                type="text"
                placeholder="Email"
                value={subadmin.email}
                required
                onChange={e => {
                  setSubadmin({ ...subadmin, email: e.target.value })
                }}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Password</label>
            <div className="col-md-10">
              <input
                className="form-control"
                type="password"
                value={subadmin.password}
                required
                onChange={e => {
                  setSubadmin({ ...subadmin, password: e.target.value })
                }}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Confirm Password</label>
            <div className="col-md-10">
              <input
                className="form-control"
                type="password"
                value={subadmin.confirm_password}
                required
                onChange={e => {
                  setSubadmin({ ...subadmin, confirm_password: e.target.value })
                }}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Category</label>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={permissions.category.create}
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    category: {
                      ...permissions.category,
                      create: e.target.checked,
                    },
                  })
                }
              />
              <Label check>Create</Label>
            </div>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={permissions.category.read}
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    category: {
                      ...permissions.category,
                      read: e.target.checked,
                    },
                  })
                }
              />
              <Label check>Read</Label>
            </div>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={permissions.category.update}
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    category: {
                      ...permissions.category,
                      update: e.target.checked,
                    },
                  })
                }
              />
              <Label check>Update</Label>
            </div>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={permissions.category.delete}
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    category: {
                      ...permissions.category,
                      delete: e.target.checked,
                    },
                  })
                }
              />
              <Label check>Delete</Label>
            </div>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={
                  permissions.category.create &&
                  permissions.category.read &&
                  permissions.category.update &&
                  permissions.category.delete
                    ? true
                    : false
                }
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    category: {
                      ...permissions.category,
                      create: e.target.checked,
                      read: e.target.checked,
                      update: e.target.checked,
                      delete: e.target.checked,
                    },
                  })
                }
              />
              <Label check>All</Label>
            </div>
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Product</label>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={permissions.product.create}
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    product: {
                      ...permissions.product,
                      create: e.target.checked,
                    },
                  })
                }
              />
              <Label check>Create</Label>
            </div>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={permissions.product.read}
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    product: { ...permissions.product, read: e.target.checked },
                  })
                }
              />
              <Label check>Read</Label>
            </div>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={permissions.product.update}
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    product: {
                      ...permissions.product,
                      update: e.target.checked,
                    },
                  })
                }
              />
              <Label check>Update</Label>
            </div>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={permissions.product.delete}
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    product: {
                      ...permissions.product,
                      delete: e.target.checked,
                    },
                  })
                }
              />
              <Label check>Delete</Label>
            </div>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={permissions.product.sponsor}
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    product: {
                      ...permissions.product,
                      sponsor: e.target.checked,
                    },
                  })
                }
              />
              <Label check>Sponsor</Label>
            </div>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={
                  permissions.product.create &&
                  permissions.product.read &&
                  permissions.product.update &&
                  permissions.product.delete &&
                  permissions.product.sponsor
                    ? true
                    : false
                }
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    product: {
                      ...permissions.category,
                      create: e.target.checked,
                      read: e.target.checked,
                      update: e.target.checked,
                      delete: e.target.checked,
                      sponsor: e.target.checked,
                    },
                  })
                }
              />
              <Label check>All</Label>
            </div>
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Glossary</label>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={permissions.glossary.create}
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    glossary: {
                      ...permissions.glossary,
                      create: e.target.checked,
                    },
                  })
                }
              />
              <Label check>Create</Label>
            </div>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={permissions.glossary.read}
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    glossary: {
                      ...permissions.glossary,
                      read: e.target.checked,
                    },
                  })
                }
              />
              <Label check>Read</Label>
            </div>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={permissions.glossary.update}
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    glossary: {
                      ...permissions.glossary,
                      update: e.target.checked,
                    },
                  })
                }
              />
              <Label check>Update</Label>
            </div>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={permissions.glossary.delete}
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    glossary: {
                      ...permissions.glossary,
                      delete: e.target.checked,
                    },
                  })
                }
              />
              <Label check>Delete</Label>
            </div>
            <div className="col-md-2 col-form-label">
              <Input
                type="checkbox"
                checked={
                  permissions.glossary.create &&
                  permissions.glossary.read &&
                  permissions.glossary.update &&
                  permissions.glossary.delete
                    ? true
                    : false
                }
                onChange={e =>
                  setPermissions({
                    ...permissions,
                    glossary: {
                      ...permissions.category,
                      create: e.target.checked,
                      read: e.target.checked,
                      update: e.target.checked,
                      delete: e.target.checked,
                    },
                  })
                }
              />
              <Label check>All</Label>
            </div>
          </Row>
          <Button type="submit" color="primary">
            Add
          </Button>
        </form>
      </CardBody>
    </Card>
  )
}

export default connect(null, { setBreadcrumbItems })(AddSubadmin)
