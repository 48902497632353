import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Card, CardTitle, CardBody, Row, Col, Button } from "reactstrap"
import toast, { Toaster } from "react-hot-toast"
import { setBreadcrumbItems } from "store/actions"
import { AiFillCloseCircle, AiFillCaretDown } from "react-icons/ai"

import {
  getParentCategories,
  addCategory,
  getAllCategories,
} from "repository/CategoryRepository"

function AddCategory({ setBreadcrumbItems }) {
  const [parentCategories, setParentCategories] = useState([])
  const [allCategories, setAllCategories] = useState([])
  const [categoryData, setCategoryData] = useState({
    name: "",
    desc: "",
    showOnHomepage: false,
    isLatest: true,
    isTrending: false,
    isPopular: false,
  })
  const breadcrumbItems = [
    {
      item: "Add Category",
      path: "#",
    },
  ]
  const getInvalidCategoryNamesInput = input => {
    const invalidCategoryNames = []
    const rHtml = []
    if (input == "" || input.length < 4) {
      return rHtml
    }
    if (allCategories.length > 0) {
      allCategories.forEach(category => {
        if (category.name.toLowerCase().startsWith(input.toLowerCase())) {
          invalidCategoryNames.push(category.name)
        }
      })
    }
    invalidCategoryNames.forEach(name => {
      rHtml.push(
        <div>
          {name}{" "}
          <AiFillCloseCircle style={{ color: "red", fontSize: "1.5em" }} />
        </div>
      )
    })
    console.log(rHtml)
    return rHtml
  }
  const handleFormSubmit = e => {
    e.preventDefault()
    if (
      categoryData["parentCategoryName"] ==
        "Other Categories (No Parent Category)" ||
      categoryData["parentCategoryName"] == ""
    ) {
      delete categoryData["parentCategoryName"]
    }
    const addCategoryPromise = addCategory(categoryData)
    toast.promise(addCategoryPromise, {
      loading: "Upload in Process",
      success: "Category Added Successfully",
      error: "Error while uploading category",
    })
    window.location = `${window.location.hostname}/all-categories`
  }
  useEffect(() => {
    setBreadcrumbItems("Add Category", breadcrumbItems)

    getParentCategories()
      .then(response => {
        setCategoryData({
          ...categoryData,
          parentCategoryName: response[0].name,
        })
        setParentCategories(response)
      })
      .catch(error => {
        console.log(error)
      })
    getAllCategories()
      .then(response => {
        setAllCategories(response)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  return (
    <Card>
      <CardBody>
        <Toaster />
        <form onSubmit={handleFormSubmit}>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label required">
              Category Name
            </label>
            <div className="col-md-10">
              <input
                className="form-control"
                placeholder="Category Name"
                name="name"
                onChange={e => {
                  setCategoryData({ ...categoryData, name: e.target.value })
                }}
                required
              />
              {getInvalidCategoryNamesInput(categoryData.name)}
            </div>
          </Row>

          <Row className="mb-3">
            <label className="col-md-2 col-form-label required">
              Category Desc
            </label>
            <div className="col-md-10">
              <textarea
                className="form-control"
                placeholder="Category Description"
                name="desc"
                maxLength="2000"
                onChange={e => {
                  setCategoryData({ ...categoryData, desc: e.target.value })
                }}
                required
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Parent Category</label>
            <div className="col-md-10">
              <select
                className="form-control"
                name="parentCategoryName"
                style={{
                  appearance: "auto",
                }}
                onChange={e => {
                  setCategoryData({
                    ...categoryData,
                    parentCategoryName: e.target.value,
                  })
                }}
              >
                {parentCategories.map(element => (
                  <option value={element.name}>{element.name}</option>
                ))}
              </select>
              {/* <AiFillCaretDown /> */}
            </div>
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label required">Type</label>
            <div
              className="col-md-10"
              onChange={e =>
                setCategoryData({ ...categoryData, type: e.target.value })
              }
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
              }}
            >
              <Col span={12}>
                {" "}
                <input
                  type="radio"
                  value="Category"
                  defaultChecked
                  name="type"
                />{" "}
                Category
              </Col>
              <Col span={12}>
                <input type="radio" value="Collection" name="type" /> Collection{" "}
              </Col>
            </div>
          </Row>
          <Row>
            <Col span={12}>
              <label>Is trending</label>
              <input
                type="checkbox"
                name="isTrending"
                style={{ marginLeft: "10px" }}
                onChange={e => {
                  setCategoryData({
                    ...categoryData,
                    isTrending: e.target.checked,
                  })
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>Is popular</label>
              <input
                type="checkbox"
                name="isPopular"
                style={{ marginLeft: "10px" }}
                onChange={e => {
                  setCategoryData({
                    ...categoryData,
                    isPopular: e.target.checked,
                  })
                }}
              />
            </Col>
          </Row>
          <Button color="primary">Add</Button>
        </form>
      </CardBody>
    </Card>
  )
}

export default connect(null, { setBreadcrumbItems })(AddCategory)
