import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Input } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import {
  deleteCategory,
  getAllCategories,
  getParentCategories,
  updateCategory,
} from "repository/CategoryRepository"
import { Link } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"

function AllCategories() {
  const user = JSON.parse(localStorage.getItem("authUser"))
  const permissions = JSON.parse(user.permissions)
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: "Slug",
        field: "slug",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Parent Category",
        field: "parent_category",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [],
  })
  const [parentCategories, setParentCategories] = useState([])
  const getParentCategory = slug => {
    let parentCat = ""
    parentCategories.forEach(element => {
      let parentCatFilter = element.subCategoryInfo.filter(e => e.slug == slug)
      if (parentCatFilter.length != 0) {
        parentCat = element.name
      }
    })
    return parentCat
  }
  const handleCategoryDeleteClick = slug => {
    const answer = window.confirm(`Are you sure you want to delete ${slug}`)
    const id = toast.loading("Processing your Request...");
    if (answer) {
      deleteCategory(slug)
        .then(response => {
          if (response.success == true) {
            toast.success("Category deleted successfully!!")
            // console.log(tableData.rows)
            // let tableRows = JSON.parse(JSON.stringify(tableData.rows))
            // let filteredRows = tableRows.filter(e => e.slug != slug)
            // console.log(filteredRows)
            // setTableData({ ...tableData, rows: filteredRows })
            window.location.reload(true)
          } else {
            toast.error("Some error occoured!!")
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      toast.error("Category Not Deleted")
    }
    toast.dismiss(id);
  }

  useEffect(() => {
    getParentCategories()
      .then(response => {
        setParentCategories(response)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  useEffect(() => {
    getAllCategories()
      .then(categories => {
        categories.forEach(element => {
          element["parent_category"] = getParentCategory(element.slug)
          element["actions"] = (
            <>
              {permissions.category.update ? (
                <Link to={`/category-detail/${element.slug}`}>
                  <a
                    className="btn btn-success"
                    style={{ marginBottom: "10px", width: "100%" }}
                  >
                    Edit
                  </a>
                </Link>
              ) : (
                ""
              )}
              {permissions.category.delete ? (
                <a
                  className="btn btn-danger"
                  style={{ marginBottom: "10px", width: "100%" }}
                  onClick={e => {
                    e.preventDefault()
                    handleCategoryDeleteClick(element.slug)
                  }}
                >
                  Delete
                </a>
              ) : (
                ""
              )}
            </>
          )
        })
        setTableData({ ...tableData, rows: categories })
      })
      .catch(error => {
        console.log(error)
      })
  }, [parentCategories])
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Toaster />
          {/* <CardTitle className="h4">All Categories</CardTitle> */}
          <MDBDataTable responsive bordered data={tableData} />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default AllCategories
