import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Button } from "reactstrap"
import { deleteProduct } from "repository/ProductRepository"
import MaterialTable from "material-table"
import { ThemeProvider, createTheme } from "@mui/material"
import { Link } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"

function DeletedProducts() {
  const user = JSON.parse(localStorage.getItem("authUser"))
  const permissions = JSON.parse(user.permissions)
  const columns = [
    {
      title: "Logo",
      field: "logo",
      filtering: false,
    },
    {
      title: "Slug",
      field: "slug",
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Category",
      field: "primaryCategorySlug",
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
    },
  ]
  const defaultMaterialTheme = createTheme()
  const handleProductDeleteClick = slug => {
    const answer = window.confirm(`Are you sure you want to delete ${slug}`)
    const id = toast.loading("Processing your Request...");
    if (answer) {
      deleteProduct(slug)
        .then(response => {
          if (response.success == true) {
            toast.success("Delete Successful")
          } else {
            toast.error("Some error occurred!!")
          }
        })
        .catch(error => {
          console.log(error)
          toast.error("Some error occurred")
        })
    } else {
      toast.error("Product Not Deleted")
    }
    toast.dismiss(id);
  }
  const getActions = element => (
    <>
      {permissions.product.update ? (
        <Link to={`/product-detail/${element.slug}`}>
          <a className="btn btn-primary" style={{ width: "100%" }}>
            Edit Product
          </a>
        </Link>
      ) : (
        ""
      )}
    </>
  )
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Toaster />
          <ThemeProvider theme={defaultMaterialTheme}>
            <MaterialTable
              title=""
              columns={columns}
              options={{
                debounceInterval: 700,
                padding: "dense",
                filtering: true,
              }}
              data={query =>
                new Promise((resolve, reject) => {
                  // prepare your data and then call resolve like this:
                  let url = `${process.env.REACT_APP_API_BASE_URL}/product?showDelProducts=true`
                  //searching
                  if (query.search) {
                    url += `&_search=${query.search}`
                  }
                  if (query.filters.length) {
                    const filter = query.filters.map(filter => {
                      return `&${filter.column.field}=${filter.value}`
                    })
                    url += filter.join("")
                  }
                  //pagination
                  url += `&page=${query.page + 1}`
                  url += `&pageCount=${query.pageSize}`

                  fetch(url, {
                    method: "GET",
                    headers: {
                      apiKey: process.env.REACT_APP_API_TOKEN,
                    },
                  })
                    .then(resp => resp.json())
                    .then(resp => {
                      resp.products.forEach(element => {
                        element["logo"] = (
                          <img
                            src={element.logo}
                            alt={element.logoAltText}
                            height={50}
                            width={50}
                            style={{
                              objectFit : 'contain'
                            }}
                          />
                        )
                        element["actions"] = getActions(element)
                      })
                      resolve({
                        data: resp.products, // your data array
                        page: query.page, // current page number
                        totalCount: resp.totalProductsCount, // total row number
                      })
                    })
                })
              }
            />
          </ThemeProvider>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default DeletedProducts
