import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  Row,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import {
  getRecentlyAddedProducts,
  deleteProduct,
} from "repository/ProductRepository"
import MaterialTable from "material-table"
import { ThemeProvider, createTheme } from "@mui/material"
import { Link } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"
import GeneralTable from "components/Common/GeneralTable"
import {
  countryCodeContinentCodeMap,
  countryNameCodeMap,
  continentNameCodeMap,
} from "common/data/geoData"
import { is } from "bluebird"

function SponsoredProducts() {
  const user = JSON.parse(localStorage.getItem("authUser"))
  const [viewSponsorModal, setViewSponsorModal] = useState(false)
  const [viewSponsor, setViewSponsor] = useState({})
  const permissions = JSON.parse(user.permissions)
  const columns = [
    {
      title: "Slug",
      field: "slug",
    },
    {
      title: "Categories",
      field: "categorySlugs",
    },
    { title: "Actions", field: "actions" },
  ]
  const categoryWiseColumns = [
    {
      title: "Category Slug",
      field: "categorySlug",
    },
    { title: "Country Codes", field: "countryCodes" },
    { title: "Continent Codes", field: "continentCodes" },
    { title: "Global", field: "global" },
  ]
  const defaultMaterialTheme = createTheme()
  const findContinents = countryCodes => {
    console.log(countryCodes)
    const allContinentCodes = continentNameCodeMap.map(c => c.continentCode)
    const presentContinentCodes = []
    allContinentCodes.forEach(continentCode => {
      const continentCountries = countryCodeContinentCodeMap.filter(
        a => a.continentCode == continentCode
      )
      const continentCountryCodes = continentCountries.map(a => a.countryCode)
      const isContinentPresent = continentCountryCodes.every(val =>
        countryCodes.includes(val)
      )
      if (isContinentPresent) {
        presentContinentCodes.push(continentCode)
      }
    })
    return presentContinentCodes
  }
  const getCategoryWiseInfo = categoryWiseInfo => {
    console.log(categoryWiseInfo)

    const continentCodes = findContinents(categoryWiseInfo.countryCodes)
    let globalStatus = "No"
    const allContinentCodes = continentNameCodeMap.map(c => c.continentCode)
    const isContinentPresent = allContinentCodes.every(val =>
      continentCodes.includes(val)
    )
    if (isContinentPresent) {
      globalStatus = "Yes"
    }
    categoryWiseInfo.global = globalStatus
    categoryWiseInfo.continentCodes = continentCodes
    return categoryWiseInfo
  }
  const modifyViewSponsor = sponsor => {
    sponsor.categoryWiseInfo.forEach(category => {
      const newCategory = getCategoryWiseInfo(category)
      category.countryCodes = category.countryCodes.join(",")
      category.global = newCategory.global
      category.continentCodes = newCategory.continentCodes.join(",")
      if (category.continentCodes.length > 0) {
        category.countryCodes = ""
      }
    })
    return sponsor.categoryWiseInfo
  }
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p></p>
              <Link to={"/add-sponsor"}>
                <a className="btn btn-primary">Add Sponsor</a>
              </Link>
            </div>
          </CardTitle>
          <Toaster />
          <ThemeProvider theme={defaultMaterialTheme}>
            <MaterialTable
              title=""
              columns={columns}
              options={{
                debounceInterval: 700,
                padding: "dense",
                filtering: true,
              }}
              data={query =>
                new Promise((resolve, reject) => {
                  // prepare your data and then call resolve like this:
                  let url = `${process.env.REACT_APP_API_BASE_URL}/sponsor?`
                  //searching
                  if (query.search) {
                    url += `_search=${query.search}`
                  }
                  if (query.filters.length) {
                    const filter = query.filters.map(filter => {
                      return `&${filter.column.field}=${filter.value}`
                    })
                    url += filter.join("")
                  }
                  //pagination
                  url += `&page=${query.page + 1}`
                  url += `&pageCount=${query.pageSize}`

                  fetch(url, {
                    method: "GET",
                    headers: {
                      apiKey: process.env.REACT_APP_API_TOKEN,
                    },
                  })
                    .then(resp => resp.json())
                    .then(resp => {
                      resp.products.forEach(product => {
                        let categorySlugs = product.categoryWiseInfo.map(
                          c => c.categorySlug
                        )

                        product["categorySlugs"] = categorySlugs.join(",")
                        product["actions"] = (
                          <div>
                            <Button
                              color="danger"
                              style={{ width: "100%", marginTop: "10px" }}
                              onClick={() => {
                                setViewSponsor(product)
                                setViewSponsorModal(true)
                              }}
                            >
                              Delete Sponsor
                            </Button>
                            <br />
                            <Link
                              to={{
                                pathname: "/edit-sponsor",
                                state: { product: JSON.stringify(product) },
                              }}
                            >
                              <a
                                className="btn btn-primary"
                                style={{ width: "100%", marginTop: "10px" }}
                              >
                                Edit Sponsor
                              </a>
                            </Link>
                          </div>
                        )
                      })
                      resolve({
                        data: resp.products, // your data array
                        page: query.page, // current page number
                        totalCount: resp.totalProductsCount, // total row number
                      })
                    })
                })
              }
            />
          </ThemeProvider>
        </CardBody>
      </Card>
      <Modal
        isOpen={viewSponsorModal}
        toggle={() => setViewSponsorModal(!viewSponsorModal)}
        style={{ maxWidth: "800px", maxHeight: "600px" }}
      >
        <ModalHeader toggle={() => setViewSponsorModal(!viewSponsorModal)}>
          View Sponsored Product
        </ModalHeader>

        <ModalBody>
          <Row className="mb-3 mt-3">
            <label className="col-md-2 col-form-label">Slug</label>
            <div className="col-md-10">
              <input
                className="form-control"
                value={viewSponsor.slug}
                disabled
                type="text"
              />
            </div>
            <br />{" "}
          </Row>
          <Row>
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                title="Category Wise Info"
                columns={categoryWiseColumns}
                options={{
                  debounceInterval: 700,
                  padding: "dense",
                  filtering: true,
                }}
                data={
                  viewSponsor.categoryWiseInfo
                    ? modifyViewSponsor(viewSponsor)
                    : []
                }
              />
            </ThemeProvider>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setViewSponsorModal(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default SponsoredProducts
