import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "store/actions"
import { connect } from "react-redux"
import { Card, CardBody, CardTitle, Button } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { getProducAlternatives } from "repository/ProductRepository"
import { Link, useParams } from "react-router-dom"

function ProductAlternatives({ setBreadcrumbItems }) {
  const { slug } = useParams()
  const [apiResponse, setApiResponse] = useState({})
  const [productAlternatives, setProductAlternatives] = useState({
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "categoryName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Subtitle",
        field: "subtitle",
        sort: "asc",
        width: 150,
      },
      {
        label: "Rating",
        field: "rating",
        sort: "asc",
        width: 150,
      },
      {
        label: "Score",
        field: "score",
        sort: "asc",
        width: 150,
      },
      {
        label: "No. of reviews",
        field: "numberOfReviews",
        sort: "asc",
        width: 150,
      },
      {
        label: "Logo",
        field: "logo",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [],
  })
  const breadcrumbItems = []
  useEffect(() => {
    setBreadcrumbItems("Alternatives", breadcrumbItems)
    getProducAlternatives(slug)
      .then(response => {
        response.products.forEach(element => {
          element["logo"] = (
            <img
              src={element.logo}
              alt={element.logoAltText}
              height={50}
              width={50}
            />
          )
          element["actions"] = (
            <>
              <Link to={`/product-detail/${element.slug}`}>
                <a
                  className="btn btn-danger"
                  style={{ marginBottom: "10px", width: "100%" }}
                >
                  Details
                </a>
              </Link>
              <Button
                type="button"
                color="primary"
                className="waves-effect waves-light"
                onClick={() => {
                  window.open(element.url, "_blank")
                }}
                style={{ width: "100%" }}
              >
                View
              </Button>
            </>
          )
        })
        setApiResponse(response)
        setProductAlternatives({
          ...productAlternatives,
          rows: response.products,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="h4">{apiResponse.name}</CardTitle>
          <MDBDataTable responsive bordered data={productAlternatives} />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(ProductAlternatives)
