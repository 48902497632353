import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "store/actions"
import { connect } from "react-redux"
import { Card, CardBody, CardTitle } from "reactstrap"
import GeneralTable from "components/Common/GeneralTable"
import { getRelatedCategories } from "repository/CategoryRepository"
import { Link, useParams } from "react-router-dom"

function RelatedCategories({ setBreadcrumbItems }) {
  const { slug } = useParams()
  const [relatedCategories, setRelatedCategories] = useState([])
  const [apiResponse, setApiResponse] = useState({})
  const columns = [
    {
      name: "ID",
      data_key: "_id",
    },
    {
      name: "Name",
      data_key: "name",
    },
    {
      name: "Actions",
      render: record => (
        <>
          <Link to={`/editor-picks/${record.slug}`}>
            <a
              className="btn btn-primary"
              style={{ marginBottom: "10px", width: "100%" }}
            >
              Editor Picks
            </a>{" "}
          </Link>
          <br />
          <Link to={`/category-features/${record.slug}`}>
            <a
              className="btn btn-success"
              style={{ marginBottom: "10px", width: "100%" }}
            >
              Features
            </a>{" "}
          </Link>
        </>
      ),
    },
  ]
  const breadcrumbItems = []
  useEffect(() => {
    setBreadcrumbItems("Related Categories", breadcrumbItems)
    getRelatedCategories(slug)
      .then(response => {
        setApiResponse(response)
        setRelatedCategories(response.relatedCategories)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="h4">{apiResponse.categoryName}</CardTitle>
          <GeneralTable columns={columns} data={relatedCategories} />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(RelatedCategories)
