import { useEffect, useState } from "react"
import {
  Dropdown as StrapDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

export default function Dropdown({
  direction,
  header,
  options,
  multipleChoice,
  onChange,
  startValues,
  ...args
}) {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(startValues || [])

  useEffect(() => {
    onChange(values)
  }, [values])

  const toggle = () => setOpen(prev => !prev)
  const toggleItem = item => {
    if (multipleChoice) {
      setValues(prev =>
        prev.includes(item)
          ? [
              ...prev.slice(0, prev.indexOf(item)),
              ...prev.slice(prev.indexOf(item) + 1, prev.length),
            ]
          : [...prev, item]
      )
    } else {
      setValues([item])
    }
  }

  return (
    <StrapDropdown isOpen={open} toggle={toggle} direction={direction}>
      <DropdownToggle caret>{header}</DropdownToggle>
      <DropdownMenu {...args}>
        {options.map(option => (
          <DropdownItem
            style={{ background: values.includes(option) ? "#CCF" : "inherit" }}
          >
            <span
              onClick={e => {
                if (multipleChoice) e.stopPropagation()
                toggleItem(option)
              }}
            >
              {option}
            </span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </StrapDropdown>
  )
}
