import React from "react"
import { Table } from "reactstrap"

export default function GeneralTable({ columns, data }) {
  return (
    <div className="table-responsive">
      <Table className="table mb-0 table-striped">
        <thead>
          <tr>
            {columns ? columns.map(column => <th>{column.name}</th>) : ""}
          </tr>
        </thead>
        <tbody>
          {data
            ? data.map(record => {
                return (
                  <tr>
                    {columns
                      ? columns.map(column => (
                          <td>
                            {column.hasOwnProperty("render")
                              ? column.render(record)
                              : record[column.data_key]}
                          </td>
                        ))
                      : ""}
                  </tr>
                )
              })
            : ""}
        </tbody>
      </Table>
    </div>
  )
}
