const continentNameCodeMap = [
  { continentName: "Asia", continentCode: "AS" },
  { continentName: "Europe", continentCode: "EU" },
  { continentName: "Africa", continentCode: "AF" },
  { continentName: "Oceania", continentCode: "OC" },
  { continentName: "North America", continentCode: "NA" },
  { continentName: "South America", continentCode: "SA" },
]
const countryNameCodeMap = [
  { countryName: "Afghanistan", countryCode: "AF" },
  { countryName: "Aland Islands", countryCode: "AX" },
  { countryName: "Albania", countryCode: "AL" },
  { countryName: "Algeria", countryCode: "DZ" },
  { countryName: "American Samoa", countryCode: "AS" },
  { countryName: "Andorra", countryCode: "AD" },
  { countryName: "Angola", countryCode: "AO" },
  { countryName: "Anguilla", countryCode: "AI" },
  { countryName: "Antigua and Barbuda", countryCode: "AG" },
  { countryName: "Argentina", countryCode: "AR" },
  { countryName: "Armenia", countryCode: "AM" },
  { countryName: "Aruba", countryCode: "AW" },
  { countryName: "Australia", countryCode: "AU" },
  { countryName: "Austria", countryCode: "AT" },
  { countryName: "Azerbaijan", countryCode: "AZ" },
  { countryName: "Bahamas", countryCode: "BS" },
  { countryName: "Bahrain", countryCode: "BH" },
  { countryName: "Bangladesh", countryCode: "BD" },
  { countryName: "Barbados", countryCode: "BB" },
  { countryName: "Belarus", countryCode: "BY" },
  { countryName: "Belgium", countryCode: "BE" },
  { countryName: "Belize", countryCode: "BZ" },
  { countryName: "Benin", countryCode: "BJ" },
  { countryName: "Bermuda", countryCode: "BM" },
  { countryName: "Bhutan", countryCode: "BT" },
  { countryName: "Bolivia", countryCode: "BO" },
  { countryName: "Bonaire, Sint Eustatius and Saba", countryCode: "BQ" },
  { countryName: "Bosnia and Herzegovina", countryCode: "BA" },
  { countryName: "Botswana", countryCode: "BW" },
  { countryName: "Brazil", countryCode: "BR" },
  { countryName: "British Virgin Islands", countryCode: "VG" },
  { countryName: "Brunei Darussalam", countryCode: "BN" },
  { countryName: "Bulgaria", countryCode: "BG" },
  { countryName: "Burkina Faso", countryCode: "BF" },
  { countryName: "Burundi", countryCode: "BI" },
  { countryName: "Cambodia", countryCode: "KH" },
  { countryName: "Cameroon", countryCode: "CM" },
  { countryName: "Canada", countryCode: "CA" },
  { countryName: "Cape Verde", countryCode: "CV" },
  { countryName: "Cayman Islands", countryCode: "KY" },
  { countryName: "Central African Republic", countryCode: "CF" },
  { countryName: "Chad", countryCode: "TD" },
  { countryName: "Chile", countryCode: "CL" },
  { countryName: "China", countryCode: "CN" },
  { countryName: "Colombia", countryCode: "CO" },
  { countryName: "Comoros", countryCode: "KM" },
  { countryName: "Congo", countryCode: "CG" },
  { countryName: "Cook Islands", countryCode: "CK" },
  { countryName: "Costa Rica", countryCode: "CR" },
  { countryName: "Cote d'Ivoire", countryCode: "CI" },
  { countryName: "Croatia", countryCode: "HR" },
  { countryName: "Cuba", countryCode: "CU" },
  { countryName: "Curacao", countryCode: "CW" },
  { countryName: "Cyprus", countryCode: "CY" },
  { countryName: "Czech Republic", countryCode: "CZ" },
  { countryName: "Democratic People's Republic of Korea", countryCode: "KP" },
  { countryName: "Democratic Republic of the Congo", countryCode: "CD" },
  { countryName: "Denmark", countryCode: "DK" },
  { countryName: "Djibouti", countryCode: "DJ" },
  { countryName: "Dominica", countryCode: "DM" },
  { countryName: "Dominican Republic", countryCode: "DO" },
  { countryName: "Ecuador", countryCode: "EC" },
  { countryName: "Egypt", countryCode: "EG" },
  { countryName: "El Salvador", countryCode: "SV" },
  { countryName: "Equatorial Guinea", countryCode: "GQ" },
  { countryName: "Eritrea", countryCode: "ER" },
  { countryName: "Estonia", countryCode: "EE" },
  { countryName: "Ethiopia", countryCode: "ET" },
  { countryName: "European Union", countryCode: "EU" },
  { countryName: "Faeroe Islands", countryCode: "FO" },
  { countryName: "Falkland Islands", countryCode: "FK" },
  { countryName: "Fiji", countryCode: "FJ" },
  { countryName: "Finland", countryCode: "FI" },
  { countryName: "France", countryCode: "FR" },
  { countryName: "French Guiana", countryCode: "GF" },
  { countryName: "French Polynesia", countryCode: "PF" },
  { countryName: "Gabon", countryCode: "GA" },
  { countryName: "Gambia", countryCode: "GM" },
  { countryName: "Georgia", countryCode: "GE" },
  { countryName: "Germany", countryCode: "DE" },
  { countryName: "Ghana", countryCode: "GH" },
  { countryName: "Gibraltar", countryCode: "GI" },
  { countryName: "Great Britain code", countryCode: "UK" },
  { countryName: "Greece", countryCode: "GR" },
  { countryName: "Greenland", countryCode: "GL" },
  { countryName: "Grenada", countryCode: "GD" },
  { countryName: "Guadeloupe", countryCode: "GP" },
  { countryName: "Guam", countryCode: "GU" },
  { countryName: "Guatemala", countryCode: "GT" },
  { countryName: "Guernsey", countryCode: "GG" },
  { countryName: "Guinea", countryCode: "GN" },
  { countryName: "Guinea-Bissau", countryCode: "GW" },
  { countryName: "Guyana", countryCode: "GY" },
  { countryName: "Haiti", countryCode: "HT" },
  { countryName: "Holy See", countryCode: "VA" },
  { countryName: "Honduras", countryCode: "HN" },
  {
    countryName: "Hong Kong Special Administrative Region of China",
    countryCode: "HK",
  },
  { countryName: "Hungary", countryCode: "HU" },
  { countryName: "Iceland", countryCode: "IS" },
  { countryName: "India", countryCode: "IN" },
  { countryName: "Indonesia", countryCode: "ID" },
  { countryName: "Iran (Islamic Republic of)", countryCode: "IR" },
  { countryName: "Iraq", countryCode: "IQ" },
  { countryName: "Ireland", countryCode: "IE" },
  { countryName: "Isle of Man", countryCode: "IM" },
  { countryName: "Israel", countryCode: "IL" },
  { countryName: "Italy", countryCode: "IT" },
  { countryName: "Jamaica", countryCode: "JM" },
  { countryName: "Japan", countryCode: "JP" },
  { countryName: "Jersey", countryCode: "JE" },
  { countryName: "Jordan", countryCode: "JO" },
  { countryName: "Kazakhstan", countryCode: "KZ" },
  { countryName: "Kenya", countryCode: "KE" },
  { countryName: "Kiribati", countryCode: "KI" },
  { countryName: "Kuwait", countryCode: "KW" },
  { countryName: "Kyrgyzstan", countryCode: "KG" },
  { countryName: "Lao People's Democratic Republic", countryCode: "LA" },
  { countryName: "Latvia", countryCode: "LV" },
  { countryName: "Lebanon", countryCode: "LB" },
  { countryName: "Lesotho", countryCode: "LS" },
  { countryName: "Liberia", countryCode: "LR" },
  { countryName: "Libya", countryCode: "LY" },
  { countryName: "Liechtenstein", countryCode: "LI" },
  { countryName: "Lithuania", countryCode: "LT" },
  { countryName: "Luxembourg", countryCode: "LU" },
  {
    countryName: "Macao Special Administrative Region of China",
    countryCode: "MO",
  },
  { countryName: "Madagascar", countryCode: "MG" },
  { countryName: "Malawi", countryCode: "MW" },
  { countryName: "Malaysia", countryCode: "MY" },
  { countryName: "Maldives", countryCode: "MV" },
  { countryName: "Mali", countryCode: "ML" },
  { countryName: "Malta", countryCode: "MT" },
  { countryName: "Marshall Islands", countryCode: "MH" },
  { countryName: "Martinique", countryCode: "MQ" },
  { countryName: "Mauritania", countryCode: "MR" },
  { countryName: "Mauritius", countryCode: "MU" },
  { countryName: "Mayotte", countryCode: "YT" },
  { countryName: "Metropolitan France", countryCode: "FX" },
  { countryName: "Mexico", countryCode: "MX" },
  { countryName: "Micronesia (Federated States of)", countryCode: "FM" },
  { countryName: "Monaco", countryCode: "MC" },
  { countryName: "Mongolia", countryCode: "MN" },
  { countryName: "Montenegro", countryCode: "ME" },
  { countryName: "Montserrat", countryCode: "MS" },
  { countryName: "Morocco", countryCode: "MA" },
  { countryName: "Mozambique", countryCode: "MZ" },
  { countryName: "Myanmar", countryCode: "MM" },
  { countryName: "Namibia", countryCode: "NA" },
  { countryName: "Nauru", countryCode: "NR" },
  { countryName: "Nepal", countryCode: "NP" },
  { countryName: "Netherlands", countryCode: "NL" },
  { countryName: "Netherlands Antilles", countryCode: "AN" },
  { countryName: "New Caledonia", countryCode: "NC" },
  { countryName: "New Zealand", countryCode: "NZ" },
  { countryName: "Nicaragua", countryCode: "NI" },
  { countryName: "Niger", countryCode: "NE" },
  { countryName: "Nigeria", countryCode: "NG" },
  { countryName: "Niue", countryCode: "NU" },
  { countryName: "Norfolk Island", countryCode: "NF" },
  { countryName: "Northern Mariana Islands", countryCode: "MP" },
  { countryName: "Norway", countryCode: "NO" },
  { countryName: "Oman", countryCode: "OM" },
  { countryName: "Pakistan", countryCode: "PK" },
  { countryName: "Palau", countryCode: "PW" },
  { countryName: "Panama", countryCode: "PA" },
  { countryName: "Papua New Guinea", countryCode: "PG" },
  { countryName: "Paraguay", countryCode: "PY" },
  { countryName: "Peru", countryCode: "PE" },
  { countryName: "Philippines", countryCode: "PH" },
  { countryName: "Pitcairn", countryCode: "PN" },
  { countryName: "Poland", countryCode: "PL" },
  { countryName: "Portugal", countryCode: "PT" },
  { countryName: "Puerto Rico", countryCode: "PR" },
  { countryName: "Qatar", countryCode: "QA" },
  { countryName: "Republic of Korea", countryCode: "KR" },
  { countryName: "Republic of Moldova", countryCode: "MD" },
  { countryName: "Reunion", countryCode: "RE" },
  { countryName: "Romania", countryCode: "RO" },
  { countryName: "Russian Federation", countryCode: "RU" },
  { countryName: "Rwanda", countryCode: "RW" },
  { countryName: "Saint Barthelemy", countryCode: "BL" },
  {
    countryName: "Saint Helena, Ascension, and Tristan da Cunha",
    countryCode: "SH",
  },
  { countryName: "Saint Kitts and Nevis", countryCode: "KN" },
  { countryName: "Saint Lucia", countryCode: "LC" },
  { countryName: "Saint Martin (French part)", countryCode: "MF" },
  { countryName: "Saint Pierre and Miquelon", countryCode: "PM" },
  { countryName: "Saint Vincent and the Grenadines", countryCode: "VC" },
  { countryName: "Samoa", countryCode: "WS" },
  { countryName: "San Marino", countryCode: "SM" },
  { countryName: "Sao Tome and Principe", countryCode: "ST" },
  { countryName: "Saudi Arabia", countryCode: "SA" },
  { countryName: "Senegal", countryCode: "SN" },
  { countryName: "Serbia", countryCode: "RS" },
  { countryName: "Seychelles", countryCode: "SC" },
  { countryName: "Sierra Leone", countryCode: "SL" },
  { countryName: "Singapore", countryCode: "SG" },
  { countryName: "Sint Maarten (Dutch part)", countryCode: "SX" },
  { countryName: "Slovakia", countryCode: "SK" },
  { countryName: "Slovenia", countryCode: "SI" },
  { countryName: "Solomon Islands", countryCode: "SB" },
  { countryName: "Somalia", countryCode: "SO" },
  { countryName: "South Africa", countryCode: "ZA" },
  { countryName: "South Sudan", countryCode: "SS" },
  { countryName: "Spain", countryCode: "ES" },
  { countryName: "Sri Lanka", countryCode: "LK" },
  { countryName: "State of Palestine", countryCode: "PS" },
  { countryName: "Sudan", countryCode: "SD" },
  { countryName: "Suriname", countryCode: "SR" },
  { countryName: "Svalbard and Jan Mayen Islands", countryCode: "SJ" },
  { countryName: "Swaziland", countryCode: "SZ" },
  { countryName: "Sweden", countryCode: "SE" },
  { countryName: "Switzerland", countryCode: "CH" },
  { countryName: "Syrian Arab Republic", countryCode: "SY" },
  { countryName: "Taiwan", countryCode: "TW" },
  { countryName: "Tajikistan", countryCode: "TJ" },
  { countryName: "Thailand", countryCode: "TH" },
  {
    countryName: "The former Yugoslav Republic of Macedonia",
    countryCode: "MK",
  },
  { countryName: "Timor-Leste", countryCode: "TL" },
  { countryName: "Togo", countryCode: "TG" },
  { countryName: "Tokelau", countryCode: "TK" },
  { countryName: "Tonga", countryCode: "TO" },
  { countryName: "Trinidad and Tobago", countryCode: "TT" },
  { countryName: "Tunisia", countryCode: "TN" },
  { countryName: "Turkey", countryCode: "TR" },
  { countryName: "Turkmenistan", countryCode: "TM" },
  { countryName: "Turks and Caicos Islands", countryCode: "TC" },
  { countryName: "Tuvalu", countryCode: "TV" },
  { countryName: "Uganda", countryCode: "UG" },
  { countryName: "Ukraine", countryCode: "UA" },
  { countryName: "United Arab Emirates", countryCode: "AE" },
  {
    countryName: "United Kingdom of Great Britain and Northern Ireland",
    countryCode: "GB",
  },
  { countryName: "United Republic of Tanzania", countryCode: "TZ" },
  { countryName: "United States Virgin Islands", countryCode: "VI" },
  { countryName: "United States of America", countryCode: "US" },
  { countryName: "Uruguay", countryCode: "UY" },
  { countryName: "Uzbekistan", countryCode: "UZ" },
  { countryName: "Vanuatu", countryCode: "VU" },
  { countryName: "Venezuela", countryCode: "VE" },
  { countryName: "Vietnam", countryCode: "VN" },
  { countryName: "Wallis and Futuna Islands", countryCode: "WF" },
  { countryName: "Western Sahara", countryCode: "EH" },
  { countryName: "Yemen", countryCode: "YE" },
  { countryName: "Zambia", countryCode: "ZM" },
  { countryName: "Zimbabwe", countryCode: "ZW" },
]
const countryCodeContinentCodeMap = [
  { countryCode: "AF", continentCode: "AS" },
  { countryCode: "AX", continentCode: "EU" },
  { countryCode: "AL", continentCode: "EU" },
  { countryCode: "DZ", continentCode: "AF" },
  { countryCode: "AS", continentCode: "OC" },
  { countryCode: "AD", continentCode: "EU" },
  { countryCode: "AO", continentCode: "AF" },
  { countryCode: "AI", continentCode: "NA" },
  { countryCode: "AG", continentCode: "NA" },
  { countryCode: "AR", continentCode: "SA" },
  { countryCode: "AM", continentCode: "AS" },
  { countryCode: "AW", continentCode: "NA" },
  { countryCode: "AU", continentCode: "OC" },
  { countryCode: "AT", continentCode: "EU" },
  { countryCode: "AZ", continentCode: "AS" },
  { countryCode: "BS", continentCode: "NA" },
  { countryCode: "BH", continentCode: "AS" },
  { countryCode: "BD", continentCode: "AS" },
  { countryCode: "BB", continentCode: "NA" },
  { countryCode: "BY", continentCode: "EU" },
  { countryCode: "BE", continentCode: "EU" },
  { countryCode: "BZ", continentCode: "NA" },
  { countryCode: "BJ", continentCode: "AF" },
  { countryCode: "BM", continentCode: "NA" },
  { countryCode: "BT", continentCode: "AS" },
  { countryCode: "BO", continentCode: "SA" },
  { countryCode: "BQ", continentCode: "NA" },
  { countryCode: "BA", continentCode: "EU" },
  { countryCode: "BW", continentCode: "AF" },
  { countryCode: "BR", continentCode: "SA" },
  { countryCode: "VG", continentCode: "NA" },
  { countryCode: "BN", continentCode: "AS" },
  { countryCode: "BG", continentCode: "EU" },
  { countryCode: "BF", continentCode: "AF" },
  { countryCode: "BI", continentCode: "AF" },
  { countryCode: "KH", continentCode: "AS" },
  { countryCode: "CM", continentCode: "AF" },
  { countryCode: "CA", continentCode: "NA" },
  { countryCode: "CV", continentCode: "AF" },
  { countryCode: "KY", continentCode: "NA" },
  { countryCode: "CF", continentCode: "AF" },
  { countryCode: "TD", continentCode: "AF" },
  { countryCode: "CL", continentCode: "SA" },
  { countryCode: "CN", continentCode: "AS" },
  { countryCode: "CO", continentCode: "SA" },
  { countryCode: "KM", continentCode: "AF" },
  { countryCode: "CG", continentCode: "AF" },
  { countryCode: "CK", continentCode: "OC" },
  { countryCode: "CR", continentCode: "NA" },
  { countryCode: "CI", continentCode: "AF" },
  { countryCode: "HR", continentCode: "EU" },
  { countryCode: "CU", continentCode: "NA" },
  { countryCode: "CW", continentCode: "NA" },
  { countryCode: "CY", continentCode: "AS" },
  { countryCode: "CZ", continentCode: "EU" },
  { countryCode: "KP", continentCode: "AS" },
  { countryCode: "CD", continentCode: "AF" },
  { countryCode: "DK", continentCode: "EU" },
  { countryCode: "DJ", continentCode: "AF" },
  { countryCode: "DM", continentCode: "NA" },
  { countryCode: "DO", continentCode: "NA" },
  { countryCode: "EC", continentCode: "SA" },
  { countryCode: "EG", continentCode: "AF" },
  { countryCode: "SV", continentCode: "NA" },
  { countryCode: "GQ", continentCode: "AF" },
  { countryCode: "ER", continentCode: "AF" },
  { countryCode: "EE", continentCode: "EU" },
  { countryCode: "ET", continentCode: "AF" },
  { countryCode: "EU", continentCode: "EU" },
  { countryCode: "FO", continentCode: "EU" },
  { countryCode: "FK", continentCode: "SA" },
  { countryCode: "FJ", continentCode: "OC" },
  { countryCode: "FI", continentCode: "EU" },
  { countryCode: "FR", continentCode: "EU" },
  { countryCode: "GF", continentCode: "SA" },
  { countryCode: "PF", continentCode: "OC" },
  { countryCode: "GA", continentCode: "AF" },
  { countryCode: "GM", continentCode: "AF" },
  { countryCode: "GE", continentCode: "AS" },
  { countryCode: "DE", continentCode: "EU" },
  { countryCode: "GH", continentCode: "AF" },
  { countryCode: "GI", continentCode: "EU" },
  { countryCode: "UK", continentCode: "EU" },
  { countryCode: "GR", continentCode: "EU" },
  { countryCode: "GL", continentCode: "NA" },
  { countryCode: "GD", continentCode: "NA" },
  { countryCode: "GP", continentCode: "NA" },
  { countryCode: "GU", continentCode: "OC" },
  { countryCode: "GT", continentCode: "NA" },
  { countryCode: "GG", continentCode: "EU" },
  { countryCode: "GN", continentCode: "AF" },
  { countryCode: "GW", continentCode: "AF" },
  { countryCode: "GY", continentCode: "SA" },
  { countryCode: "HT", continentCode: "NA" },
  { countryCode: "VA", continentCode: "EU" },
  { countryCode: "HN", continentCode: "NA" },
  { countryCode: "HK", continentCode: "AS" },
  { countryCode: "HU", continentCode: "EU" },
  { countryCode: "IS", continentCode: "EU" },
  { countryCode: "IN", continentCode: "AS" },
  { countryCode: "ID", continentCode: "AS" },
  { countryCode: "IR", continentCode: "AS" },
  { countryCode: "IQ", continentCode: "AS" },
  { countryCode: "IE", continentCode: "EU" },
  { countryCode: "IM", continentCode: "EU" },
  { countryCode: "IL", continentCode: "AS" },
  { countryCode: "IT", continentCode: "EU" },
  { countryCode: "JM", continentCode: "NA" },
  { countryCode: "JP", continentCode: "AS" },
  { countryCode: "JE", continentCode: "EU" },
  { countryCode: "JO", continentCode: "AS" },
  { countryCode: "KZ", continentCode: "AS" },
  { countryCode: "KE", continentCode: "AF" },
  { countryCode: "KI", continentCode: "OC" },
  { countryCode: "KW", continentCode: "AS" },
  { countryCode: "KG", continentCode: "AS" },
  { countryCode: "LA", continentCode: "AS" },
  { countryCode: "LV", continentCode: "EU" },
  { countryCode: "LB", continentCode: "AS" },
  { countryCode: "LS", continentCode: "AF" },
  { countryCode: "LR", continentCode: "AF" },
  { countryCode: "LY", continentCode: "AF" },
  { countryCode: "LI", continentCode: "EU" },
  { countryCode: "LT", continentCode: "EU" },
  { countryCode: "LU", continentCode: "EU" },
  { countryCode: "MO", continentCode: "AS" },
  { countryCode: "MG", continentCode: "AF" },
  { countryCode: "MW", continentCode: "AF" },
  { countryCode: "MY", continentCode: "AS" },
  { countryCode: "MV", continentCode: "AS" },
  { countryCode: "ML", continentCode: "AF" },
  { countryCode: "MT", continentCode: "EU" },
  { countryCode: "MH", continentCode: "OC" },
  { countryCode: "MQ", continentCode: "NA" },
  { countryCode: "MR", continentCode: "AF" },
  { countryCode: "MU", continentCode: "AF" },
  { countryCode: "YT", continentCode: "AF" },
  { countryCode: "FX", continentCode: "EU" },
  { countryCode: "MX", continentCode: "NA" },
  { countryCode: "FM", continentCode: "OC" },
  { countryCode: "MC", continentCode: "EU" },
  { countryCode: "MN", continentCode: "AS" },
  { countryCode: "ME", continentCode: "EU" },
  { countryCode: "MS", continentCode: "NA" },
  { countryCode: "MA", continentCode: "AF" },
  { countryCode: "MZ", continentCode: "AF" },
  { countryCode: "MM", continentCode: "AS" },
  { countryCode: "NA", continentCode: "AF" },
  { countryCode: "NR", continentCode: "OC" },
  { countryCode: "NP", continentCode: "AS" },
  { countryCode: "NL", continentCode: "EU" },
  { countryCode: "AN", continentCode: "NA" },
  { countryCode: "NC", continentCode: "OC" },
  { countryCode: "NZ", continentCode: "OC" },
  { countryCode: "NI", continentCode: "NA" },
  { countryCode: "NE", continentCode: "AF" },
  { countryCode: "NG", continentCode: "AF" },
  { countryCode: "NU", continentCode: "OC" },
  { countryCode: "NF", continentCode: "OC" },
  { countryCode: "MP", continentCode: "OC" },
  { countryCode: "NO", continentCode: "EU" },
  { countryCode: "OM", continentCode: "AS" },
  { countryCode: "PK", continentCode: "AS" },
  { countryCode: "PW", continentCode: "OC" },
  { countryCode: "PA", continentCode: "NA" },
  { countryCode: "PG", continentCode: "OC" },
  { countryCode: "PY", continentCode: "SA" },
  { countryCode: "PE", continentCode: "SA" },
  { countryCode: "PH", continentCode: "AS" },
  { countryCode: "PN", continentCode: "OC" },
  { countryCode: "PL", continentCode: "EU" },
  { countryCode: "PT", continentCode: "EU" },
  { countryCode: "PR", continentCode: "NA" },
  { countryCode: "QA", continentCode: "AS" },
  { countryCode: "KR", continentCode: "AS" },
  { countryCode: "MD", continentCode: "EU" },
  { countryCode: "RE", continentCode: "AF" },
  { countryCode: "RO", continentCode: "EU" },
  { countryCode: "RU", continentCode: "EU" },
  { countryCode: "RW", continentCode: "AF" },
  { countryCode: "BL", continentCode: "NA" },
  { countryCode: "SH", continentCode: "AF" },
  { countryCode: "KN", continentCode: "NA" },
  { countryCode: "LC", continentCode: "NA" },
  { countryCode: "MF", continentCode: "NA" },
  { countryCode: "PM", continentCode: "NA" },
  { countryCode: "VC", continentCode: "NA" },
  { countryCode: "WS", continentCode: "OC" },
  { countryCode: "SM", continentCode: "EU" },
  { countryCode: "ST", continentCode: "AF" },
  { countryCode: "SA", continentCode: "AS" },
  { countryCode: "SN", continentCode: "AF" },
  { countryCode: "RS", continentCode: "EU" },
  { countryCode: "SC", continentCode: "AF" },
  { countryCode: "SL", continentCode: "AF" },
  { countryCode: "SG", continentCode: "AS" },
  { countryCode: "SX", continentCode: "NA" },
  { countryCode: "SK", continentCode: "EU" },
  { countryCode: "SI", continentCode: "EU" },
  { countryCode: "SB", continentCode: "OC" },
  { countryCode: "SO", continentCode: "AF" },
  { countryCode: "ZA", continentCode: "AF" },
  { countryCode: "SS", continentCode: "AF" },
  { countryCode: "ES", continentCode: "EU" },
  { countryCode: "LK", continentCode: "AS" },
  { countryCode: "PS", continentCode: "AS" },
  { countryCode: "SD", continentCode: "AF" },
  { countryCode: "SR", continentCode: "SA" },
  { countryCode: "SJ", continentCode: "EU" },
  { countryCode: "SZ", continentCode: "AF" },
  { countryCode: "SE", continentCode: "EU" },
  { countryCode: "CH", continentCode: "EU" },
  { countryCode: "SY", continentCode: "AS" },
  { countryCode: "TW", continentCode: "AS" },
  { countryCode: "TJ", continentCode: "AS" },
  { countryCode: "TH", continentCode: "AS" },
  { countryCode: "MK", continentCode: "EU" },
  { countryCode: "TL", continentCode: "AS" },
  { countryCode: "TG", continentCode: "AF" },
  { countryCode: "TK", continentCode: "OC" },
  { countryCode: "TO", continentCode: "OC" },
  { countryCode: "TT", continentCode: "NA" },
  { countryCode: "TN", continentCode: "AF" },
  { countryCode: "TR", continentCode: "AS" },
  { countryCode: "TM", continentCode: "AS" },
  { countryCode: "TC", continentCode: "NA" },
  { countryCode: "TV", continentCode: "OC" },
  { countryCode: "UG", continentCode: "AF" },
  { countryCode: "UA", continentCode: "EU" },
  { countryCode: "AE", continentCode: "AS" },
  { countryCode: "GB", continentCode: "EU" },
  { countryCode: "TZ", continentCode: "AF" },
  { countryCode: "VI", continentCode: "NA" },
  { countryCode: "US", continentCode: "NA" },
  { countryCode: "UY", continentCode: "SA" },
  { countryCode: "UZ", continentCode: "AS" },
  { countryCode: "VU", continentCode: "OC" },
  { countryCode: "VE", continentCode: "SA" },
  { countryCode: "VN", continentCode: "AS" },
  { countryCode: "WF", continentCode: "OC" },
  { countryCode: "EH", continentCode: "AF" },
  { countryCode: "YE", continentCode: "AS" },
  { countryCode: "ZM", continentCode: "AF" },
  { countryCode: "ZW", continentCode: "AF" },
]
module.exports = {
  countryCodeContinentCodeMap,
  countryNameCodeMap,
  continentNameCodeMap,
}
