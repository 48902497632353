import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { setBreadcrumbItems } from "store/actions"
import {
  Card,
  CardBody,
  CardTitle,
  ButtonGroup,
  Button,
  Row,
  Col,
  Spinner,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { getGlossary, deleteGlossary } from "repository/GlossaryRepository"
import toast, { Toaster } from "react-hot-toast"
import MaterialTable from "material-table"
import { ThemeProvider, createTheme } from "@mui/material"

function AllGlossary({ setBreadcrumbItems }) {
  const user = JSON.parse(localStorage.getItem("authUser"))
  const permissions = JSON.parse(user.permissions)
  const breadcrumbItems = [
    {
      item: "Glossary",
      path: "/glossary",
    },
  ]
  const columns = [
    { title: "Letter", field: "letter" },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Slug",
      field: "slug",
    },
    {
      title: "Category",
      field: "categorySlugs",
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
    },
  ]
  const defaultMaterialTheme = createTheme()
  const getActions = element => (
    <>
      {permissions.product.update ? (
        <Link
          to={{
            pathname: "/edit-glossary",
            state: { glossary: JSON.stringify(element) },
          }}
        >
          <a className="btn btn-primary" style={{ width: "100%" }}>
            Edit Glossary
          </a>
        </Link>
      ) : (
        ""
      )}
    </>
  )

  useEffect(() => {
    setBreadcrumbItems("Glossary", breadcrumbItems)
  }, [])
  return (
    <Card>
      <CardBody>
        <CardTitle>
        <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            Glossary
          {permissions.glossary.read ? (
            <Link to={"/add-glossary"}>
              <a className="btn btn-primary">Add Glossary</a>
            </Link>
          ) : (
            ""
          )}
          </div>
        </CardTitle>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            title=""
            columns={columns}
            options={{
              debounceInterval: 700,
              padding: "dense",
              filtering: true,
            }}
            data={query =>
              new Promise((resolve, reject) => {
                // prepare your data and then call resolve like this:
                let url = `${process.env.REACT_APP_API_BASE_URL}/glossary?`
                //searching
                if (query.search) {
                  url += `_search=${query.search}`
                }
                if (query.filters.length) {
                  const filter = query.filters.map(filter => {
                    return `&${filter.column.field}=${filter.value}`
                  })
                  url += filter.join("")
                }
                //pagination
                url += `&page=${query.page + 1}`
                url += `&pageCount=${query.pageSize}`

                fetch(url, {
                  method: "GET",
                  headers: {
                    apiKey: process.env.REACT_APP_API_TOKEN,
                  },
                })
                  .then(resp => resp.json())
                  .then(resp => {
                    resp.features.forEach(element => {
                      element["letter"] = element["name"]
                        .toUpperCase()
                        .charAt(0)
                      element["actions"] = getActions(element)
                    })
                    resolve({
                      data: resp.features, // your data array
                      page: query.page, // current page number
                      totalCount: resp.featureCount, // total row number
                    })
                  })
              })
            }
          />
        </ThemeProvider>
        <Toaster />
      </CardBody>
    </Card>
  )
}

export default connect(null, { setBreadcrumbItems })(AllGlossary)
