import Repository, { ADMIN_ROLE_BACKEND_URL } from "./Repository"

export function loginAdmin({ username, password }) {
  return new Promise((resolve, reject) => {
    Repository.post(`${ADMIN_ROLE_BACKEND_URL}/login`, { username, password })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getAllSubadmins(token) {
  return new Promise((resolve, reject) => {
    Repository.get(`${ADMIN_ROLE_BACKEND_URL}/user`, {
      headers: { "x-access-token": token },
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function addSubadmin(subadmin, token) {
  return new Promise((resolve, reject) => {
    Repository.post(`${ADMIN_ROLE_BACKEND_URL}/user`, subadmin, {
      headers: { "x-access-token": token },
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function deleteSubAdmin(subadminID, token) {
  return new Promise((resolve, reject) => {
    Repository.delete(`${ADMIN_ROLE_BACKEND_URL}/user/${subadminID}`, {
      headers: { "x-access-token": token },
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
