import React, { useState, useEffect } from "react"
import { setBreadcrumbItems } from "store/actions"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Row,
  Col,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import GeneralTable from "components/Common/GeneralTable"
import { Link, useParams } from "react-router-dom"
import {
  getEditorPickProducts,
  getCategoryInfo,
  getRelatedCategories,
  getProductsByCategory,
  updateCategory,
  getCategoryFeatures,
  getParentCategories,
} from "repository/CategoryRepository"
import toast, { Toaster } from "react-hot-toast"

function CategoryDetail({ setBreadcrumbItems, match }) {
  const breadcrumbs = []
  const { slug } = match.params
  const [activeTab, setActiveTab] = useState(1)
  const [editorPicks, setEditorPicks] = useState([])
  const [parentCategories, setParentCategories] = useState([])
  const [defaultParentCategory, setDefaultParentCategory] = useState("")
  const [features, setFeatures] = useState([])
  const [pricing, setPricing] = useState([])
  const [marketSegments, setMarketSegments] = useState([])
  const [deploymentTypes, setDeploymentTypes] = useState([])
  const [relatedCategories, setRelatedCategories] = useState([])
  const [categoryProducts, setCategoryProducts] = useState([])
  const [categoryFeatures, setCategoryFeatures] = useState([])
  const [updatecategoryData, setUpdatecategoryData] = useState({
    categorySlug: slug,
  })
  const [categoryProductColumns, setCategoryProductColumns] = useState({
    columns: [
      {
        label: "Slug",
        field: "slug",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Subtitle",
        field: "subtitle",
        sort: "asc",
        width: 150,
      },
      {
        label: "Rating",
        field: "rating",
        sort: "asc",
        width: 150,
      },
      {
        label: "Score",
        field: "score",
        sort: "asc",
        width: 150,
      },
      {
        label: "No. of reviews",
        field: "numberOfReviews",
        sort: "asc",
        width: 150,
      },
      {
        label: "Logo",
        field: "logo",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [],
  })
  const editorPicksColumns = [
    {
      name: "Name",
      data_key: "name",
    },
    {
      name: "Image",
      render: record => <img src={record.image} height={50} width={50} />,
    },
    {
      name: "Actions",
      render: record => <Button color="success">Edit</Button>,
    },
  ]
  const featuresColumns = [
    {
      name: "ID",
      data_key: "_id",
    },
    {
      name: "Name",
      data_key: "name",
    },
  ]
  const relatedCategoriesColumns = [
    {
      name: "Slug",
      data_key: "slug",
    },
    {
      name: "Name",
      data_key: "name",
    },
  ]
  useEffect(() => {
    setBreadcrumbItems("Category Details", breadcrumbs)
    getEditorPickProducts(slug)
      .then(response => {
        setEditorPicks(response)
      })
      .catch(error => {
        console.log(error)
      })

    getCategoryInfo(slug)
      .then(response => {
        setUpdatecategoryData({
          categorySlug: response.slug,
          name: response.categoryName,
          desc: response.desc,
          type: response.type,
          showOnHomepage: response.showOnHomepage,
          isLatest: response.isLatest,
          isTrending: response.isTrending,
          isPopular: response.isPopular,
          parentCategoryId: response.parentCategoryId,
        })
      })
      .catch(error => {
        console.log(error)
      })
    getParentCategories()
      .then(response => {
        setParentCategories(response)
        if (updatecategoryData.parentCategoryId) {
          setDefaultParentCategory(
            getParentCategoryNameFromParentCategoryId(
              updatecategoryData.parentCategoryId
            )
          )
        } else {
          setDefaultParentCategory("Other Categories")
        }
      })
      .catch(error => {
        console.log(error)
      })
    getRelatedCategories(slug)
      .then(response => {
        setRelatedCategories(response.relatedCategories)
      })
      .catch(error => {
        console.log(error)
      })
    getProductsByCategory(slug)
      .then(response => {
        setFeatures(response.filters.features)
        setPricing(response.filters.pricing)
        setDeploymentTypes(response.filters.deploymentTypes)
        setMarketSegments(response.filters.marketSegments)
        response.products.forEach(element => {
          element["logo"] = (
            <img
              src={element.logo}
              alt={element.logoAltText}
              height={50}
              width={50}
            />
          )
          element["actions"] = (
            <>
              <Link to={`/product-detail/${element.slug}`}>
                <a
                  className="btn btn-danger"
                  style={{ marginBottom: "10px", width: "100%" }}
                >
                  Edit
                </a>
              </Link>
              <br />
              <Button
                type="button"
                color="primary"
                className="waves-effect waves-light"
                onClick={() => {
                  window.open(element.url, "_blank")
                }}
                style={{ marginBottom: "10px", width: "100%" }}
              >
                View
              </Button>
            </>
          )
        })
        setCategoryProductColumns({
          ...categoryProductColumns,
          rows: response.products,
        })
      })
      .catch(error => {
        console.log(error)
      })
    getCategoryFeatures(slug)
      .then(response => {
        setCategoryFeatures(response)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  const handleUpdateCategory = () => {
    let data = { ...updatecategoryData }
    if (updatecategoryData.hasOwnProperty("parentCategoryId")) {
      delete data.parentCategoryId
    }
    const answer = window.confirm(
      `Are you sure you want to update ${data.name}`
    )
    const id = toast.loading("Processing your Request...");
    if (answer) {
      updateCategory(data)
        .then(response => {
          if (response.success) {
            toast.success(response.message)
          } else {
            toast.error(response.message)
          }
        })
        .catch(error => {
          console.log(error)
          toast.error("Error in Updating Category")
        })
    } else {
      // Do nothing!
      toast.error("Category was not updated")
    }
    toast.dismiss(id);
  }
  const getParentCategoryNameFromParentCategoryId = id => {
    const parentCategoryObj = parentCategories.find(category => {
      return category._id === id
    })
    console.log(parentCategoryObj.name)
    return parentCategoryObj.name
  }

  return (
    <React.Fragment>
      <Card>
        {console.log(defaultParentCategory)}
        <CardTitle></CardTitle>
        <Toaster />
        <CardBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab == 1 ? "active" : ""}
                onClick={() => {
                  setActiveTab(1)
                }}
              >
                Edit
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab == 2 ? "active" : ""}
                onClick={() => {
                  setActiveTab(2)
                }}
              >
                Products
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab == 3 ? "active" : ""}
                onClick={() => {
                  setActiveTab(3)
                }}
              >
                Features
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab == 4 ? "active" : ""}
                onClick={() => {
                  setActiveTab(4)
                }}
              >
                Pricing
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab == 5 ? "active" : ""}
                onClick={() => {
                  setActiveTab(5)
                }}
              >
                Market Segments
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab == 6 ? "active" : ""}
                onClick={() => {
                  setActiveTab(6)
                }}
              >
                Deployment Types
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Category Slug</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder={updatecategoryData.categorySlug}
                    name="name"
                    value={updatecategoryData.categorySlug}
                    disabled="disabled"
                  />
                </div>
              </Row>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Category Name</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder={updatecategoryData.name}
                    name="name"
                    value={updatecategoryData.name}
                    disabled="disabled"
                  />
                </div>
              </Row>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Category Desc</label>
                <div className="col-md-10">
                  <textarea
                    className="form-control"
                    placeholder={updatecategoryData.desc}
                    name="desc"
                    value={updatecategoryData.desc}
                    maxLength="2000"
                    onChange={e =>
                      setUpdatecategoryData({
                        ...updatecategoryData,
                        desc: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </Row>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Category Type</label>
                <div
                  className="col-md-10"
                  onChange={e =>
                    setUpdatecategoryData({
                      ...updatecategoryData,
                      type: e.target.value,
                    })
                  }
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                  }}
                >
                  <Col span={12}>
                    {" "}
                    <input
                      type="radio"
                      value="Category"
                      defaultChecked={updatecategoryData.type === "Category"}
                      name="type"
                    />{" "}
                    Category
                  </Col>
                  <Col span={12}>
                    <input
                      type="radio"
                      value="Collection"
                      defaultChecked={updatecategoryData.type === "Collection"}
                      name="type"
                    />{" "}
                    Collection{" "}
                  </Col>
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-2 col-form-label">
                  Parent Category
                </label>
                <div className="col-md-10">
                  <select
                    className="form-control"
                    name="parentCategoryName"
                    defaultValue={defaultParentCategory}
                    style={{
                      appearance: "auto",
                    }}
                    onChange={e => {
                      if (e.target.value != "Other Categories") {
                        if (
                          updatecategoryData.hasOwnProperty("parentCategoryId")
                        ) {
                          let data = { ...updatecategoryData }
                          delete data.parentCategoryId
                          setUpdatecategoryData(data)
                        }
                        setUpdatecategoryData({
                          ...updatecategoryData,
                          parentCategoryName: e.target.value,
                        })
                      }
                    }}
                  >
                    {parentCategories.map(element => (
                      <option value={element.name}>{element.name}</option>
                    ))}
                  </select>
                </div>
              </Row>
              <Row>
                <Col span={6}>
                  <label>Show on homepage</label>
                  <input
                    type="checkbox"
                    name="showOnHomepage"
                    style={{ marginLeft: "10px" }}
                    checked={updatecategoryData.showOnHomepage}
                    onChange={e => {
                      setUpdatecategoryData({
                        ...updatecategoryData,
                        showOnHomepage: e.target.checked,
                      })
                    }}
                  />
                </Col>
                <Col span={6}>
                  <label>Is latest</label>
                  <input
                    type="checkbox"
                    name="isLatest"
                    style={{ marginLeft: "10px" }}
                    checked={updatecategoryData.isLatest}
                    onChange={e => {
                      setUpdatecategoryData({
                        ...updatecategoryData,
                        isLatest: e.target.checked,
                      })
                    }}
                  />
                </Col>
                <Col span={6}>
                  <label>Is trending</label>
                  <input
                    type="checkbox"
                    name="isTrending"
                    style={{ marginLeft: "10px" }}
                    checked={updatecategoryData.isTrending}
                    onChange={e => {
                      setUpdatecategoryData({
                        ...updatecategoryData,
                        isTrending: e.target.checked,
                      })
                    }}
                  />
                </Col>
                <Col span={6}>
                  <label>Is popular</label>
                  <input
                    type="checkbox"
                    name="isPopular"
                    style={{ marginLeft: "10px" }}
                    checked={updatecategoryData.isPopular}
                    onChange={e => {
                      setUpdatecategoryData({
                        ...updatecategoryData,
                        isPopular: e.target.checked,
                      })
                    }}
                  />
                </Col>
              </Row>
              <Button
                color="success"
                onClick={e => {
                  handleUpdateCategory()
                }}
              >
                Update
              </Button>
            </TabPane>
            <TabPane tabId={2}>
              {categoryProductColumns.rows.length == 0 ? (
                "No data available..."
              ) : (
                <MDBDataTable
                  responsive
                  bordered
                  data={categoryProductColumns}
                />
              )}
            </TabPane>
            <TabPane tabId={3}>
              {features.length == 0 ? (
                "No data available..."
              ) : (
                <GeneralTable columns={featuresColumns} data={features} />
              )}
            </TabPane>
            <TabPane tabId={4}>
              {pricing.length == 0 ? (
                "No data available..."
              ) : (
                <GeneralTable columns={featuresColumns} data={pricing} />
              )}
            </TabPane>
            <TabPane tabId={5}>
              {marketSegments.length == 0 ? (
                "No data available..."
              ) : (
                <GeneralTable columns={featuresColumns} data={marketSegments} />
              )}
            </TabPane>
            <TabPane tabId={6}>
              {deploymentTypes.length == 0 ? (
                "No data available..."
              ) : (
                <GeneralTable
                  columns={featuresColumns}
                  data={deploymentTypes}
                />
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(CategoryDetail)
