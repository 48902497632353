import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import toast, { Toaster } from "react-hot-toast"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "store/actions"
import { getAllCategories } from "repository/CategoryRepository"
import {
  countryCodeContinentCodeMap,
  countryNameCodeMap,
  continentNameCodeMap,
} from "common/data/geoData"
import {
  updateGlossary,
  getGlossaryBySlug,
} from "repository/GlossaryRepository"
import { useHistory, useLocation } from "react-router-dom"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import ProductAlternativeCard from "components/Common/ProductAlternativeCard"
import fetch from "node-fetch"

function EditSponsor({ setBreadcrumbItems }) {
  const history = useHistory()
  const {
    state: { product },
  } = useLocation()
  const breadcrumbItems = [
    {
      item: "Sponsor",
      path: "/product",
    },
    {
      item: "Edit",
      path: "/edit-sponsor",
    },
  ]
  const [activeTab, setActiveTab] = useState(1)
  const [sponsorDocs, setSponsorDocs] = useState([])
  const fetchSponsorDocData = async (categorySlug, countryCode) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/sponsor/doc?categorySlug=${categorySlug}&countryCode=${countryCode}`
      const response = await fetch(url, {
        method: "GET",
        headers: {
          apiKey: process.env.REACT_APP_API_TOKEN,
        },
      })
      const jsonData = await response.json()
      return jsonData
    } catch (error) {
      console.error("Error fetching data:", error)
      return {}
    }
  }
  const getCountryCodeRows = categorySlug => {
    {
      console.log(selectedCategory)
    }
    return (
      <Row className="mb-6">
        {selectedCategory.countryCodes.filter(item=>item === 'US').map((code, index) => {
          {
            let sponsorProducts = []
            const sponsorDoc = sponsorDocs.find(
              doc => doc.categorySlug == categorySlug && doc.countryCode == code
            )
            if (sponsorDoc && sponsorDoc.hasOwnProperty("products")) {
              sponsorProducts = sponsorDoc.products
            }
            return (
              <Row className="mb-3">
                <div>
                  {index + 1}. {code}
                </div>
                <div>
                  {" "}
                  <h6>Existing Products</h6>
                  <ul>
                    {" "}
                    {sponsorProducts.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <label>Choose Product Rank </label>
                  <input
                    type="number"
                    disabled={!sponsorDocs[index]}
                    value={selectedCategory.productRanks[index]}
                  />
                </div>
              </Row>
            )
          }
        })}
      </Row>
    )
  }

  const countryCodeAutoCompleteProps = {
    options: countryNameCodeMap.map(a => a.countryCode),
    getOptionLabel: option => option || "",
  }
  const continentCodeAutoCompleteProps = {
    options: continentNameCodeMap,
    getOptionLabel: option => option.continentName || "",
  }
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(
    JSON.parse(product).categoryWiseInfo[0]
  )
  const [products, setProducts] = useState([])
  const [sponsorData, setSponsorData] = useState({
    slug: JSON.parse(product).slug,
    categoryWiseInfo: JSON.parse(product).categoryWiseInfo,
  })
  const categoryAutoCompleteProps = {
    options: sponsorData.categoryWiseInfo,
    getOptionLabel: option => option.categorySlug || "",
  }
  const productAutoCompleteProps = {
    options: products,
    getOptionLabel: option => option || "",
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    toast.success("Sponsored Product Successfully Updated")
  }
  useEffect(() => {
    async function fetchAllSponsorDocData() {
      let docs = []
      let c_slug = selectedCategory.categorySlug
      for (let i = 0; i < selectedCategory.countryCodes.filter(item=>item === 'US').length; i++) {
        let code = selectedCategory.countryCodes[i]
        console.log(c_slug, code)
        docs[i] = await fetchSponsorDocData(c_slug, code)
      }
      setSponsorDocs(docs)
    }
    fetchAllSponsorDocData()
    setBreadcrumbItems("Edit Sponsor", breadcrumbItems)
    getAllCategories()
      .then(response => {
        let result = response.map(a => a.slug)
        setCategories(result)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  return (
    <Card>
      <CardBody>
        <Toaster />
        <CardTitle>Edit Sponsor</CardTitle>
        <form onSubmit={handleOnSubmit}>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label required">Slug</label>
            <div className="col-md-10">
              <input
                className="form-control"
                placeholder="Name"
                value={sponsorData.slug}
                disabled
                required
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label required">
              Select Category
            </label>
            <Autocomplete
              {...categoryAutoCompleteProps}
              id="controlled-demo"
              value={selectedCategory ? selectedCategory : ""}
              onChange={(event, newValue) => {
                setSelectedCategory(newValue)
              }}
              renderInput={params => (
                <TextField {...params} label="" variant="standard" />
              )}
            />
          </Row>
          <Button color="success" type="submit">
            Add Category
          </Button>
          <br />
          <Row className="mb-3">
            <br />
          </Row>
          {/* <Row className="mb-3">
            <label className="col-md-2 col-form-label">Continent Codes</label>
            <Autocomplete
              {...continentCodeAutoCompleteProps}
              multiple
              id="controlled-demo"
              // value={
              //   selectedCategory.continentCodes
              //     ? selectedCategory.continentCodes
              //     : ""
              // }
              // onChange={(event, newValue) => {
              //   // let newSlugs = newValue.map(a => a.slug)
              //   setSponsorData({
              //     ...sponsorData,
              //     continentCodes: newValue,
              //   })
              // }}
              renderInput={params => (
                <TextField {...params} label="" variant="standard" />
              )}
            />
          </Row> */}
          {/* <Row className="mb-3">
            <label className="col-md-2 col-form-label">Country Codes</label>
            <Autocomplete
              {...countryCodeAutoCompleteProps}
              multiple
              id="controlled-demo"
              value={
                selectedCategory.countryCodes
                  ? selectedCategory.countryCodes
                  : ""
              }
              onChange={(event, newValue) => {
                // let newSlugs = newValue.map(a => a.slug)
                setSelectedCategory({
                  ...selectedCategory,
                  countryCodes: newValue,
                })
              }}
              renderInput={params => (
                <TextField {...params} label="" variant="standard" />
              )}
            />
          </Row> */}
          <Row className="mb-3">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab == 1 ? "active" : ""}
                  onClick={() => {
                    setActiveTab(index + 1)
                  }}
                >
                  {selectedCategory.categorySlug}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={1}>
                {getCountryCodeRows(selectedCategory.categorySlug)}
              </TabPane>
            </TabContent>
          </Row>
          <Button color="success" type="submit">
            Update
          </Button>
        </form>
      </CardBody>
    </Card>
  )
}

export default connect(null, { setBreadcrumbItems })(EditSponsor)
