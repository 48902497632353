import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Button, Row } from "reactstrap"
import toast, { Toaster } from "react-hot-toast"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "store/actions"
import { getAllCategories } from "repository/CategoryRepository"
import {
  updateGlossary,
  getGlossaryBySlug,
} from "repository/GlossaryRepository"
import { useHistory, useLocation } from "react-router-dom"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"

function EditGlossary({ setBreadcrumbItems }) {
  const history = useHistory()
  const {
    state: { glossary },
  } = useLocation()
  const breadcrumbItems = [
    {
      item: "Glossary",
      path: "/glossary",
    },
    {
      item: "Edit",
      path: "/edit-glossary",
    },
  ]
  const [categories, setCategories] = useState([])
  const [products, setProducts] = useState([])
  const [glossaryData, setGlossaryData] = useState({
    name: JSON.parse(glossary).name,
    desc: JSON.parse(glossary).desc,
    slug: JSON.parse(glossary).slug,
    categorySlugs: [],
    productSlugs: [],
  })
  const categoryAutoCompleteProps = {
    options: categories,
    getOptionLabel: option => option || "",
  }
  const productAutoCompleteProps = {
    options: products,
    getOptionLabel: option => option || "",
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    let glossary_copy = JSON.parse(JSON.stringify(glossaryData))
    const answer = window.confirm(
      `Are you sure you want to update ${glossaryData.name}`
    )
    const id = toast.loading("Processing your Request...");
    if (answer) {
      updateGlossary(glossary_copy)
        .then(response => {
          if (response.success) {
            toast.success(response.message)
            history.goBack()
          } else {
            toast.error(response.message)
          }
        })
        .catch(error => {
          console.log(error)
          toast.error("Error in updating feature")
        })
    } else {
      toast.error("Feature Not Updated")
    }
    toast.dismiss(id);
  }
  useEffect(() => {
    setBreadcrumbItems("Edit Glossary", breadcrumbItems)
    getAllCategories()
      .then(response => {
        let result = response.map(a => a.slug)
        setCategories(result)
      })
      .catch(error => {
        console.log(error)
      })
    getGlossaryBySlug(glossaryData.slug)
      .then(response => {
        console.log(response)
        setGlossaryData({
          ...glossaryData,
          categorySlugs: response.categorySlugs,
          productSlugs: response.productSlugs,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  return (
    <Card>
      <CardBody>
        <Toaster />
        <CardTitle>Edit Glossary</CardTitle>
        <form onSubmit={handleOnSubmit}>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label required">Name</label>
            <div className="col-md-10">
              <input
                className="form-control"
                placeholder="Name"
                value={glossaryData.name}
                onChange={e => {
                  setGlossaryData({ ...glossaryData, name: e.target.value })
                }}
                required
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label className="col-md-2 col-form-label">Description</label>
            <div className="col-md-10">
              <textarea
                className="form-control"
                placeholder="Description"
                value={glossaryData.desc}
                onChange={e => {
                  setGlossaryData({ ...glossaryData, desc: e.target.value })
                }}
                required
              />
            </div>
          </Row>
          <Button color="success" type="submit">
            Update
          </Button>
        </form>
      </CardBody>
    </Card>
  )
}

export default connect(null, { setBreadcrumbItems })(EditGlossary)
