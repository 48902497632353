import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "store/actions"
import { connect } from "react-redux"
import { Card, CardBody, CardTitle } from "reactstrap"
import { useParams } from "react-router-dom"
import { getCategoryFeatures } from "repository/CategoryRepository"
import GeneralTable from "components/Common/GeneralTable"

function CategoryFeatures({ setBreadcrumbItems }) {
  const { slug } = useParams()
  const [categoryFeatures, setCategoryFeatures] = useState([])
  const [apiResponse, setApiResponse] = useState({})
  const breadcrumbItems = []
  const columns = [
    {
      name: "Name",
      data_key: "name",
    },
  ]
  useEffect(() => {
    setBreadcrumbItems("Category Features", breadcrumbItems)
    getCategoryFeatures(slug)
      .then(response => {
        setApiResponse(response)
        setCategoryFeatures(response.features)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="h4">{apiResponse.categoryName}</CardTitle>
          <GeneralTable columns={columns} data={categoryFeatures} />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(CategoryFeatures)
