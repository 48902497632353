import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "store/actions"
import {
  updateGlossary,
  getGlossaryBySlug,
  deleteGlossary,
} from "../../../repository/GlossaryRepository"
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  FormGroup,
} from "reactstrap"
import GeneralTable from "components/Common/GeneralTable"
import Slider from "react-slick"
import {
  getProductDetails,
  getProductOverview,
  getProductMediaInfo,
  getProducAlternatives,
  getProductReviews,
  updateProduct,
  addSponsorProduct,
  searchCustomer,
  addNewCustomer,
  searchIntegration,
  addNewIntegration,
  searchProductProduct,
  getProductPricingInfo,
  addNewPricingInfo,
  updatePricingInfo,
  searchAll,
  getProductId,
  deletePricingInfo,
} from "repository/ProductRepository"
import {
  getProductsByCategory,
  getAllCategories,
} from "repository/CategoryRepository"
import { getQuickLinks } from "repository/HomeRepository"
import { MAIN_WEB_URL } from "repository/Repository"
import toast, { Toaster } from "react-hot-toast"
import { upload } from "helpers/S3Functions"
import Autocomplete from "react-autocomplete"
import AutocompleteMui from "@mui/material/Autocomplete"
import Dropdown from "components/Common/Dropdown"
import DnDList from "components/Common/DnDList"
import VideoDnDList from "components/Common/VideoDndList"
import MaterialTable from "material-table"
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, MenuItem, TextField, ThemeProvider, Typography, createTheme } from "@mui/material"
import { ArrowDownward, ArrowDropDown, CopyAll, Delete } from "@mui/icons-material"
import _ from "lodash"

const ProductDetails = ({ setBreadcrumbItems, match }) => {
  const { slug } = match.params
  const defaultMaterialTheme = createTheme()
  const breadcrumbItems = []
  const [productDetails, setProductDetails] = useState({
    slug: slug,
    secondaryCategorySlugs: [],
  })
  const [productPricingInfo, setProductPricingInfo] = useState({})
  const [productOverview, setProductOverview] = useState({})
  const [productMediaInfo, setProductMediaInfo] = useState({})
  const [productAlternatives, setProductAlternatives] = useState([])
  const [quickLinks, setQuickLinks] = useState([])
  const [activeTab, setActiveTab] = useState(1)
  const [categoryFeatures, setCategoryFeatures] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [sponsorData, setSponsorData] = useState({
    productSlug: slug,
    categorySlugs: [],
    productUrls: [],
    countryCodes: [],
    continentCodes: [],
  })
  const [integrationModal, setIntegrationModal] = useState(false)
  const [addFeatureModal, setAddFeatureModal] = useState(false)
  const [customerModal, setCustomerModal] = useState(false)
  const [mediaModal, setMediaModal] = useState(false)
  const [priceMediaModal, setPriceMediaModal] = useState(false)
  const [pricingMonthlyModal, setPricingMonthlyModal] = useState(false)
  const [pricingYearlyModal, setPricingYearlyModal] = useState(false)
  const [plansToCopy, setPlansToCopy] = useState([])
  const [copyToYearlyActive, setCopyToYearlyActive] = useState(false)
  const [copyToMonthlyActive, setcopyToMonthlyActive] = useState(false)
  const [pricingUseNew, setPricingUseNew] = useState(false)
  const [inteSelected, setInteselected] = useState(1)
  const [mediaSelected, setMediaSelected] = useState(1)
  const [customerSelected, setCustomerSelected] = useState(1)
  const [customerUpdateLoading, setCustomerUpdateLoading] = useState(false)
  const [customerSearchValue, setCustomerSearchValue] = useState("")
  const [customerSearchResult, setCustomerSearchResult] = useState(null)
  const [integrationSearchValue, setIntegrationSearchValue] = useState("")
  const [integrationSearchResult, setIntegrationSearchResult] = useState(null)
  const [integrationSearchResultSelected, setIntegrationSearchResultSelected] = useState(null)
  const [categorySearchResult, setCategorySearchResult] = useState(null)

  const [migratedProductSearchResult, setMigratedProductSearchResult] =
    useState([])
  const migratedProductSearchAutoCompleteProps = {
    options: migratedProductSearchResult.map(item => item.slug),
    // getOptionLabel: option => option.slug || "",
  }
  const [addCustomer, setAddCustomer] = useState({
    name: "",
    logo: null,
  })
  const [addIntegration, setAddIntegration] = useState({
    name: "",
    logo: null,
    logoAltText: "",
    primaryCategoryName: "",
    url: ""
  })
  const [addFeature, setAddFeature] = useState({})
  const handleFeatureDeleteClick = element => {
    const answer = window.confirm(
      `Are you sure you want to delete ${element.slug}`
    )
    if (answer) {
      const data = {
        featureSlug: element.slug,
        featureName: element.name,
        productSlug: productDetails.slug,
      }
      deleteGlossary(data)
        .then(response => {
          if (response.success == true) {
            window.alert("Delete Successful")
            window.location.reload()
          } else {
            toast.error(response.message)
          }
        })
        .catch(error => {
          console.log(error)
          toast.error(error.message)
        })
    } else {
      toast.error("Product Not Deleted")
    }
  }

  const [uploads, setUploads] = useState([])
  const [videoUploads, setVideoUploads] = useState([])
  const [addVideo, setAddVideo] = useState({
    videoUrl: "",
  })
  const [addMediaLoading, setAddMediaLoading] = useState(false)
  const [addMonthlyPricing, setAddMonthlyPricing] = useState({
    title : "",
    pricingCurrency : "$",
    pricing : "",
    pricingType : "",
    customPricingType : "",
    billing : "",
    features : []
  })
  // const [addMonthlyPricingLoading, setAddMonthlyPricingLoading] = useState(false)
  
  const [addYearlyPricing, setAddYearlyPricing] = useState({
    title : "",
    pricingCurrency : "$",
    pricing : "",
    pricingType : "",
    customPricingType : "",
    billing : "",
    features : []
  })
  // const [addYearlyPricingLoading, setAddYearlyPricingLoading] = useState(false)
  
  const [reviewsTableColumns, setReviewsTableColumns] = useState({
    columns: [
      {
        label: "User",
        field: "userName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Source",
        field: "source",
        sort: "asc",
        width: 150,
      },
      {
        label: "Title",
        field: "heading",
        sort: "asc",
        width: 150,
      },
      {
        label: "Rating",
        field: "rating",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "reviewDate",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [],
  })
  const glossary_columns = [
    { title: "Letter", field: "letter" },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Slug",
      field: "slug",
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
    },
  ]
  const productAlternativesColumns = [
    {
      name: "Name",
      data_key: "name",
    },
    {
      name: "Subtitle",
      data_key: "subtitle",
    },
    {
      name: "Website URL",
      render: record => <input className="form-control" value={record.url} />,
    },
    {
      name: "Logo",
      render: record => <img src={record.logo} height={50} width={50} style={{
        objectFit : 'contain'
      }}/>,
    },
  ]
  const productIntegrationColumns = [
    {
      name: "Name",
      data_key: "name",
    },
    {
      name: "Rating",
      data_key: "rating",
    },
    {
      name: "Logo",
      render: record => <img src={record.logo} height={50} width={50} style={{
        objectFit : 'contain'
      }}/>,
    },
    {
      name: "Actions",
      render: record => (
        <Button
          color="danger"
          onClick={() => {
            let integrations = productDetails.integrations
            integrations = integrations.filter(
              ele => JSON.stringify(ele) !== JSON.stringify(record)
            )
            setProductDetails({
              ...productDetails,
              integrations,
            })
          }}
        >
          Delete
        </Button>
      ),
    },
  ]
  const productCustomersColumn = [
    {
      name: "Name",
      data_key: "name",
    },
    {
      name: "Logo",
      render: record => (
        <>
          {console.log(record)}
          <img
            src={
              typeof record.logo == "blog"
                ? URL.createObjectURL(record.logo)
                : record.logo
            }
            height={50}
            width={50}
            style={{
              objectFit : 'contain'
            }}
          />
        </>
      ),
    },
    {
      name: "Actions",
      render: record => (
        <Button
          color="danger"
          onClick={() => {
            let customerArray = productDetails.customers
            customerArray = customerArray.filter(
              ele => JSON.stringify(ele) !== JSON.stringify(record)
            )
            setProductDetails({
              ...productDetails,
              customers: customerArray,
            })
          }}
        >
          Delete
        </Button>
      ),
    },
  ]
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    sliderToShow: 1,
    slidesToScroll: 1,
  }

  const pricingTypes = [
    '/user',
    '/user/day',
    '/user/month',
    '/user/year',
    '/month',
    '/year',
    '/day',
    'custom',
    'free',
    'none',
    'other'
  ]
  
  const userPerDayPricingType = [
    '/user/day',
    'user/day',
    'day/user',
    '/day/user'
  ]
  const userPerMonthPricingType = [
    '/user/month',
    'user/month',
    'month/user',
    '/month/user'
  ]
  const userPerYearPricingType = [
    '/user/year',
    'user/year',
    'year/user',
    '/year/user'
  ]

  const billingTypes = [
    'monthly',
    'yearly',
    'daily',
  ]
  useEffect(() => {
    setBreadcrumbItems("Product Detail", breadcrumbItems)
    console.log("details", productDetails)
    getProductDetails(slug)
      .then(response => {
        setProductDetails(response)
      })
      .catch(error => {
        console.log(error)
      })
    getProductPricingInfo(slug)
      .then(response => {
        setProductPricingInfo(response)
        console.log('-------------------------------------------------------------------------------------------------------------------------', response);
      })
      .catch(error => {
        console.log('this is the error',error)
        setPricingUseNew(true)
      })
    getProductOverview(slug)
      .then(response => {
        setProductOverview(response)
      })
      .catch(error => {
        console.log(error)
      })
    getProductMediaInfo(slug)
      .then(response => {
        setProductMediaInfo(response)
      })
      .catch(error => {
        console.log(error)
      })
    getProducAlternatives(slug)
      .then(response => {
        setProductAlternatives(response.products)
      })
      .catch(error => {
        console.log(error)
      })
    getQuickLinks()
      .then(response => {
        setQuickLinks(response)
      })
      .catch(error => {
        console.log(error)
      })
    getProductReviews(slug)
      .then(response => {
        setReviewsTableColumns(prevState => ({
          prevState,
          rows: response.reviewsInfo.reviews,
        }))
      })
      .catch(error => {
        console.log(error)
      })
    getAllCategories()
      .then(response => {
        setCategoryList(response)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    searchCustomer(customerSearchValue)
      .then(res => {
        console.log(res)
        if (
          JSON.stringify(res.slice(0, 15)) ==
          JSON.stringify(customerSearchResult)
        )
          return
        setCustomerSearchResult(res.slice(0, 15))
      })
      .catch(error => {
        console.log(error)
      })
  }, [customerSearchValue])

  useEffect(() => {
    searchIntegration(integrationSearchValue)
      .then(res => {
        console.log(res)
        if (
          JSON.stringify(
            res.slice(0, 15)
          ) == JSON.stringify(integrationSearchResult)
        )
          return
        setIntegrationSearchResult(
          res.slice(0, 15)
        )
      })
      .catch(error => {
        console.log(error)
      })
  }, [integrationSearchValue])

  useEffect(() => {
    searchAll(addIntegration.primaryCategoryName)
      .then(res => {
        console.log(res.filter(ele => ele.type == "Category"))
        if (
          JSON.stringify(
            res.filter(ele => ele.type == "Category").slice(0, 15)
          ) == JSON.stringify(categorySearchResult)
        )
          return
        setCategorySearchResult(
          res.filter(ele => ele.type == "Category").slice(0, 15)
        )
      })
      .catch(error => {
        console.log(error)
      })
  }, [addIntegration.primaryCategoryName])

  const handleProductUpdateClick = data => {
    const answer = window.confirm(`Are you sure you want to update ${slug}`)
    const id = toast.loading("Processing your Request...")
    console.log(data)
    if (answer) {
      data.primaryCategorySlug = productDetails.categorySlug
      updateProduct(data)
        .then(response => {
          console.log(response)
          if (response.success == true) {
            toast.success(response.message)
          } else {
            toast.error(response.message)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error(err.message)
        })
    } else {
      toast.error("Product Not Updated")
    }
    toast.dismiss(id)
  }
  const handleUpdateCustomer = async () => {
    if (customerUpdateLoading) return
    setCustomerUpdateLoading(true)
    console.log(productDetails)
    let customerArray = productDetails.customers.map(ele => ele._id)
    console.log(customerArray)
    handleProductUpdateClick({
      customers: customerArray,
      slug,
    })
    setCustomerUpdateLoading(false)
  }
  const handleUpdateIntegration = async () => {
    console.log(productDetails)
    let integrationArray = productDetails.integrations
    const updatedIntegrations = []
    integrationArray.forEach(integration => {
      let docToAdd = {
        docId: integration._id,
        isAvailable: false,
      }
      if ("slug" in integration) {
        docToAdd.isAvailable = true
      }
      updatedIntegrations.push(docToAdd)
    })
    console.log(updatedIntegrations)
    handleProductUpdateClick({
      integrations: updatedIntegrations,
      slug,
    })
  }
  const handleUpload = async e => {
    const files = e.target.files
    // const zurl = await upload({ dirname: "d", file: e.target.files[0] })
    Array.from(files).forEach(file => {
      const reader = new FileReader()
      reader.onload = e => {
        const fileUrl = new File(
          [e.target.result],
          `image_urls_${Date.now()}`,
          {
            type: "image/jpg",
          }
        )
        setUploads(prev =>
          prev
            ? [
                ...prev,
                {
                  src: e.target.result,
                  url: fileUrl,
                  new: true,
                  image_file: file,
                  name: file.name,
                },
              ]
            : [
                {
                  src: e.target.result,
                  url: fileUrl,
                  new: true,
                  image_file: file,
                  name: file.name,
                },
              ]
        )
      }
      reader.readAsDataURL(file)
    })
  }

  const togglePriceMediaModal = open => {
    setPriceMediaModal(open)
    setUploads(
      open
        ? productMediaInfo?.priceImageInfo?.map(el => ({ ...el, new: false }))
        : []
    )
  }
  
  const togglePricingMonthlyModal = open => {
    setPricingMonthlyModal(open)
  }
  const togglePricingYearlyModal = open => {
    setPricingYearlyModal(open)
  }


  const handlePricingDelete = (pricingIndex, plan) => {
    if(plan === 'monthly'){
      setProductPricingInfo({
        ...productPricingInfo,
        monthly : productPricingInfo.monthly.filter((item, index)=> index !== pricingIndex)
      })
    }
    else if(plan === 'yearly'){
      setProductPricingInfo({
        ...productPricingInfo,
        yearly : productPricingInfo.yearly.filter((item, index)=> index !== pricingIndex)
      })
    }
  }
  
  const handlePricingClone = (pricingIndex, plan) => {
    if(plan === 'monthly'){
      let tempProductPricngInfo = productPricingInfo.monthly[pricingIndex]
      let tempItem = {...tempProductPricngInfo}
      tempItem.pricing = handleNumericInput(tempItem.pricing)
      tempItem.pricingCurrency = tempProductPricngInfo?.pricing?.match(/[^\d.]/g)?.join('')
      
      if(tempProductPricngInfo.pricingType.toLowerCase() === 'other' || !pricingTypes.includes(tempProductPricngInfo.pricingType.toLowerCase())){
        tempItem.pricingType = 'other'
        tempItem.customPricingType = tempProductPricngInfo.pricingType.toLowerCase()
      }

      setAddMonthlyPricing({
        title : tempItem.title,
        pricing : tempItem.pricing,
        pricingCurrency : tempItem.pricingCurrency, 
        pricingType : tempItem.pricingType.toLowerCase(),
        customPricingType : tempItem.customPricingType,
        billing : tempItem.billing,
        features : tempItem.features.join()
      })
      setPricingMonthlyModal(true)
    }
    else if(plan === 'yearly'){
      let tempProductPricngInfo = productPricingInfo.yearly[pricingIndex]
      let tempItem = {...tempProductPricngInfo}
      tempItem.pricing = handleNumericInput(tempItem.pricing)
      tempItem.pricingCurrency = tempProductPricngInfo?.pricing?.match(/[^\d.]/g)?.join('')
      
      if(tempProductPricngInfo.pricingType.toLowerCase() === 'other' || !pricingTypes.includes(tempProductPricngInfo.pricingType.toLowerCase())){
        tempItem.pricingType = 'other'
        tempItem.customPricingType = tempProductPricngInfo.pricingType.toLowerCase()
      }

      setAddYearlyPricing({
        title : tempItem.title,
        pricing : tempItem.pricing,
        pricingCurrency : tempItem.pricingCurrency, 
        pricingType : tempItem.pricingType.toLowerCase(),
        customPricingType : tempItem.customPricingType,
        billing : tempItem.billing,
        features : tempItem.features.join()
      })
      setPricingYearlyModal(true)
    }
  }
  
  const handleCopyPlanToAnother = (planType) => {
    if(planType === 'monthly'){
      if(productPricingInfo?.yearly){
        setProductPricingInfo({
          ...productPricingInfo,
          yearly : [...productPricingInfo.yearly, ...plansToCopy]
        })
      }
      else{
        setProductPricingInfo({
          ...productPricingInfo,
          yearly : [...plansToCopy]
        })
      }
      setPlansToCopy([])
      setCopyToYearlyActive(false)
    }
    else if(planType === 'yearly'){
      if(productPricingInfo?.monthly){
        setProductPricingInfo({
          ...productPricingInfo,
          monthly : [...productPricingInfo.monthly, ...plansToCopy]
        })
      }
      else{
        setProductPricingInfo({
          ...productPricingInfo,
          monthly : [ ...plansToCopy]
        })
      }
      setPlansToCopy([])
      setcopyToMonthlyActive(false)
    }
  }
  const handlePricingPlanAddCopy = (plan, planType) => {
    if(planType === 'monthly'){
      if(plansToCopy.filter(item => item == plan).length > 0){
        setPlansToCopy(plansToCopy.filter(item => item != plan))
      }
      else{
        setPlansToCopy(prev => [...prev, plan])
      }
    }
    else if(planType === 'yearly'){
      if(plansToCopy.filter(item => item == plan).length > 0){
        setPlansToCopy(plansToCopy.filter(item => item != plan))
      }
      else{
        setPlansToCopy(prev => [...prev, plan])
      }
    }
  }
  const handleAddPricing = (plan) => {
    
    if(plan === 'monthly'){
      if(addMonthlyPricing.title){
        let tempMonthlyPricing = addMonthlyPricing
        tempMonthlyPricing = {
          ...tempMonthlyPricing,
          features : addMonthlyPricing?.features?.split(',')
        }
        
        if(_.isEmpty(productPricingInfo)){
          setProductPricingInfo({
            productName : productDetails?.name,
            slug : productDetails?.slug,
            monthly : [tempMonthlyPricing]
          })  

          setAddMonthlyPricing({
            title : "",
            pricing : "",
            pricingType : "",
            billing : "",
            features : []
          })
          togglePricingMonthlyModal(false)
        }

        else{
          let tempProductPricngInfoExpanded = productPricingInfo?.monthly?.map((item, index)=>{

            let tempProductPricngInfo = item
            let tempItem = {...tempProductPricngInfo}
            tempItem.pricing = handleNumericInput(tempItem.pricing)
            tempItem.pricingCurrency = tempProductPricngInfo?.pricing?.match(/[^\d.]/g)?.join('')
            
            if(tempProductPricngInfo.pricingType.toLowerCase() === 'other' || !pricingTypes.includes(tempProductPricngInfo.pricingType.toLowerCase())){
              tempItem.pricingType = 'other'
              tempItem.customPricingType = tempProductPricngInfo.pricingType.toLowerCase()
            }

            return {
              title : tempItem.title,
              pricing : tempItem.pricing,
              pricingCurrency : tempItem.pricingCurrency, 
              pricingType : tempItem.pricingType.toLowerCase(),
              customPricingType : tempItem.customPricingType,
              billing : tempItem.billing,
              features : tempItem.features
            }
          })

          const id = toast.loading("Processing your Request...");
          if(_.some(tempProductPricngInfoExpanded, tempMonthlyPricing)){
            toast.error('Plan already exists')
            toast.dismiss(id);
          }
          else{
            setProductPricingInfo({
              ...productPricingInfo,
              productName : productDetails?.name,
              monthly : productPricingInfo?.monthly ? [...productPricingInfo?.monthly , tempMonthlyPricing] : [tempMonthlyPricing]
            })
            setAddMonthlyPricing({
              title : "",
              pricing : "",
              pricingType : "",
              billing : "",
              features : []
            })
            togglePricingMonthlyModal(false)
            toast.dismiss(id);
          }
        }
      }
    }
    else if(plan === 'yearly'){
      
      if(addYearlyPricing.title){
        let tempYearlyPricing = addYearlyPricing
        tempYearlyPricing = {
          ...tempYearlyPricing,
          features : addYearlyPricing?.features?.split(',')
        }
        if(_.isEmpty(productPricingInfo)){
          setProductPricingInfo({
            productName : productDetails?.name,
            slug : productDetails?.slug,
            yearly : [tempYearlyPricing]
          })  
          
          setAddYearlyPricing({
            title : "",
            pricing : "",
            pricingType : "",
            billing : "",
            features : []
          })
          togglePricingYearlyModal(false)
        }
        else{
          let tempProductPricngInfoExpanded = productPricingInfo?.yearly?.map((item, index)=>{

            let tempProductPricngInfo = item
            let tempItem = {...tempProductPricngInfo}
            tempItem.pricing = handleNumericInput(tempItem.pricing)
            tempItem.pricingCurrency = tempProductPricngInfo?.pricing?.match(/[^\d.]/g)?.join('')
            
            if(tempProductPricngInfo.pricingType.toLowerCase() === 'other' || !pricingTypes.includes(tempProductPricngInfo.pricingType.toLowerCase())){
              tempItem.pricingType = 'other'
              tempItem.customPricingType = tempProductPricngInfo.pricingType.toLowerCase()
            }

            return {
              title : tempItem.title,
              pricing : tempItem.pricing,
              pricingCurrency : tempItem.pricingCurrency, 
              pricingType : tempItem.pricingType.toLowerCase(),
              customPricingType : tempItem.customPricingType,
              billing : tempItem.billing,
              features : tempItem.features
            }
          })

          const id = toast.loading("Processing your Request...");
          if(_.some(tempProductPricngInfoExpanded, tempYearlyPricing)){
            toast.error('Plan already exists')
            toast.dismiss(id);
          }
          else{
            setProductPricingInfo({
              ...productPricingInfo,
              productName : productDetails?.name,
              yearly : productPricingInfo?.yearly ? [...productPricingInfo?.yearly , tempYearlyPricing] : [tempYearlyPricing]
            })
            setAddYearlyPricing({
              title : "",
              pricing : "",
              pricingType : "",
              billing : "",
              features : []
            })
            togglePricingYearlyModal(false)
            toast.dismiss(id);
          }
        }
      }
    }
  }

  const handlePricingPlanSave = async () => {
    const id = toast.loading("Processing your Request...");
    try{
      let tempProductPricingInfo = {
        ...productPricingInfo,
        productName : productDetails?.name,
        slug : productDetails?.slug,
      }
      delete tempProductPricingInfo._id
      delete tempProductPricingInfo.name
      
      
      let tempMonthly = tempProductPricingInfo?.monthly?.map((item)=>{
        let tempItem = item
        if(!(item.pricing === '' || item.pricing === undefined || item.pricing === null)){
          if(item.pricingCurrency === '' || item.pricingCurrency === undefined || item.pricingCurrency === null){
            tempItem.pricingCurrency = '$'
          }
          tempItem.pricing = tempItem.pricingCurrency + handleNumericInput(tempItem.pricing)
        }
        else{
          tempItem.pricing = ''
        }
        if(item.pricingType.toLowerCase() === 'other'){
          tempItem.pricingType = item.customPricingType
        }

        delete tempItem.pricingCurrency
        delete tempItem.customPricingType
        
        return tempItem

      })

      let tempYearly = tempProductPricingInfo?.yearly?.map((item)=>{
        
        let tempItem = item
        if(!(item.pricing === '' || item.pricing === undefined || item.pricing === null)){
          if(item.pricingCurrency === '' || item.pricingCurrency === undefined || item.pricingCurrency === null){
            tempItem.pricingCurrency = '$'
          }
          tempItem.pricing = tempItem.pricingCurrency + handleNumericInput(tempItem.pricing)
        }
        else{
          tempItem.pricing = ''
        }
        if(item.pricingType.toLowerCase() === 'other'){
          tempItem.pricingType = item.customPricingType
        }

        delete tempItem.pricingCurrency
        delete tempItem.customPricingType
        
        return tempItem

      })
      
      tempProductPricingInfo.monthly = tempMonthly
      tempProductPricingInfo.yearly = tempYearly
      if(pricingUseNew){
        const res = await addNewPricingInfo(tempProductPricingInfo.slug, tempProductPricingInfo)
        console.log(res);
        toast.success(res.message)
      }
      else{
        const res = await updatePricingInfo(tempProductPricingInfo.slug, tempProductPricingInfo)
        console.log(res);
        toast.success(res.message)
      }
    }
    catch(e){
      toast.error(e.response.data.message);
    }
    finally{
      console.log(id);
      toast.dismiss(id);
    }
  }

  const handleEditMonthly = (index, field, value, numeric = false) => {
    const updatedMonthly = productPricingInfo.monthly.map((item, idx) =>
      idx === index ? { ...item, [field]: numeric ? handleNumericInput(value) : value } : item
    );

    setProductPricingInfo(prevState => ({
      ...prevState,
      monthly: updatedMonthly
    }));
  };
  
  const handleEditYearly = (index, field, value, numeric = false) => {
    const updatedYearly = productPricingInfo.yearly.map((item, idx) =>
      idx === index ? { ...item, [field]: numeric ? handleNumericInput(value) : value } : item
    );

    setProductPricingInfo(prevState => ({
      ...prevState,
      yearly: updatedYearly
    }));
  };


  const editHandleFeatureChange = (index, featureIndex, value, plan) => {
    if(plan === 'monthly'){
      const updatedMonthly = productPricingInfo.monthly.map((item, idx) => {
        if (idx === index) {
          const updatedFeatures = item.features.map((feature, fIdx) =>
            fIdx === featureIndex ? value : feature
          );
          return { ...item, features: updatedFeatures };
        }
        return item;
      });
  
      setProductPricingInfo(prevState => ({
        ...prevState,
        monthly: updatedMonthly
      }));
    }
    
    else if(plan === 'yearly'){
      const updatedYearly = productPricingInfo.yearly.map((item, idx) => {
        if (idx === index) {
          const updatedFeatures = item.features.map((feature, fIdx) =>
            fIdx === featureIndex ? value : feature
          );
          return { ...item, features: updatedFeatures };
        }
        return item;
      });
  
      setProductPricingInfo(prevState => ({
        ...prevState,
        yearly: updatedYearly
      }));
    }
  };

  const editAddPricingInfoFeature = (index, value, plan) => {
    if(plan === 'monthly'){
      const updatedMonthly = productPricingInfo.monthly.map((item, idx) => {
        if (idx === index) {
          return { ...item, features: [...item.features, value] };
        }
        return item;
      });
  
      setProductPricingInfo(prevState => ({
        ...prevState,
        monthly: updatedMonthly
      }));
    }
    if(plan === 'yearly'){
      const updatedYearly = productPricingInfo.yearly.map((item, idx) => {
        if (idx === index) {
          return { ...item, features: [...item.features, value] };
        }
        return item;
      });
  
      setProductPricingInfo(prevState => ({
        ...prevState,
        yearly: updatedYearly
      }));
    }
  };

  const editRemovePricingInfoFeature = (index, featureIndex, plan) => {
    if(plan === 'monthly'){
      const updatedMonthly = productPricingInfo.monthly.map((item, idx) => {
        if (idx === index) {
          const updatedFeatures = item.features.filter((_, fIdx) => fIdx !== featureIndex);
          return { ...item, features: updatedFeatures };
        }
        return item;
      });
  
      setProductPricingInfo(prevState => ({
        ...prevState,
        monthly: updatedMonthly
      }));
    }
    else if(plan === 'yearly'){
      const updatedYearly = productPricingInfo.yearly.map((item, idx) => {
        if (idx === index) {
          const updatedFeatures = item.features.filter((_, fIdx) => fIdx !== featureIndex);
          return { ...item, features: updatedFeatures };
        }
        return item;
      });
  
      setProductPricingInfo(prevState => ({
        ...prevState,
        yearly: updatedYearly
      }));
    }
  };

  const handleNumericInput = (value) => {
    // Remove any characters that are not digits or a decimal point
    const sanitizedValue = value.replace(/[^0-9.]/g, '');

    // Check if there is more than one decimal point
    const parts = sanitizedValue.split('.');
    if (parts.length > 2) {
      return sanitizedValue.slice(0, sanitizedValue.lastIndexOf('.'));
    }

    // Ensure that the decimal point is not the first character
    if (sanitizedValue.startsWith('.')) {
      return '0' + sanitizedValue;
    }

    return sanitizedValue;
  };

  const toggleAddFeatureModal = open => {
    setAddFeatureModal(open)
  }

  const toggleMediaModal = open => {
    setMediaModal(open)
    setVideoUploads(
      open
        ? productMediaInfo?.videoInfo?.map(el => ({ ...el, new: false }))
        : []
    )
    setUploads(
      open
        ? productMediaInfo?.imageInfo?.map(el => ({ ...el, new: false }))
        : []
    )
  }

  const checkFreeTrial = pricing => {
    if (typeof pricing === "object") {
      if (pricing.includes("Free Trial")) {
        return true
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="h4">
            <Toaster />
            <Row>
              <Col width={12}>
                <img src={productDetails.logo} height={50} width={50} style={{
                              objectFit : 'contain',
                              marginRight : '16px'
                            }}/>
                {productDetails.name}
              </Col>
            </Row>
          </CardTitle>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab == 1 ? "active" : ""}
                onClick={() => setActiveTab(1)}
              >
                Overview
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab == 2 ? "active" : ""}
                onClick={() => setActiveTab(2)}
              >
                Pricing
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={activeTab == 4 ? "active" : ""}
                onClick={() => setActiveTab(4)}
              >
                Alternatives
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab == 6 ? "active" : ""}
                onClick={() => setActiveTab(6)}
              >
                Media
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab == 7 ? "active" : ""}
                onClick={() => setActiveTab(7)}
              >
                Integrations
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab == 8 ? "active" : ""}
                onClick={() => setActiveTab(8)}
              >
                Customers
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab == 9 ? "active" : ""}
                onClick={() => setActiveTab(9)}
              >
                Support
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab == 3 ? "active" : ""}
                onClick={() => setActiveTab(3)}
              >
                Features
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Product Name</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder={productDetails.name}
                    name="name"
                    value={productDetails.name}
                    disabled="disabled"
                  />
                </div>
              </Row>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Product Slug</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder={productDetails.slug}
                    name="name"
                    value={productDetails.slug}
                    disabled="disabled"
                  />
                </div>
              </Row>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">
                  Primary Category Slug
                </label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder={productDetails.categorySlug}
                    name="name"
                    value={productDetails.categorySlug}
                    disabled="disabled"
                  />
                </div>
              </Row>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Spot Score</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder={productDetails.score}
                    name="name"
                    value={productDetails.score}
                    disabled="disabled"
                  />
                </div>
              </Row>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Rating</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder={productDetails.rating}
                    name="name"
                    value={productDetails.rating}
                    disabled="disabled"
                  />
                </div>
              </Row>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Logo</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder="image"
                    type="file"
                    onChange={async e => {
                      const id = toast.loading("Processing your Request...")
                      try {
                        const reader = new FileReader()
                        reader.onload = e => {
                          const fileUrl = new File(
                            [e.target.result],
                            `image_urls_${Date.now()}`,
                            {
                              type: "image/jpg",
                            }
                          )
                          reader.readAsDataURL(file)
                        }
                        const logoURL = await upload({
                          dirname: "product*" + productDetails.name,
                          file: e.target.files[0],
                        })
                        setProductDetails({
                          ...productDetails,
                          logo: logoURL,
                        })
                      } finally {
                        toast.dismiss(id)
                      }
                    }}
                  />
                  <p>Max image size: 1 MB</p>
                </div>
              </Row>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Logo Alt Text</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder={productDetails.logoAltText}
                    value={productDetails.logoAltText}
                    onChange={e => {
                      if (e.target.value) {
                        setProductDetails({
                          ...productDetails,
                          logoAltText: e.target.value,
                        })
                      }
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Description</label>
                <div className="col-md-10">
                  <textarea
                    className="form-control"
                    placeholder={productDetails.desc}
                    value={productDetails.desc}
                    onChange={e => {
                      setProductDetails({
                        ...productDetails,
                        desc: e.target.value,
                      })
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Subtitle</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder={productDetails.subtitle}
                    value={productDetails.subtitle}
                    onChange={e => {
                      setProductDetails({
                        ...productDetails,
                        subtitle: e.target.value,
                      })
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Product URL</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder={productDetails.url}
                    value={productDetails.url}
                    onChange={e => {
                      if (e.target.value) {
                        setProductDetails({
                          ...productDetails,
                          url: e.target.value,
                        })
                      }
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-2 col-form-label">Platforms</label>
                <div className="col-md-10 d-flex gap-2">
                  <Dropdown
                    direction="down"
                    header="Select"
                    options={[
                      "SaaS/Web/Cloud",
                      "Mobile - Android",
                      "Mobile - iOS",
                      "Installed - Windows",
                      "Installed - Mac",
                    ]}
                    startValues={productDetails.deploymentTypes}
                    onChange={values => {
                      setProductDetails({
                        ...productDetails,
                        deploymentTypes: values,
                      })
                    }}
                    multipleChoice
                  />
                  <span>{productDetails.deploymentTypes?.join(" | ")}</span>
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-2 col-form-label">
                  Customer Types
                </label>
                <div className="col-md-10 d-flex gap-2">
                  <Dropdown
                    direction="down"
                    header="Select"
                    options={[
                      "Individuals",
                      "Freelancers",
                      "Large Enterprises",
                      "Medium Business",
                      "Small Business",
                    ]}
                    startValues={productDetails.customerTypes}
                    onChange={values => {
                      setProductDetails({
                        ...productDetails,
                        customerTypes: values,
                      })
                    }}
                    multipleChoice
                  />
                  <span>{productDetails.customerTypes?.join(" | ")}</span>
                </div>
              </Row>
              <Row>
                <Col span={6}>
                  <label>Free Trial Available</label>
                  <input
                    type="checkbox"
                    name="freeeTrial"
                    style={{ marginLeft: "10px" }}
                    checked={checkFreeTrial(productDetails.pricing)}
                    onChange={e => {
                      let set = new Set(productDetails.pricing)
                      if (e.target.checked) {
                        set.add("Free Trial")
                      } else {
                        set.delete("Free Trial")
                      }
                      setProductDetails({
                        ...productDetails,
                        pricing: Array.from(set),
                      })
                    }}
                  />
                </Col>
                <Col span={6}>
                  <label>Api Available</label>
                  <input
                    type="checkbox"
                    name="API"
                    style={{ marginLeft: "10px" }}
                    checked={productDetails.api == "Yes"}
                    onChange={e => {
                      let api = "Yes"
                      if (e.target.checked) {
                        api = "Yes"
                      } else {
                        api = "NA"
                      }
                      setProductDetails({
                        ...productDetails,
                        api: api,
                      })
                    }}
                  />
                </Col>
                <Col span={6}>
                  <label>Is Quick Link</label>
                  <input
                    type="checkbox"
                    name="isQuickLink"
                    style={{ marginLeft: "10px" }}
                    checked={productDetails.isQuickLink}
                    onChange={e => {
                      if (e.target.checked && quickLinks.length >= 5) {
                        toast.error("Can't add any more quick links")
                      } else {
                        setProductDetails({
                          ...productDetails,
                          isQuickLink: e.target.checked,
                        })
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <label>Is Discontineud</label>
                  <input
                    type="checkbox"
                    name="freeeTrial"
                    style={{ marginLeft: "10px" }}
                    checked={productDetails.isDiscontinued}
                    onChange={e => {
                      let isDiscontinued = false
                      let isMigrated = productDetails.isMigrated
                      if (e.target.checked) {
                        isDiscontinued = true
                        isMigrated = false
                      } else {
                        isDiscontinued = false
                      }
                      setProductDetails({
                        ...productDetails,
                        isDiscontinued: isDiscontinued,
                        isMigrated: isMigrated,
                      })
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <label>Is Migrated</label>
                  <input
                    type="checkbox"
                    name="freeeTrial"
                    style={{ marginLeft: "10px" }}
                    checked={productDetails.isMigrated}
                    onChange={e => {
                      let isMigrated = false
                      let isDiscontinued = productDetails.isDiscontinued
                      if (e.target.checked) {
                        isMigrated = true
                        isDiscontinued = false
                      } else {
                        isMigrated = false
                      }
                      setProductDetails({
                        ...productDetails,
                        isMigrated: isMigrated,
                        isDiscontinued: isDiscontinued,
                        // migratedToProductName: isMigrated
                        //   ? productDetails.migratedToProductName
                        //   : "",
                        migratedToProductSlug: isMigrated
                          ? productDetails.migratedToProductSlug
                          : "",
                      })
                    }}
                  />
                </Col>
              </Row>
              {productDetails.isMigrated && (
                <>
                  <Row>
                    {/* <label className="col-md-2 col-form-label">migratedToProductName</label> */}

                    {/* <input
                        className="form-control"
                        placeholder={productDetails.migratedToProductName}
                        value={productDetails.migratedToProductName}
                        onChange={async (e) => {
                          const res = await searchProductProduct(e.target.value)
                          setProductDetails({
                            ...productDetails,
                            migratedToProductName: e.target.value,
                          })
                        }}
                      /> */}
                    <label className="col-md-2 col-form-label">
                      migratedToProductSlug
                    </label>
                    <div className="col-md-3">
                      <AutocompleteMui
                        {...migratedProductSearchAutoCompleteProps}
                        id="prod-controlled-demo"
                        noOptionsText={"Search Product"}
                        onInputChange={(event, value, reason) => {
                          // console.log(productDetails);
                          if (value) {
                            fetch(
                              `${process.env.REACT_APP_API_BASE_URL}/product?_search=` +
                                value,
                              {
                                method: "GET",
                                headers: {
                                  apiKey: process.env.REACT_APP_API_TOKEN,
                                },
                              }
                            )
                              .then(function (response) {
                                return response.json()
                              })
                              .then(function (myJson) {
                                setMigratedProductSearchResult(myJson.products)
                              })
                          } else {
                            setMigratedProductSearchResult([])
                          }
                        }}
                        // value={
                        //   productDetails.migratedToProductSlug
                        //     ? productDetails.migratedToProductSlug
                        //     : ""
                        // }
                        onChange={(event, newValue) => {
                          // let newSlugs = objArray.map(a => a.slug)
                          console.log('gere',newValue)
                          setProductDetails({
                            ...productDetails,
                            // migratedToProductName: newValue.name,
                            migratedToProductSlug: newValue,
                          })
                        }}
                        
                        defaultValue={{ label: productDetails.migratedToProductName }}
                        renderInput={params => (
                          <TextField {...params} label="" variant="outlined" size="small" />
                        )}
                      />
                    </div>
                  </Row>
                  {/* <Row>
                    
                    <label className="col-md-2 col-form-label">migratedToProductSlug</label>
                    <div className="col-md-10">
                      <input
                        className="form-control"
                        placeholder={productDetails.migratedToProductSlug}
                        value={productDetails.migratedToProductSlug}
                        onChange={e => {
                          setProductDetails({
                            ...productDetails,
                            migratedToProductSlug: e.target.value,
                          })
                        }}
                      />
                    </div>
                </Row> */}
                </>
              )}
              <Button
                color="primary"
                onClick={e => {
                  handleProductUpdateClick({
                    desc: productDetails.desc,
                    logo: productDetails.logo,
                    logoAltText: productDetails.logoAltText,
                    deploymentTypes: productDetails.deploymentTypes,
                    customerTypes: productDetails.customerTypes,
                    pricing: productDetails.pricing,
                    api: productDetails.api,
                    isQuickLink: productDetails.isQuickLink,
                    subtitle: productDetails.subtitle,
                    url: productDetails.url,
                    isDiscontinued: productDetails.isDiscontinued,
                    isMigrated: productDetails.isMigrated,
                    // migratedToProductName : productDetails.migratedToProductName,
                    migratedToProductSlug: productDetails.migratedToProductSlug,
                    slug,
                  })
                }}
              >
                Update
              </Button>
            </TabPane>
            <TabPane tabId={2}>
              <h4 style={{ marginTop: "20px" }}>
                Pricing details for {productDetails.name}
              </h4>
              <Row className="mb-3">
                <label className="col-md-2 col-form-label">
                  Pricing Models
                </label>
                <div className="col-md-10 d-flex gap-2">
                  <Dropdown
                    direction="down"
                    header="Select"
                    options={[
                      "Free Trial",
                      "Freemium",
                      "Subscription",
                      "Quotation Based",
                      "One-time license",
                      "Open-Source",
                    ]}
                    startValues={productDetails.pricing}
                    onChange={values => {
                      setProductDetails({
                        ...productDetails,
                        pricing: values,
                      })
                    }}
                    multipleChoice
                  />
                  <span>{productDetails.pricing?.join(" | ")}</span>
                </div>
              </Row>
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Pricing Url</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder={productDetails.productPricingUrl}
                    name="name"
                    value={productDetails.productPricingUrl}
                    onChange={e => {
                      setProductDetails({
                        ...productDetails,
                        productPricingUrl: e.target.value,
                      })
                    }}
                  />
                </div>
              </Row>
              
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Monthly Pricing Info</label>
                <div className="col-md-6">
                  {productPricingInfo?.monthly?.length > 0 && (
                    <div className="mb-3">
                      {
                        productPricingInfo?.monthly?.map((item, index)=>{

                          return( 
                            <div className="d-flex gap-2">
                            {copyToYearlyActive && <input type="checkbox" size='large' checked={plansToCopy.includes(item)} onChange={()=>handlePricingPlanAddCopy(item, 'monthly')}/>}
                            <Accordion>
                            <AccordionSummary
                              expandIcon={<ArrowDropDown />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{
                                borderBottom : '1px solid #ccc'
                              }}
                            >
                              <Typography variant="button">{item.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            {
                                      
                              <Grid container rowSpacing={2}>
                                <Grid item xs={4}>
                                  <Typography variant="body2">Title : </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Input value={item.title} onChange={e=>handleEditMonthly(index, 'title', e.target.value)}/>
                                </Grid>
                                
                                <Grid item xs={4}>
                                  <Typography variant="body2">Pricing Type : </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <TextField select size="small" fullWidth defaultValue={pricingTypes[0]} value={
                                    pricingTypes.includes(item.pricingType.toLowerCase()) ? item.pricingType.toLowerCase() : 
                                    userPerDayPricingType.includes(item.pricingType.toLowerCase()) ? '/user/day' :
                                    userPerMonthPricingType.includes(item.pricingType.toLowerCase()) ? '/user/month' :
                                    userPerYearPricingType.includes(item.pricingType.toLowerCase()) ? '/user/year' :
                                    'other'} onChange={e=>handleEditMonthly(index, 'pricingType', e.target.value)}>
                                    {
                                      pricingTypes.map((item)=>{
                                        return <MenuItem key={item} value={item} >{item}</MenuItem >
                                      })
                                    }
                                  </TextField>
                                  { (item.pricingType.toLowerCase() === 'other' || !pricingTypes.includes(item.pricingType.toLowerCase())) && <Input value={!pricingTypes.includes(item.pricingType.toLowerCase()) ? item.pricingType.toLowerCase() : item.customPricingType} onChange={e=>handleEditMonthly(index, 'customPricingType', e.target.value)} style={{marginTop : '4px'}}/>}
                                </Grid>

                                {
                                  !(item.pricingType.toLowerCase() === 'custom' || item.pricingType.toLowerCase() === 'free' ) &&
                                  <>
                                  <Grid item xs={4}>
                                    <Typography variant="body2">Pricing : </Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Input placeholder="$" value={item.pricingCurrency ? item.pricingCurrency : item?.pricing?.match(/[^\d.]/g)?.join('') || ''} onChange={e=>handleEditMonthly(index, 'pricingCurrency', e.target.value)}/>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Input value={handleNumericInput(item.pricing)} onChange={e=>handleEditMonthly(index, 'pricing', e.target.value, true)}/>
                                  </Grid>
                                  </>
                                }
                                
                                <Grid item xs={4}>
                                  <Typography variant="body2">Billing : </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <TextField select size="small" fullWidth value={item.billing} onChange={e=>handleEditMonthly(index, 'billing', e.target.value)}>
                                    {
                                      billingTypes.map((item)=>{
                                        return <MenuItem key={item} value={item} >{item}</MenuItem >
                                      })
                                    }
                                  </TextField>
                                </Grid>

                                <Grid item xs={4}>
                                  <Typography variant="body2">Features : </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Box display={"flex"} flexDirection={"column"} rowGap={1.5} marginBottom={1}>
                                  {item.features.map((feature, featureIndex)=>{
                                    return (
                                    <Grid container rowSpacing={2} columnSpacing={1} key={featureIndex}>
                                      <Grid item xs={10}>

                                        <Input
                                          value={feature}
                                          onChange={(e) => editHandleFeatureChange(index, featureIndex, e.target.value, 'monthly')}
                                        />
                                      </Grid>
                                      <Grid item xs={2}>

                                        <Button color='danger' size="sm" onClick={() => editRemovePricingInfoFeature(index, featureIndex, 'monthly')}><Delete/></Button>
                                      </Grid>
                                    </Grid>
                                    )
                                  })}
                                  </Box>
                                  <Button color="primary" onClick={() => editAddPricingInfoFeature(index, '', 'monthly')}>Add Feature</Button>
                                </Grid>
                                <Grid item xs={2}>
                                  <Button color='danger' onClick={()=>handlePricingDelete(index, 'monthly')}><Delete/></Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button color='primary' onClick={()=>handlePricingClone(index, 'monthly')}><CopyAll/></Button>
                                </Grid>
                              </Grid>
                              
                            }
                            </AccordionDetails>
                          </Accordion>
                            </div>
                          
                          
                        )
                        })
                      }
                    </div>
                  )}
                  <div className="d-flex gap-2">
                    {!copyToYearlyActive && <Button
                      color="primary"
                      onClick={() => togglePricingMonthlyModal(true)}
                    >
                      Add Monthly Pricing
                    </Button>}
                    {productPricingInfo?.monthly?.length > 0 &&
                    (copyToYearlyActive ? 
                      <>
                        <Button
                          color="success"
                          onClick={() => handleCopyPlanToAnother('monthly')}
                        >
                          Copy {plansToCopy.length} to yearly
                        </Button>
                        <Button
                          color="danger"
                          onClick={() => setCopyToYearlyActive(false)}
                        >
                          Cancel
                        </Button>
                      </>
                      :
                      <Button
                        color="success"
                        onClick={() => setCopyToYearlyActive(true)}
                      >
                        Copy to yearly
                      </Button>)
                      
                    }
                  </div>
                </div>
              </Row>
              
              <Row className="mb-3" style={{ marginTop: "10px" }}>
                <label className="col-md-2 col-form-label">Yearly Pricing Info</label>
                <div className="col-md-6">
                  {productPricingInfo?.yearly?.length > 0 && (
                    <div className="mb-3">
                      {
                        productPricingInfo?.yearly?.map((item, index)=>{
                                
                          return( 
                            <div className="d-flex gap-2">
                            {copyToMonthlyActive && <input type="checkbox" size='large' checked={plansToCopy.includes(item)} onChange={()=>handlePricingPlanAddCopy(item, 'yearly')}/>}
                            <Accordion>
                            <AccordionSummary
                              expandIcon={<ArrowDropDown />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{
                                borderBottom : '1px solid #1e1e1e'
                              }}
                            >
                              <Typography variant="button">{item.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            {
                                      
                              <Grid container rowSpacing={2}>
                                <Grid item xs={4}>
                                  <Typography variant="body2">Title : </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Input value={item.title} onChange={e=>handleEditYearly(index, 'title', e.target.value)}/>
                                </Grid>
                                
                                <Grid item xs={4}>
                                  <Typography variant="body2">Pricing Type : </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <TextField select size="small" fullWidth defaultValue={pricingTypes[0]} 
                                    value={
                                      
                                      pricingTypes.includes(item.pricingType.toLowerCase()) ? item.pricingType.toLowerCase() : 
                                      userPerDayPricingType.includes(item.pricingType.toLowerCase()) ? '/user/day' :
                                      userPerMonthPricingType.includes(item.pricingType.toLowerCase()) ? '/user/month' :
                                      userPerYearPricingType.includes(item.pricingType.toLowerCase()) ? '/user/year' :
                                      'other'
                                    }
                                    onChange={e=>handleEditYearly(index, 'pricingType', e.target.value)}>
                                    {
                                      pricingTypes.map((item)=>{
                                        return <MenuItem key={item} value={item} >{item}</MenuItem >
                                      })
                                    }
                                  </TextField>
                                  { (item.pricingType.toLowerCase() === 'other' || !pricingTypes.includes(item.pricingType.toLowerCase())) && <Input value={!pricingTypes.includes(item.pricingType.toLowerCase()) ? item.pricingType.toLowerCase() : item.customPricingType} onChange={e=>handleEditYearly(index, 'customPricingType', e.target.value)} style={{marginTop : '4px'}}/>}
                                </Grid>
                                
                                {
                                  !(item.pricingType.toLowerCase() === 'custom' || item.pricingType.toLowerCase() === 'free' ) &&
                                  <>
                                    <Grid item xs={4}>
                                      <Typography variant="body2">Pricing : </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Input placeholder="$" value={item.pricingCurrency ? item.pricingCurrency : item?.pricing?.match(/[^\d.]/g)?.join('') || ''} onChange={e=>handleEditYearly(index, 'pricingCurrency', e.target.value)}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Input value={handleNumericInput(item.pricing)} onChange={e=>handleEditYearly(index, 'pricing', e.target.value, true)}/>
                                    </Grid>
                                  </>
                                }

                                <Grid item xs={4}>
                                  <Typography variant="body2">Billing : </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <TextField select size="small" fullWidth value={item.billing} onChange={e=>handleEditYearly(index, 'billing', e.target.value)}>
                                    {
                                      billingTypes.map((item)=>{
                                        return <MenuItem key={item} value={item} >{item}</MenuItem >
                                      })
                                    }
                                  </TextField>
                                </Grid>

                                <Grid item xs={4}>
                                  <Typography variant="body2">Features : </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Box display={"flex"} flexDirection={"column"} rowGap={1.5} marginBottom={1}>
                                  {item.features.map((feature, featureIndex)=>{
                                    return (
                                    <Grid container rowSpacing={2} columnSpacing={1} key={featureIndex}>
                                      <Grid item xs={10}>

                                        <Input
                                          value={feature}
                                          onChange={(e) => editHandleFeatureChange(index, featureIndex, e.target.value, 'yearly')}
                                        />
                                      </Grid>
                                      <Grid item xs={2}>

                                        <Button color='danger' size="sm" onClick={() => editRemovePricingInfoFeature(index, featureIndex, 'yearly')}><Delete/></Button>
                                      </Grid>
                                    </Grid>
                                    )
                                  })}
                                  </Box>
                                  <Button color="primary" onClick={() => editAddPricingInfoFeature(index, '', 'yearly')}>Add Feature</Button>
                                </Grid>
                                <Grid item xs={2}>
                                  <Button color='danger' onClick={()=>handlePricingDelete(index, 'yearly')}><Delete/></Button>
                                </Grid>
                                <Grid item xs={4}>
                                  <Button color='primary' onClick={()=>handlePricingClone(index, 'yearly')}><CopyAll/></Button>
                                </Grid>
                              </Grid>
                              
                            }
                            </AccordionDetails>
                          </Accordion>
                          </div>
                          
                          
                        )
                        })
                      }
                    </div>
                  )}
                  <div className="d-flex gap-2">
                  {!copyToMonthlyActive && <Button
                      color="primary"
                      onClick={() => togglePricingYearlyModal(true)}
                    >
                      Add Yearly Pricing
                    </Button>}
                    {productPricingInfo?.yearly?.length > 0 &&
                    (copyToMonthlyActive ? 
                      <>
                        <Button
                          color="success"
                          onClick={() => handleCopyPlanToAnother('yearly')}
                        >
                          Copy {plansToCopy.length} to monthly
                        </Button>
                        <Button
                          color="danger"
                          onClick={() => setcopyToMonthlyActive(false)}
                        >
                          Cancel
                        </Button>
                      </>
                      :
                      <Button
                        color="success"
                        onClick={() => setcopyToMonthlyActive(true)}
                      >
                        Copy to monthly
                      </Button>)
                      
                    }
                  </div>
                </div>
              </Row>

              <strong>
                <p>Screenshot of {productDetails.name} pricing page</p>
              </strong>
              {
                productMediaInfo?.priceImageInfo?.length <= 0 && <p>No pricing screenshot found</p>
              }
              <Button
                color="primary"
                onClick={() => togglePriceMediaModal(true)}
              >
                Add Screenshot
              </Button>
              <Slider {...sliderSettings}>
                {productMediaInfo?.priceImageInfo?.map((image, index) => (
                  <>
                    <img height={500} src={image.url} />
                    <input
                      className="form-control"
                      style={{ marginTop: "10px" }}
                      value={image.url}
                      placeholder="Enter Image URL"
                      onChange={e => {
                        let priceImageInfoArr = JSON.parse(
                          JSON.stringify(productMediaInfo?.priceImageInfo)
                        )
                        let newImg = JSON.parse(JSON.stringify(image))
                        newImg.url = e.target.value
                        priceImageInfoArr[index] = newImg
                        setProductMediaInfo({
                          ...productMediaInfo,
                          priceImageInfo: priceImageInfoArr,
                        })
                      }}
                    />
                    <input
                      className="form-control"
                      style={{ marginTop: "10px" }}
                      placeholder="Enter Image AltText"
                      value={image.altText}
                      onChange={e => {
                        let priceImageInfoArr = JSON.parse(
                          JSON.stringify(productMediaInfo?.priceImageInfo)
                        )
                        let newImg = JSON.parse(JSON.stringify(image))
                        newImg.altText = e.target.value
                        priceImageInfoArr[index] = newImg
                        setProductMediaInfo({
                          ...productMediaInfo,
                          priceImageInfo: priceImageInfoArr,
                        })
                      }}
                    />
                  </>
                ))}
              </Slider>

              <Button
                color="primary"
                className="mt-2"
                onClick={e => {
                  handlePricingPlanSave()
                  handleProductUpdateClick({
                    pricing: productDetails.pricing,
                    productPricingUrl: productDetails.productPricingUrl,
                    priceImage: productMediaInfo?.priceImageInfo?.map(
                      ele => ele.url
                    ),
                    priceImageAltText: productMediaInfo?.priceImageInfo?.map(
                      ele => ele.altText
                    ),
                    slug,
                  })
                }}
              >
                Update
              </Button>
            </TabPane>
            <TabPane tabId={3}>
              <ThemeProvider theme={defaultMaterialTheme}>
                <h4 style={{ marginTop: "20px" }}>Add Features</h4>
                {productDetails.features ? (
                  <MaterialTable
                    title="Add New Features"
                    columns={glossary_columns}
                    options={{
                      debounceInterval: 700,
                      padding: "dense",
                      filtering: true,
                    }}
                    data={query =>
                      new Promise((resolve, reject) => {
                        // prepare your data and then call resolve like this:
                        let url = `${process.env.REACT_APP_API_BASE_URL}/glossary?sort=${slug}`
                        console.log(url)
                        //searching
                        if (query.search) {
                          url = `${process.env.REACT_APP_API_BASE_URL}/glossary?`
                          url += `_search=${query.search}`
                        }
                        if (query.filters.length) {
                          const filter = query.filters.map(filter => {
                            return `&${filter.column.field}=${filter.value}`
                          })
                          url += filter.join("")
                        }
                        //pagination
                        url += `&page=${query.page + 1}`
                        url += `&pageCount=${query.pageSize}`

                        fetch(url, {
                          method: "GET",
                          headers: {
                            apiKey: process.env.REACT_APP_API_TOKEN,
                          },
                        })
                          .then(resp => resp.json())
                          .then(resp => {
                            resp.features.forEach(element => {
                              element["letter"] = element["name"]
                                .toUpperCase()
                                .charAt(0)
                              if (
                                productDetails.features.includes(
                                  element["name"]
                                )
                              ) {
                                element["actions"] = (
                                  <a
                                    onClick={e => {
                                      handleFeatureDeleteClick(element)
                                    }}
                                    className="btn btn-danger"
                                    style={{ width: "100%", marginTop: "10px" }}
                                  >
                                    Delete Feature
                                  </a>
                                )
                              } else {
                                element["actions"] = (
                                  <Button
                                    color="primary"
                                    style={{ width: "100%", marginTop: "10px" }}
                                    onClick={() => {
                                      setAddFeature(element)
                                      toggleAddFeatureModal(true)
                                    }}
                                  >
                                    Add Feature
                                  </Button>
                                )
                              }
                            })
                            resolve({
                              data: resp.features, // your data array
                              page: query.page, // current page number
                              totalCount: resp.featureCount, // total row number
                            })
                          })
                      })
                    }
                  />
                ) : (
                  ""
                )}
              </ThemeProvider>
              <Button
                color="primary"
                onClick={e => {
                  handleProductUpdateClick({
                    featuresInfo: categoryFeatures,
                  })
                }}
              >
                Update
              </Button>
            </TabPane>
            <TabPane tabId={4}>
              <h4 style={{ marginTop: "20px" }}>Alternatives</h4>
              {
                productAlternatives.length > 0 ?
                <GeneralTable
                  columns={productAlternativesColumns}
                  data={productAlternatives}
                />
                : 
                <p>No alternatives found</p>
              }
            </TabPane>
            <TabPane tabId={6}>
              <div style={{ margin: "20px" }}>
                {(productMediaInfo?.imageInfo?.length <= 0 && productMediaInfo?.videoInfo?.length <= 0) && <p>No media found</p>}
                <Button color="primary" onClick={() => toggleMediaModal(true)}>
                  Add/Update Media
                </Button>
                <Slider {...sliderSettings}>
                  {productMediaInfo?.imageInfo?.map((image, index) => (
                    <>
                      <img height={500} src={image.url} />
                      <input
                        className="form-control"
                        style={{ marginTop: "10px" }}
                        value={image.url}
                        placeholder="Enter Image URL"
                      />
                      <input
                        className="form-control"
                        style={{ marginTop: "10px" }}
                        placeholder="Enter Image AltText"
                        value={image.altText}
                      />
                    </>
                  ))}
                  {productMediaInfo?.videoInfo?.map((video, index) => (
                    <>
                      <iframe height={500} width={888} src={video.videoUrl} />
                      <input
                        className="form-control"
                        style={{ marginTop: "10px" }}
                        placeholder="Enter Video URL"
                        value={video.videoUrl}
                        onChange={e => {
                          let videoInfoArr = JSON.parse(
                            JSON.stringify(productMediaInfo?.videoInfo)
                          )
                          let newVid = JSON.parse(JSON.stringify(video))
                          newVid.videoUrl = e.target.value
                          videoInfoArr[index] = newVid
                          setProductMediaInfo({
                            ...productMediaInfo,
                            videoInfo: videoInfoArr,
                          })
                        }}
                      />
                    </>
                  ))}
                </Slider>
              </div>
              <Button
                color="primary"
                onClick={e => {
                  console.log(productMediaInfo?.videoInfo)
                  handleProductUpdateClick({
                    imageUrls: productMediaInfo?.imageInfo?.map(ele => ele.url),
                    videoUrls: productMediaInfo?.videoInfo?.map(
                      ele => ele.videoUrl
                    ),
                    imageUrlsAltText: productMediaInfo?.imageInfo?.map(
                      ele => ele.altText
                    ),
                    slug,
                  })
                }}
              >
                Update
              </Button>
            </TabPane>
            <TabPane tabId={7}>
              <h4 style={{ marginTop: "20px" }}>Integrations</h4>
              <Button
                color="primary"
                className="my-2"
                onClick={() => setIntegrationModal(true)}
              >
                Add Integration
              </Button>
              {productDetails?.integrations?.length > 0 ? 
              <GeneralTable
                columns={productIntegrationColumns}
                data={
                  productDetails?.integrations
                    ? productDetails?.integrations
                    : []
                }
              />
              :
              <p>No integrations found</p>
              
            }
              <Button
                color="primary"
                // disabled={customerUpdateLoading}
                onClick={() => handleUpdateIntegration()}
              >
                {!customerUpdateLoading ? "Update" : "Updating..."}
              </Button>
            </TabPane>
            <TabPane tabId={8}>
              <h4 style={{ marginTop: "20px" }}>Customers</h4>
              <Button
                color="primary"
                className="my-2"
                onClick={() => setCustomerModal(true)}
              >
                Add Customer
              </Button>
              {
                productDetails?.customers?.length > 0 ?
                <GeneralTable
                  columns={productCustomersColumn}
                  data={
                    productDetails?.customers ? productDetails?.customers : []
                  }
                />
                :
                <p>No customers found</p>
              }
              <Button
                color="primary"
                disabled={customerUpdateLoading}
                onClick={() => handleUpdateCustomer()}
              >
                {!customerUpdateLoading ? "Update" : "Updating..."}
              </Button>
            </TabPane>
            <TabPane tabId={9}>
              <h4 style={{ marginTop: "20px" }}>Support</h4>
              <Row className="mb-3">
                <label className="col-md-2 col-form-label">Contact</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    type="text"
                    value={productDetails.contact}
                    onChange={e => {
                      setProductDetails({
                        ...productDetails,
                        contact: e.target.value,
                      })
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-2 col-form-label">
                  Customer Service
                </label>
                <div className="col-md-10 d-flex gap-2">
                  <Dropdown
                    direction="down"
                    header="Select"
                    options={["Business Hours", "Online", "24/7 (Live rep)"]}
                    startValues={productDetails.support}
                    onChange={values => {
                      setProductDetails({
                        ...productDetails,
                        support: values,
                      })
                    }}
                    multipleChoice
                  />
                  <span>{productDetails.support?.join(" | ")}</span>
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-2 col-form-label">Location</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    type="text"
                    value={productDetails.location}
                    onChange={e => {
                      setProductDetails({
                        ...productDetails,
                        location: e.target.value,
                      })
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-2 col-form-label">Twitter URL</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    type="text"
                    value={productDetails.socialMediaUrls?.twitterUrl}
                    onChange={e => {
                      setProductDetails({
                        ...productDetails,
                        socialMediaUrls: {
                          ...productDetails.socialMediaUrls,
                          twitterUrl: e.target.value,
                        },
                      })
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-2 col-form-label">Linkedin URL</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    type="text"
                    value={productDetails.socialMediaUrls?.linkedinUrl}
                    onChange={e => {
                      setProductDetails({
                        ...productDetails,
                        socialMediaUrls: {
                          ...productDetails.socialMediaUrls,
                          linkedinUrl: e.target.value,
                        },
                      })
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-2 col-form-label">Facebook URL</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    type="text"
                    value={productDetails.socialMediaUrls?.facebookUrl}
                    onChange={e => {
                      setProductDetails({
                        ...productDetails,
                        socialMediaUrls: {
                          ...productDetails.socialMediaUrls,
                          facebookUrl: e.target.value,
                        },
                      })
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-2 col-form-label">Instagram URL</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    type="text"
                    value={productDetails.socialMediaUrls?.instagramUrl}
                    onChange={e => {
                      setProductDetails({
                        ...productDetails,
                        socialMediaUrls: {
                          ...productDetails.socialMediaUrls,
                          instagramUrl: e.target.value,
                        },
                      })
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-2 col-form-label">Youtube URL</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    type="text"
                    value={productDetails.socialMediaUrls?.youtubeUrl}
                    onChange={e => {
                      setProductDetails({
                        ...productDetails,
                        socialMediaUrls: {
                          ...productDetails.socialMediaUrls,
                          youtubeUrl: e.target.value,
                        },
                      })
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label className="col-md-2 col-form-label">
                  Other Social URL
                </label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    type="text"
                    value={productDetails.socialMediaUrls?.otherSocialUrls}
                    onChange={e => {
                      setProductDetails({
                        ...productDetails,
                        socialMediaUrls: {
                          ...productDetails.socialMediaUrls,
                          otherSocialUrls: e.target.value,
                        },
                      })
                    }}
                  />
                </div>
              </Row>
              <Button
                color="primary"
                onClick={e => {
                  handleProductUpdateClick({
                    contact: productDetails.contact,
                    support: productDetails.support,
                    location: productDetails.location,
                    socialMediaUrls: productDetails.socialMediaUrls,
                    slug,
                  })
                }}
              >
                Update
              </Button>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
      <Modal isOpen={mediaModal} toggle={() => toggleMediaModal(!mediaModal)}>
        <ModalHeader toggle={() => toggleMediaModal(!mediaModal)}>
          Add Image
        </ModalHeader>
        <ModalBody>
          <ButtonGroup>
            <Button
              color="primary"
              outline
              onClick={() => setMediaSelected(1)}
              active={mediaSelected === 1}
            >
              Image
            </Button>
            <Button
              color="primary"
              outline
              onClick={() => setMediaSelected(2)}
              active={mediaSelected === 2}
            >
              Video
            </Button>
          </ButtonGroup>
          {mediaSelected == 1 ? (
            <>
              <Row className="mb-3 mt-3">
                <label className="col-md-2 col-form-label required">
                  Image URL
                </label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder="Image"
                    type="file"
                    multiple
                    onChange={handleUpload}
                  />
                  <p>Max image size: 1 MB</p>
                </div>
              </Row>
              {uploads?.length > 0 && (
                <Row className="mb-3 mt-3">
                  <DnDList
                    list={uploads}
                    setList={setUploads}
                    droppableId="media-list"
                  />
                </Row>
              )}
            </>
          ) : (
            <>
              <Row className="mb-3 mt-3">
                <label className="col-md-2 col-form-label required">
                  Video URL
                </label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder="Video URL"
                    onChange={e => {
                      setAddVideo({
                        ...addVideo,
                        videoUrl: e.target.value,
                      })
                    }}
                  />
                  {videoUploads?.length > 0 && (
                    <Row className="mb-3 mt-3">
                      <VideoDnDList
                        list={videoUploads}
                        setList={setVideoUploads}
                        droppableId="media-list"
                      />
                    </Row>
                  )}
                </div>
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={addMediaLoading}
            onClick={async () => {
              const id = toast.loading("Processing your Request...")
              try {
                setAddMediaLoading(true)
                if (uploads.filter(e => e.altText == null).length > "") {
                  setAddMediaLoading(false)
                  toast.error("Please add alt text for all images")
                  return
                }
                if (mediaSelected == 1) {
                  let imageInfoArr = JSON.parse(
                    JSON.stringify(productMediaInfo?.imageInfo ?? [])
                  )
                  uploads.forEach(async (img, index) => {
                    if (!img.new) return
                    const file = new File([img.url], img.altText)
                    console.log(img.url, "url")
                    const fileURL = await upload({
                      dirname:
                        "products*" +
                        productDetails.name
                          .toLowerCase()
                          .replace("/[^a-z0-9]/g", "-") +
                        "*media",
                      file: img.image_file,
                    })
                    uploads[index].url = fileURL
                    imageInfoArr.push({
                      url: fileURL,
                      altText: img.altText,
                    })
                    setProductMediaInfo(prev =>
                      prev
                        ? {
                            ...prev,
                            imageInfo: imageInfoArr,
                          }
                        : { imageInfo: imageInfoArr }
                    )
                  })
                  let newImageInfo = uploads.map(img => {
                    let altText = ""
                    if (img.altText) {
                      altText = img.altText
                    }
                    return {
                      url: img.url,
                      altText,
                    }
                  })
                  setProductMediaInfo(prev =>
                    prev
                      ? {
                          ...prev,
                          imageInfo: newImageInfo,
                        }
                      : { imageInfo: newImageInfo }
                  )
                } else {
                  let videoInfoArr = JSON.parse(JSON.stringify(videoUploads))
                  if (addVideo.videoUrl) {
                    videoInfoArr.push(addVideo)
                  }
                  setAddVideo({
                    ...addVideo,
                    videoUrl: "",
                  })
                  setProductMediaInfo({
                    ...productMediaInfo,
                    videoInfo: videoInfoArr,
                  })
                }
                // setAddVideo({ videoUrl: "" })
                setAddMediaLoading(false)
                toggleMediaModal(!mediaModal)
              } finally {
                toast.dismiss(id)
              }
            }}
          >
            {addMediaLoading ? "Uploading Resources..." : "Save"}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => toggleMediaModal(!mediaModal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={priceMediaModal}
        toggle={() => togglePriceMediaModal(!priceMediaModal)}
      >
        <ModalHeader toggle={() => togglePriceMediaModal(!priceMediaModal)}>
          Add/Update Pricing Image
        </ModalHeader>
        <ModalBody>
          <Row className="mb-3 mt-3">
            <label className="col-md-2 col-form-label required">
              Image URL
            </label>
            <div className="col-md-10">
              <input
                className="form-control"
                placeholder="Image"
                type="file"
                multiple
                onChange={handleUpload}
              />
              <p>Max image size: 1 MB</p>
            </div>
          </Row>
          {uploads?.length > 0 && (
            <Row className="mb-3 mt-3">
              <DnDList
                list={uploads}
                setList={setUploads}
                droppableId="media-list"
              />
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={addMediaLoading}
            onClick={async () => {
              const id = toast.loading("Processing your Request...")
              try {
                setAddMediaLoading(true)
                if (uploads.filter(e => e.altText == null).length > "") {
                  setAddMediaLoading(false)
                  toast.error("Please add alt text for all images")
                  return
                }
                let priceImageInfoArr = JSON.parse(
                  JSON.stringify(productMediaInfo?.priceImageInfo)
                )
                uploads.forEach(async (img, index) => {
                  if (!img.new) return
                  const file = new File([img.url], img.altText)
                  console.log(img)
                  const fileURL = await upload({
                    dirname:
                      "products*" + productDetails.name + "*pricing_image",
                    file: img.image_file,
                  })
                  uploads[index].url = fileURL
                  console.log(fileURL)
                  priceImageInfoArr.push({ url: fileURL, altText: img.altText })
                  setProductMediaInfo(prev =>
                    prev
                      ? {
                          ...prev,
                          priceImageInfo: priceImageInfoArr,
                        }
                      : { priceImageInfo: priceImageInfoArr }
                  )
                })
                let newImageInfo = uploads.map(img => {
                  let altText = ""
                  if (img.altText) {
                    altText = img.altText
                  }
                  return {
                    url: img.url,
                    altText,
                  }
                })
                setProductMediaInfo(prev =>
                  prev
                    ? {
                        ...prev,
                        priceImageInfo: newImageInfo,
                      }
                    : { priceImageInfo: newImageInfo }
                )
                setAddMediaLoading(false)
                togglePriceMediaModal(!priceMediaModal)
              } finally {
                toast.dismiss(id)
              }
            }}
          >
            {addMediaLoading ? "Uploading Resources..." : "Save"}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => togglePriceMediaModal(!priceMediaModal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      
      <Modal
        isOpen={pricingMonthlyModal}
        toggle={() => togglePricingMonthlyModal(!pricingMonthlyModal)}
      >
        <ModalHeader toggle={() => togglePricingMonthlyModal(!pricingMonthlyModal)}>
          Add Monthly Pricing
        </ModalHeader>
        <ModalBody>
        <Grid container rowSpacing={2} columnSpacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1">Add New Monthly Pricing</Typography>
          </Grid>
          
          <Grid item xs={4}>
            <Typography variant="body2">Title : </Typography>
          </Grid>
          <Grid item xs={8}>
            <Input value={addMonthlyPricing.title} onChange={e=>setAddMonthlyPricing(prev => {return {...prev , title : e.target.value}})}/>
          </Grid>
          
          <Grid item xs={4}>
            <Typography variant="body2">Pricing Type : </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField select size="small" fullWidth defaultValue={pricingTypes[0]} value={addMonthlyPricing.pricingType.toLowerCase()} onChange={e=>setAddMonthlyPricing(prev => {return {...prev , pricingType : e.target.value}})}>
              {
                pricingTypes.map((item)=>{
                  return <MenuItem key={item} value={item} >{item}</MenuItem >
                })
              }
            </TextField>
            { addMonthlyPricing.pricingType.toLowerCase() === 'other' && <Input value={addMonthlyPricing.customPricingType} onChange={e=>setAddMonthlyPricing(prev => {return {...prev , customPricingType : e.target.value}})} style={{marginTop : '4px'}}/>}
          </Grid>
          
          {!(addMonthlyPricing.pricingType.toLowerCase() === 'custom' || addMonthlyPricing.pricingType.toLowerCase() === 'free' ) && 
            <>
              <Grid item xs={4}>
                <Typography variant="body2">Pricing : </Typography>
              </Grid>
              <Grid item xs={2}>
                <Input
                  value={addMonthlyPricing.pricingCurrency} 
                  placeholder="$"
                  onChange={e=>setAddMonthlyPricing(prev => {return {...prev , pricingCurrency : e.target.value}})}
                />
              </Grid>
              <Grid item xs={6}>
                <Input  
                  type="text"
                  value={addMonthlyPricing.pricing} 
                  onChange={e=>setAddMonthlyPricing(prev => {

                    return {...prev , pricing : handleNumericInput(e.target.value)}
                  })}
                />
              </Grid>
            </>
          }
          
          <Grid item xs={4}>
            <Typography variant="body2">Billing : </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField select size="small" fullWidth value={addMonthlyPricing.billing} onChange={e=>setAddMonthlyPricing(prev => {return {...prev , billing : e.target.value}})}>
              {
                billingTypes.map((item)=>{
                  return <MenuItem key={item} value={item} >{item}</MenuItem >
                })
              }
            </TextField>
          </Grid>
          
          <Grid item xs={4}>
            <Typography variant="body2">Features (add comma (,) separated values) : </Typography>
          </Grid>
          <Grid item xs={8}>
            <Input value={addMonthlyPricing.features} onChange={e=>setAddMonthlyPricing(prev => {return {...prev , features : e.target.value}})}/>
          </Grid>

          <Grid item xs={12}>
            <Button color="primary" variant='outlined' disabled={addMonthlyPricing.title ? false : true} onClick={()=>handleAddPricing('monthly')}>Add</Button>
          </Grid>
        </Grid>
          
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => togglePricingMonthlyModal(!pricingMonthlyModal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      
      <Modal
        isOpen={pricingYearlyModal}
        toggle={() => togglePricingYearlyModal(!pricingYearlyModal)}
      >
        <ModalHeader toggle={() => togglePricingYearlyModal(!pricingYearlyModal)}>
          Add Yearly Pricing
        </ModalHeader>
        <ModalBody>
        <Grid container rowSpacing={2} columnSpacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1">Add New Yearly Pricing</Typography>
          </Grid>
          
          <Grid item xs={4}>
            <Typography variant="body2">Title : </Typography>
          </Grid>
          <Grid item xs={8}>
            <Input value={addYearlyPricing.title} onChange={e=>setAddYearlyPricing(prev => {return {...prev , title : e.target.value}})}/>
          </Grid>
          
          <Grid item xs={4}>
            <Typography variant="body2">Pricing Type : </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField select size="small" fullWidth defaultValue={pricingTypes[0]} value={addYearlyPricing.pricingType.toLowerCase()} onChange={e=>setAddYearlyPricing(prev => {return {...prev , pricingType : e.target.value}})}>
              {
                pricingTypes.map((item)=>{
                  return <MenuItem key={item} value={item} >{item}</MenuItem >
                })
              }
            </TextField>
            { addYearlyPricing.pricingType.toLowerCase() === 'other' && <Input value={addYearlyPricing.customPricingType} onChange={e=>setAddYearlyPricing(prev => {return {...prev , customPricingType : e.target.value}})} style={{marginTop : '4px'}}/>}
          </Grid>
          
          {
            !(addYearlyPricing.pricingType.toLowerCase() === 'custom' || addYearlyPricing.pricingType.toLowerCase() === 'free' ) &&
            <>
              <Grid item xs={4}>
                <Typography variant="body2">Pricing : </Typography>
              </Grid>
              <Grid item xs={2}>
                <Input
                  value={addYearlyPricing.pricingCurrency} 
                  placeholder="$"
                  onChange={e=>setAddYearlyPricing(prev => {return {...prev , pricingCurrency : e.target.value}})}
                />
              </Grid>
              <Grid item xs={6}>
                <Input  
                  type="text"
                  value={addYearlyPricing.pricing} 
                  onChange={e=>setAddYearlyPricing(prev => {

                    return {...prev , pricing : handleNumericInput(e.target.value)}
                  })}
                />
              </Grid>
            </>
          }
          
          <Grid item xs={4}>
            <Typography variant="body2">Billing : </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField select size="small" fullWidth value={addYearlyPricing.billing} onChange={e=>setAddYearlyPricing(prev => {return {...prev , billing : e.target.value}})}>
              {
                billingTypes.map((item)=>{
                  return <MenuItem key={item} value={item} >{item}</MenuItem >
                })
              }
            </TextField>
          </Grid>
          
          <Grid item xs={4}>
            <Typography variant="body2">Features (add comma (,) separated values) : </Typography>
          </Grid>
          <Grid item xs={8}>
            <Input value={addYearlyPricing.features} onChange={e=>setAddYearlyPricing(prev => {return {...prev , features : e.target.value}})}/>
          </Grid>

          <Grid item xs={12}>
            <Button color="primary" variant='outlined' disabled={addYearlyPricing.title ? false : true} onClick={()=>handleAddPricing('yearly')}>Add</Button>
          </Grid>
        </Grid>
          
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => togglePricingYearlyModal(!pricingYearlyModal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={integrationModal}
        toggle={() => setIntegrationModal(!integrationModal)}
      >
        <ModalHeader toggle={() => setIntegrationModal(!integrationModal)}>
          Add Integration
        </ModalHeader>
        <ModalBody>
          <ButtonGroup>
            <Button
              color="primary"
              outline
              onClick={() => setInteselected(1)}
              active={inteSelected === 1}
            >
              Available on SpotSaaS
            </Button>
            <Button
              color="primary"
              outline
              onClick={() => setInteselected(2)}
              active={inteSelected === 2}
            >
              Not Available on SpotSaaS
            </Button>
          </ButtonGroup>
          {inteSelected == 1 ? (
            <Row className="mb-3 mt-3">
              <label className="col-md-2 col-form-labe required">Slug</label>
              <div className="col-md-10">
                <Autocomplete
                  wrapperStyle={{ position: "relative", width: "100%" }}
                  value={integrationSearchValue}
                  items={integrationSearchResult ?? []}
                  getItemValue={item => item.name}
                  onSelect={(value, item) => {
                    setIntegrationSearchResult([item])
                    setIntegrationSearchResultSelected(item)
                    setIntegrationSearchValue(value)
                  }}
                  onChange={(event, value) => {
                    console.log(value)
                    setIntegrationSearchValue(value)
                  }}
                  renderMenu={children => (
                    <div className="menu">{children}</div>
                  )}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className={`item ${
                        isHighlighted ? "item-highlighted" : ""
                      }`}
                      key={item.abbr}
                    >
                      {item.name}
                    </div>
                  )}
                />
                {integrationSearchResultSelected && (
                  <>
                    <label className="col-md-4 col-form-label">
                      Integration Name:
                    </label>
                    <div className="col-md-8">
                      <p className="mb-0 col-form-label">
                        {integrationSearchResultSelected?.name}
                      </p>
                    </div>
                    <label className="col-md-4 col-form-label">
                      Integration Logo:
                    </label>
                    <div className="col-md-8">
                      <img
                        src={integrationSearchResultSelected?.logo}
                        style={{ width: "50%" }}
                      />
                    </div>
                  </>
                )}
              </div>
            </Row>
          ) : (
            <>
              <Row className="mb-3 mt-3">
                <label className="col-md-2 col-form-label required">Name</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder="Name"
                    value={addIntegration.name}
                    onChange={e => {
                      setAddIntegration({
                        ...addIntegration,
                        name: e.target.value,
                      })
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3 mt-3">
                <label className="col-md-2 col-form-label required">
                  Image
                </label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder="Image"
                    type="file"
                    onChange={e => {
                      const file = new File(
                        [e.target.files[0]],
                        `logo_${Date.now()}`
                      )
                      console.log(file)
                      setAddIntegration({
                        ...addIntegration,
                        logo: file,
                      })
                    }}
                  />
                  <p>Max image size: 1 MB</p>
                  {addIntegration.logo &&
                    typeof addIntegration.logo != "string" && (
                      <img
                        src={URL.createObjectURL(addIntegration.logo)}
                        style={{ width: "100%", marginTop: "10px" }}
                      />
                    )}
                </div>
              </Row>
              <Row className="mb-3 mt-3">
                <label className="col-md-2 col-form-label required">
                  Logo Alt Text
                </label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder="Name"
                    value={addIntegration.logoAltText}
                    onChange={e => {
                      setAddIntegration({
                        ...addIntegration,
                        logoAltText: e.target.value,
                      })
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3 mt-3">
                <label className="col-md-2 col-form-label required">
                  url
                </label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    placeholder="url"
                    value={addIntegration.url}
                    onChange={e => {
                      setAddIntegration({
                        ...addIntegration,
                        url: e.target.value,
                      })
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3 mt-3">
                <label className="col-md-2 col-form-label required">
                  Primary Category Name
                </label>
                <div className="col-md-10">
                  <Autocomplete
                    wrapperStyle={{ position: "relative", width: "100%" }}
                    value={addIntegration.primaryCategoryName}
                    items={categorySearchResult ?? []}
                    getItemValue={item => item.name}
                    onSelect={(value, item) => {
                      setCategorySearchResult([item])
                      setAddIntegration(prev => ({
                        ...prev,
                        primaryCategoryName: value,
                      }))
                    }}
                    onChange={(event, value) => {
                      console.log(value)
                      setAddIntegration(prev => ({
                        ...prev,
                        primaryCategoryName: value,
                      }))
                    }}
                    renderMenu={children => (
                      <div className="menu">{children}</div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "item-highlighted" : ""
                        }`}
                        key={item.abbr}
                      >
                        {item.name}
                      </div>
                    )}
                  />
                </div>
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              const id = toast.loading("Processing your Request...")
              console.log("Start integrations modal")
              try {
                if (inteSelected == 1) {
                  const prodIdData = await getProductId(integrationSearchResultSelected?.slug)
                  setProductDetails({
                    ...productDetails,
                    integrations: [
                      ...productDetails.integrations,
                      {
                        _id: prodIdData?._id,
                        logo: integrationSearchResultSelected?.logo,
                        slug: integrationSearchResultSelected?.slug,
                        rating: integrationSearchResultSelected?.rating,
                        logoAltText: integrationSearchResultSelected?.logoAltText,
                        name: integrationSearchResultSelected?.name,
                        primaryCategoryName:
                        integrationSearchResultSelected?.categoryName,
                      },
                    ],
                  })
                }
                if (inteSelected == 2) {
                  const logoURL = await upload({
                    dirname: "product*" + addIntegration.name,
                    file: addIntegration.logo,
                  })
                  console.log(logoURL)
                  const newIntegration = { ...addIntegration, logo: logoURL }
                  console.log(newIntegration, "Here")
                  const id = toast.loading("Processing Your request...")
                  try {
                    const res = await addNewIntegration(newIntegration)
                    console.log(res.id)
                    setProductDetails({
                      ...productDetails,
                      integrations: [
                        ...productDetails.integrations,
                        {
                          _id: res?.id,
                          logo: newIntegration?.logo,
                          logoAltText: newIntegration?.logoAltText,
                          name: newIntegration?.name,
                          primaryCategoryName:
                            newIntegration?.primaryCategoryName,
                        },
                      ],
                    })
                  } catch (e) {
                    toast.error(e.response.data.message)
                  } finally {
                    toast.dismiss(id)
                    setAddIntegration({
                      name: "",
                      logo: null,
                      logoAltText: "",
                      primaryCategoryName: "",
                    })
                  }
                  console.log("Close integrations modal")
                  setIntegrationModal(false)
                }
                console.log("Close integrations modal")
                setIntegrationModal(false)
              } finally {
                toast.dismiss(id)
              }
            }}
          >
            Save
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => setIntegrationModal(!integrationModal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={customerModal}
        toggle={() => setCustomerModal(!customerModal)}
      >
        <ModalHeader toggle={() => setCustomerModal(!customerModal)}>
          Add Customer
        </ModalHeader>
        <ModalBody>
          <>
            <ButtonGroup>
              <Button
                color="primary"
                outline
                onClick={() => setCustomerSelected(1)}
                active={customerSelected === 1}
              >
                New Customer
              </Button>
              <Button
                color="primary"
                outline
                onClick={() => setCustomerSelected(2)}
                active={customerSelected === 2}
              >
                Existing Customer
              </Button>
            </ButtonGroup>
            {customerSelected === 1 && (
              <>
                <Row className="mb-3 mt-3">
                  <label className="col-md-2 col-form-label required">
                    Name
                  </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      placeholder="Name"
                      value={addCustomer.name}
                      onChange={e => {
                        setAddCustomer({
                          ...addCustomer,
                          name: e.target.value,
                        })
                      }}
                    />
                  </div>
                </Row>
                <Row className="mb-3 mt-3">
                  <label className="col-md-2 col-form-label required">
                    Image
                  </label>
                  <div className="col-md-10">
                    <input
                      className="form-control"
                      placeholder="Image"
                      type="file"
                      onChange={e => {
                        const reader = new FileReader()
                        reader.onload = e => {
                          const fileUrl = new File(
                            [e.target.result],
                            `image_urls_${Date.now()}`,
                            {
                              type: "image/jpg",
                            }
                          )
                          reader.readAsDataURL(file)
                        }

                        // const file = new File(
                        //   [e.target.files[0]],
                        //   `logo_${Date.now()}`
                        // )
                        // console.log(file)
                        setAddCustomer({
                          ...addCustomer,
                          logo: e.target.files[0],
                        })
                      }}
                    />
                    <p>Max image size: 1 MB</p>
                    {addCustomer.logo &&
                      typeof addCustomer.logo != "string" && (
                        <img
                          src={URL.createObjectURL(addCustomer.logo)}
                          style={{ width: "100%", marginTop: "10px" }}
                        />
                      )}
                  </div>
                </Row>
              </>
            )}
            {customerSelected === 2 && (
              <>
                <Row className="mb-3 mt-3">
                  <label className="col-md-4 col-form-label required">
                    Search Customer
                  </label>
                  <div className="col-md-8">
                    <Autocomplete
                      wrapperStyle={{ position: "relative", width: "100%" }}
                      value={customerSearchValue}
                      items={customerSearchResult ?? []}
                      getItemValue={item => item.name}
                      onSelect={(value, item) => {
                        setCustomerSearchResult([item])
                        setCustomerSearchValue(value)
                      }}
                      onChange={(event, value) => {
                        console.log(value)
                        setCustomerSearchValue(value)
                      }}
                      renderMenu={children => (
                        <div className="menu">{children}</div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "item-highlighted" : ""
                          }`}
                          key={item.abbr}
                        >
                          {item.name}
                        </div>
                      )}
                    />
                  </div>
                  {console.log(customerSearchResult)}
                  {customerSearchResult?.length == 1 && (
                    <>
                      <label className="col-md-4 col-form-label">
                        Customer Name:
                      </label>
                      <div className="col-md-8">
                        <p className="mb-0 col-form-label">
                          {customerSearchResult[0]?.name}
                        </p>
                      </div>
                      <label className="col-md-4 col-form-label">
                        Customer Logo:
                      </label>
                      <div className="col-md-8">
                        <img
                          src={customerSearchResult[0]?.logo}
                          style={{ width: "50%" }}
                        />
                      </div>
                    </>
                  )}
                </Row>
              </>
            )}
          </>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              const id = toast.loading("Processing your Request...")
              try {
                if (customerSelected == 1) {
                  const logoURL = await upload({
                    dirname: "product-customers*" + productDetails.name,
                    file: addCustomer.logo,
                  })
                  setAddCustomer(prev => ({ ...prev, logo: logoURL }))
                  const newCustomer = { name: addCustomer.name, logo: logoURL }
                  const res = await addNewCustomer(newCustomer)
                  console.log(res)
                  setProductDetails({
                    ...productDetails,
                    customers: [
                      ...productDetails.customers,
                      { ...newCustomer, _id: res?.id },
                    ],
                  })
                  setAddCustomer({
                    name: "",
                    logo: null,
                  })
                  console.log(productDetails.customers)
                }
                if (customerSelected == 2) {
                  setProductDetails({
                    ...productDetails,
                    customers: [
                      ...productDetails.customers,
                      {
                        _id: customerSearchResult[0]?._id,
                        name: customerSearchResult[0]?.name,
                        logo: customerSearchResult[0]?.logo,
                      },
                    ],
                  })
                }
                setCustomerModal(false)
              } finally {
                toast.dismiss(id)
              }
            }}
          >
            Save
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => setCustomerModal(!customerModal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={addFeatureModal}
        toggle={() => toggleAddFeatureModal(!addFeatureModal)}
      >
        <ModalHeader toggle={() => toggleAddFeatureModal(!addFeatureModal)}>
          Add Feature
        </ModalHeader>
        <ModalBody>
          <Row className="mb-3 mt-3">
            <label className="col-md-2 col-form-label required">
              Feature Name
            </label>
            <div className="col-md-10">
              <input
                className="form-control"
                value={addFeature.name}
                disabled
                type="text"
              />
            </div>
            <label className="col-md-2 col-form-label">Feature Desc</label>
            <div className="col-md-10">
              <input
                className="form-control"
                value={addFeature.desc}
                disabled
                type="text"
              />
            </div>
            <label className="col-md-2 col-form-label required">Category</label>
            <div className="col-md-10 d-flex gap-2">
              <Dropdown
                direction="down"
                header="Select"
                options={[productDetails.categorySlug]}
                startValues={productDetails.categorySlug}
                onChange={values => {
                  setAddFeature({ ...addFeature, changedCategorySlug: values })
                }}
              />
              <span>{addFeature.changedCategorySlug}</span>
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          {" "}
          <Button
            color="primary"
            onClick={async () => {
              console.log(addFeature)
              let dataToUpdate = {
                slug: addFeature.slug,
                name: addFeature.name,
                newProduct: slug,
              }
              if (
                !addFeature.categorySlugs.includes(
                  addFeature.changedCategorySug
                )
              ) {
                dataToUpdate.newCategory = addFeature.changedCategorySlug
              }
              console.log(dataToUpdate)
              const answer = window.confirm(
                `Are you sure you want to add ${dataToUpdate.slug} feature`
              )
              if (answer) {
                updateGlossary(dataToUpdate)
                  .then(response => {
                    if (response.success) {
                      toast.success(response.message)
                      location.reload()
                    } else {
                      toast.error(response.message)
                    }
                    toggleAddFeatureModal(!addFeatureModal)
                  })
                  .catch(error => {
                    console.log(error)
                    toast.error("Error in adding feature")
                    toggleAddFeatureModal(!addFeatureModal)
                  })
              } else {
                toast.error("Feature Not Added")
                toggleAddFeatureModal(!addFeatureModal)
              }
            }}
          >
            Add
          </Button>
          <Button
            color="secondary"
            onClick={() => toggleAddFeatureModal(!addFeatureModal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(ProductDetails)
