import Repository from "./Repository"

export function getGlossary() {
  return new Promise((resolve, reject) => {
    Repository.get("/glossary")
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function addGlossary(data) {
  return new Promise((resolve, reject) => {
    Repository.post("/glossary", data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function updateGlossary(data) {
  return new Promise((resolve, reject) => {
    Repository.put("/glossary", data)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function deleteGlossary(data) {
  return new Promise((resolve, reject) => {
    Repository.delete(`/glossary`, { data: data })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getGlossaryBySlug(glossarySlug) {
  return new Promise((resolve, reject) => {
    Repository.get(`/glossary/${glossarySlug}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
