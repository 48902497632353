import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//categories
import Categories from "pages/Categories"
import EditorPicks from "pages/Categories/EditorPicks"
import CategoryFeatures from "pages/Categories/CategoryFeatures"
import RelatedCategories from "pages/Categories/RelatedCategories"
import CategoryDetails from "pages/Categories/CategoryDetails"
import AddCategory from "pages/Categories/AddCategory"

//products
import Products from "pages/Products"
import DeletedProducts from "pages/Products/DeletedProducts"
import ProductAlternatives from "pages/Products/ProductAlternatives"
import ProductDetails from "pages/Products/ProductDetails"
import AddProduct from "pages/Products/AddProduct"
import AddSponsor from "pages/Products/AddSponsor"
import EditSponsor from "pages/Products/EditSponsor"

//glossary
import AllGlossary from "pages/Glossary/AllGlossary"
import AddGlossary from "pages/Glossary/AddGlossary"
import EditGlossary from "pages/Glossary/EditGlossary"

//subadmins
import ListSubadmins from "pages/Subadmins/ListSubadmins"
import AddSubadmin from "pages/Subadmins/AddSubadmin"
import AllClients from "pages/Clients/AllClients"
import AddClient from "pages/Clients/AddClient"
import EditClient from "pages/Clients/EditClient"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // // //profile
  { path: "/profile", component: UserProfile },

  //categories
  { path: "/all-categories", component: Categories },

  { path: "/editor-picks/:slug", component: EditorPicks },

  { path: "/category-features/:slug", component: CategoryFeatures },

  { path: "/related-categories/:slug", component: RelatedCategories },

  { path: "/category-detail/:slug", component: CategoryDetails },

  { path: "/add-category", component: AddCategory },

  //products
  { path: "/all-products", component: Products },

  { path: "/deleted-products", component: DeletedProducts },

  { path: "/product-alternatives/:slug", component: ProductAlternatives },

  { path: "/product-detail/:slug", component: ProductDetails },

  { path: "/add-product", component: AddProduct },
  { path: "/add-sponsor", component: AddSponsor },
  { path: "/edit-sponsor", component: EditSponsor },

  //glossary
  { path: "/glossary", component: AllGlossary },

  { path: "/add-glossary", component: AddGlossary },

  { path: "/edit-glossary", component: EditGlossary },

  //subadmins
  { path: "/subadmins", component: ListSubadmins },
  { path: "/add-subadmin", component: AddSubadmin },

  //clients
  { path: "/clients", component: AllClients },

  { path: "/add-client", component: AddClient },

  { path: "/edit-client", component: EditClient },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { userRoutes, authRoutes }
