import React, { useEffect, useState } from "react"
import { setBreadcrumbItems } from "store/actions"
import { connect } from "react-redux"
import { Button, Card, CardBody, CardTitle } from "reactstrap"
import { useParams } from "react-router-dom"
import { getEditorPickProducts } from "repository/CategoryRepository"
import GeneralTable from "components/Common/GeneralTable"

function EditorPicksProducts({ setBreadcrumbItems }) {
  const { slug } = useParams()
  const [editorPickProducts, setEditorPickProducts] = useState([])
  const breadcrumbItems = []
  const columns = [
    {
      name: "Name",
      data_key: "name",
    },
    {
      name: "Image",
      render: record => <img src={record.image} height={50} width={50} />,
    },
    {
      name: "Actions",
      render: record => <Button color="success">Edit</Button>,
    },
  ]
  useEffect(() => {
    setBreadcrumbItems("Editor Picks Products", breadcrumbItems)
    getEditorPickProducts(slug)
      .then(response => {
        setEditorPickProducts(response)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="h4">Editor Picks Products</CardTitle>
          <GeneralTable columns={columns} data={editorPickProducts} />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(EditorPicksProducts)
