import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Button } from "reactstrap"
import { getAllSubadmins } from "repository/AuthRepository"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { setBreadcrumbItems } from "store/actions"
import moment from "moment"
import GeneralTable from "components/Common/GeneralTable"
import { deleteSubAdmin } from "repository/AuthRepository"
import toast, { Toaster } from "react-hot-toast"
import confirm from "reactstrap-confirm"

function ListSubadmins({ setBreadcrumbItems }) {
  const token = localStorage.getItem("token")
  const columns = [
    {
      name: "Username",
      data_key: "username",
    },
    {
      name: "Email",
      data_key: "email",
    },
    {
      name: "Created At",
      data_key: "createdAt",
    },
    {
      name: "Actions",
      render: record => (
        <>
          <Button
            color="danger"
            onClick={e => {
              deleteSubadmin(record._id)
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ]
  const breadcrumbItems = [
    {
      item: "Subadmins",
      path: "#",
    },
    {
      item: "List Subadmins",
      path: "/subadmins",
    },
  ]
  const [subadmins, setSubadmins] = useState([])
  const deleteSubadmin = subadminID => {
    const id = toast.loading("Processing your Request...");
    confirm().then(() => {
      deleteSubAdmin(subadminID, token)
        .then(response => {
          if (response.status == 1) {
            let admins = JSON.parse(JSON.stringify(subadmins))
            let filtered = admins.filter(element => element._id != subadminID)
            setSubadmins(filtered)
            toast.success("User deleted successfully!!")
          } else {
            toast.error(response.message)
          }
        })
        .catch(error => {
          console.log(error)
        })
    })
    toast.dismiss(id);
  }
  useEffect(() => {
    console.log("token ", token)
    setBreadcrumbItems("List Subadmins", breadcrumbItems)
    getAllSubadmins(token).then(response => {
      if (response.status == 1) {
        response.data.forEach(element => {
          element.createdAt = moment(element.createdAt).format("DD-MM-YYYY")
        })
        setSubadmins(response.data)
      } else {
        alert(response.message)
      }
    })
  }, [])
  return (
    <Card>
      <CardBody>
        <CardTitle style={{ display: "flex", justifyContent: "space-between" }}>
          Sub-Admins
          <Link to={"/add-subadmin"}>
            <a className="btn btn-primary">Add Subadmin</a>
          </Link>
        </CardTitle>
        <Toaster />
        {subadmins.length > 0 ? (
          <GeneralTable columns={columns} data={subadmins} />
        ) : (
          "No data found..."
        )}
      </CardBody>
    </Card>
  )
}

export default connect(null, { setBreadcrumbItems })(ListSubadmins)
